import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {styles, Checkbox, colors} from '@get-wrecked/simple-components'

const emptyOnChange = () => {}

const CheckboxSetting = ({id, icon, label, subtext, description, checked, onChange = () => {}, disabled, state} = {}) => {
  const [isChecked, setChecked] = useState(checked)

  const onClick = (e) => {
    if (disabled) {
      return
    }
    e.stopPropagation()
    onChange(!isChecked)
    setChecked(!isChecked)
  }

  useEffect(() => {
    if (isChecked !== checked) {
      setChecked(checked)
    }
  }, [checked])

  return (
    <Container
      $state={state}
      $disabled={disabled}
      onClick={onClick}
    >
      <Text>
        <Label>{label}</Label>
        {subtext && <Subtext>{subtext}</Subtext>}
        {description && <Description>{description}</Description>}
      </Text>
      <CheckboxContainer>
        <Checkbox
          checked={isChecked}
          onChange={emptyOnChange}
        />
      </CheckboxContainer>
    </Container>
  )
}

export default CheckboxSetting;

const Container = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${colors.stroke['0A8']};
  border: 1px solid ${colors.stroke['0A8']};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: all;
  align-items: start;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.32);

    ${({$state}) => {
      if ($state === 'caution') {
        return `
          background-color: rgba(255, 159, 80, 0.1);
          border: 1px solid rgba(255, 141, 80, 1);
        `
      } else if ($state === 'warning') {
        return `
          background-color: rgba(255, 80, 80, 0.1);
          border: 1px solid #ff5050;
        `
      }
    }}
  }
  
  ${({$disabled}) => $disabled ? styles.Disabled : ''}
  
  ${({$state}) => {
    if ($state === 'caution') {
      return `
        background-color: rgba(255, 159, 80, 0.05);
        border: 1px solid rgba(255, 141, 80, 0.5);
      `
    } else if ($state === 'warning') {
      return `
        background-color: rgba(255, 80, 80, 0.05);
        border: 1px solid rgba(255, 80, 80, 0.5);
      `
    }
  }}
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  gap: 4px;
  margin-inline-end: auto;
`

const Label = styled.span`
  font-weight: 500;
  color: white;
`

const Subtext = styled.span`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.64);
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 500;
`

const Description = styled.span`
  font-size: 13px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.64);
  margin-top: 4px;
`

const CheckboxContainer = styled.div`
  position: relative;
  margin-inline-start: 16px;
`
