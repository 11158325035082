import {Environments} from '@get-wrecked/updates/constants'

/**
 * Get the corresponding Medal environment for the specified LaunchDarkly environment.
 * @param env
 * @returns {string}
 */
export const ldEnvToMedalEnvironment = env => {
  switch (env) {
    case 'test':
      return Environments.SANDBOX
    case 'qa-testing':
      return Environments.QA_TESTING
    case 'ea':
      return Environments.EARLY_ACCESS
    case 'production':
      return Environments.PRODUCTION
    default:
      console.warn('Unrecognized LD env, cannot map to Medal environment:', env)
      return null
  }
}

/**
 * Get the corresponding LD env for the specified Medal environment.
 * @param environment
 * @returns {string}
 */
export const medalEnvironmentToLdEnv = environment => {
  switch (environment.value ?? environment) {
    case Environments.SANDBOX:
      return 'test'
    case Environments.QA_TESTING:
      return 'qa-testing'
    case Environments.EARLY_ACCESS:
      return 'ea'
    case Environments.PRODUCTION:
      return 'production'
    default:
      console.warn('Unrecognized Medal environment, cannot map to LD env:', environment)
      return 'test'
  }
}

const envOrder = [Environments.PRODUCTION, Environments.EARLY_ACCESS, Environments.SANDBOX, Environments.QA_TESTING]
function envSort(a, b) {
  return envOrder.indexOf(ldEnvToMedalEnvironment(a)) - envOrder.indexOf(ldEnvToMedalEnvironment(b));
}

/**
 * Convert a LaunchDarkly flag to our flag objects.
 * @param ldFlag
 * @returns {{createdAt: *, createdBy: {userName: *}, description, value: *}}
 */
export const convertLdFlag = (ldFlag) => {
  const valueWhenOn = ldFlag.variations[ldFlag.defaults?.onVariation ?? 0].value
  const valueWhenOff = ldFlag.variations[ldFlag.defaults?.offVariation ?? 1].value

  const project = ldFlag._links.parent.href.split('/').pop()
  const shouldMapEnvironments = project === 'medaltv-desktop'

  const environmentNames = Object.keys(ldFlag.environments)
  if (shouldMapEnvironments) {
    environmentNames.sort(envSort)
  }

  const environments = {}
  for (let env of environmentNames) {
    environments[shouldMapEnvironments ? ldEnvToMedalEnvironment(env) : env] = {
      value: ldFlag.environments[env].on ? valueWhenOn : valueWhenOff,
    }
  }

  return {
    name: ldFlag.name,
    description: ldFlag.description,
    environments: environments,
    createdAt: ldFlag.creationDate,
    createdBy: {
      userName: ldFlag._maintainer?.firstName
    }
  }
}
