export const capitalize = (word) => {
  return word[0].toUpperCase() + word.substring(1);
}

export const parseQueryString = (queryString) => {
  const params = new URLSearchParams(queryString);
  const parsedParams = {};
  params.forEach((value, key) => {
    parsedParams[key] = value;
  });
  return parsedParams;
}
