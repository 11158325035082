import {get} from './builds-api'
import {capitalize} from './string'

export const versionListUrl = ({user, environment, module, raw}) => {
  const target = environment.target || environment.value;
  const targetPath = target.endsWith('/') ? target : `${target}/`;
  if (!user?.isStaff) {
    return `https://cdn.medal.tv/${targetPath}${module}/version.json`
  } else {
    return `https://builds-api.medal.tv/versions/${environment.value}/${module}`
  }
}

export const getParentProperties = ({environment, module, versionLists}) => {
  const hasParent = environment.parent
  const parentVersionList = versionLists?.[`${environment.parent}.${module}`];
  const versionList = versionLists?.[`${environment.value}.${module}`];
  const isParentUpToDate = hasParent && parentVersionList?.[0].semver === versionList?.[0].semver
  return {
    hasParent,
    parent: {
      versionList: parentVersionList,
      isUpToDate: isParentUpToDate
    }
  }
}

export const getWhatsNew = async ({environment, module, versionLists, currentVersion}) => {
  const { hasParent, parent } = getParentProperties({environment, module, versionLists})
  const versionList = versionLists?.[`${environment.value}.${module}`];
  const currentEntry = currentVersion ?? versionList[0]

  const repo = module === 'electron'
    ? 'medal-electron'
    : 'MedalStudio'
  const targetBranch = (environment.parent === 'production' || environment.value === 'production')
    ? 'production'
    : (environment.parent === 'staging' || environment.value === 'staging')
      ? 'early-access'
      : ''
  const tagSuffix = targetBranch === 'early-access' ? '-ea' : '';

  const processBody = (body) => {
    return body
      .replace(/(https:\/\/github.com\/.*\/pull\/)(\d+)/g, '[#$2]($1$2)')
      .replace(/@([a-zA-Z0-9](?:-?[a-zA-Z0-9]){0,38})/g, '[@$1](https://github.com/$1)')
  }

  let diff = []
  if (hasParent) {
    diff = [parent.versionList[0]?.semver, currentEntry.hash]
    // const diffUrl = `https://github.com/get-wrecked/${repo}/compare/${latestParentVersion}${tagSuffix}...${currentEntry.hash}`
  } else {
    const previousVersionIndex = versionLists?.[`${environment.value}.${module}`].findIndex(version => version.semver === currentEntry.semver) + 1
    if (previousVersionIndex <= 0) {
      throw new Error('Could not find previous version in relation to current version')
    }
    if (previousVersionIndex >= versionLists?.[`${environment.value}.${module}`].length) {
      throw new Error(`Unable to look back on previous versions before ${currentEntry?.semver}`)
    }
    const previousVersion = versionLists?.[`${environment.value}.${module}`][previousVersionIndex]

    diff = [previousVersion?.semver, currentEntry.semver]
  }

  const diffUrl = `https://github.com/get-wrecked/${repo}/compare/${diff[0]}${tagSuffix}...${diff[1]}`
  const {data} = await get(`/github/notes/${repo}/${diff[1]}${tagSuffix}/${targetBranch}/${diff[0]}${tagSuffix}`)
  const raw = data.body
  const parsed = processBody(data.body)
    .replace(`**Full Changelog**: https://github.com/get-wrecked/${repo}/compare/${diff[0]}${tagSuffix}...${diff[1]}${tagSuffix}`, `**[Full Changelog](${diffUrl})**`)
  const parsedLines = parsed.split('\n')
  const candidateMergesSuppressed = parsedLines.filter(line => !line.includes('candidate'))
  return {
    title: `${capitalize(module)} ${environment.label} ${currentEntry.semver}`,
    raw: raw,
    parsed: `> Changelog generated based on diff between ${diff[0]} and ${diff[1]}\n\n${candidateMergesSuppressed.join('\n')}`,
    suppressed: parsedLines.length - candidateMergesSuppressed.length
  }
}
