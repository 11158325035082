import {Fragment, useState} from 'react'
import styled from 'styled-components'
import {colors, Tooltip} from '@get-wrecked/simple-components'
import CircleCheckIcon from '../../../components/Icons/CircleCheckIcon'
import {LoadingPlaceholder, WarningText} from '../../../components/styles'

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

const FlagKey = ({flagKey, canCopy, warningMessage, loading, width}) => {
  const [copied, setCopied] = useState(false)

  const onCopy = async () => {
    if (!flagKey?.length || canCopy === false) {
      return
    }
    await copyToClipboard(flagKey)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  if (loading) {
    return (
      <Container
        $empty={true}
        $loading={loading}
        $clickable={false}
      />
    )
  }

  return (
    <Tooltip
      tooltip={(flagKey?.length && canCopy !== false) ? 'Copy to clipboard' : undefined}
      position="bottom"
      wrapperStyle={{width: width ?? 'fit-content'}}
    >
      <Container
        $empty={!flagKey?.length}
        $copied={copied}
        $clickable={canCopy !== false}
        $warning={warningMessage?.length > 0}
        $width={width}
        onClick={onCopy}
      >
        <span>
          {
            flagKey?.length > 0
              ? flagKey
              : 'The flag key will appear here'
          }
          {
            warningMessage?.length > 0 &&
            <WarningText> {warningMessage}</WarningText>
          }
        </span>
        {
          !warningMessage?.length &&
          <Fragment>
            {
              flagKey?.length > 0 && !copied && canCopy !== false &&
              <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
                  fill="white"/>
                <path
                  d="M17.2391 4.81949C17.2391 6.40949 15.9391 7.70949 14.3491 7.70949H9.64906C8.05906 7.70949 6.75906 6.40949 6.75906 4.81949C6.75906 4.25949 6.15906 3.90949 5.65906 4.16949C4.24906 4.91949 3.28906 6.40949 3.28906 8.11949V17.5295C3.28906 19.9895 5.29906 21.9995 7.75906 21.9995H16.2391C18.6991 21.9995 20.7091 19.9895 20.7091 17.5295V8.11949C20.7091 6.40949 19.7491 4.91949 18.3391 4.16949C17.8391 3.90949 17.2391 4.25949 17.2391 4.81949ZM15.5291 17.5295C15.3791 17.6795 15.1891 17.7495 14.9991 17.7495C14.8091 17.7495 14.6191 17.6795 14.4691 17.5295L9.74906 12.8095V13.9995C9.74906 14.4095 9.40906 14.7495 8.99906 14.7495C8.58906 14.7495 8.24906 14.4095 8.24906 13.9995V10.9995C8.24906 10.5895 8.58906 10.2495 8.99906 10.2495H11.9991C12.4091 10.2495 12.7491 10.5895 12.7491 10.9995C12.7491 11.4095 12.4091 11.7495 11.9991 11.7495H10.8091L15.5291 16.4695C15.8191 16.7595 15.8191 17.2395 15.5291 17.5295Z"
                  fill="white"/>
              </svg>
            }
            {
              copied &&
              <CheckContainer>
                <CircleCheckIcon size={16}/>
              </CheckContainer>
            }
          </Fragment>
        }
      </Container>
    </Tooltip>
  )
}

export default FlagKey

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: ${({$width}) => $width ?? 'auto'};
  height: 32px;
  border-radius: 8px;
  transition: background-color 100ms ease, outline-color 100ms ease;
  font-size: 14px;
  color: ${({$copied}) => $copied ? colors.success['50'] : colors.text['50']};
  padding: 8px ${({$empty}) => $empty ? '12px' : '4px'} 8px 12px;
  background-color: ${({$empty}) => $empty ? colors.neutral['0A4'] : colors.background['first-layer']};
  outline: ${({$empty, $copied}) => $empty ? 'none' : `1px solid ${$copied ? colors.success['50'] : colors.stroke['0A8']}`};
  cursor: ${({$empty, $clickable}) => ($empty || !$clickable) ? 'default' : 'pointer'};
  
  ${({$empty}) => $empty ? '' : 'font-family: monospace;'}
  ${({$clickable}) => $clickable ? '' : 'pointer-events: none;'}
  ${({$warning}) => $warning ? `height: fit-content;` : ''}
  
  svg {
    path {
      fill: ${({$copied}) => $copied ? colors.success['50'] : colors.text['50']};
      transition: fill 100ms ease;
    }
  }
  
  &:hover {
    ${({$empty, $copied}) => ($empty || $copied) ? '' : `color: white;`}
    ${({$empty, $copied}) => ($empty || $copied) ? '' : `outline-color: white;`}
    
    svg {
      path {
        fill: ${({$copied}) => $copied ? colors.success['50'] : 'white'};
      }
    }
  }
  
  ${({$loading}) => $loading ? LoadingPlaceholder : ''}
`

const CheckContainer = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`
