import {useContext} from 'react'
import ConfirmationDialog from '../../ConfirmationDialog'
import {LoginContext} from '../../../contexts'

const LogoutModal = () => {
  const { logout } = useContext(LoginContext)
  return (
    <ConfirmationDialog
      modalId="logout"
      title="Are you sure?"
      description="Please confirm that you wish to logout, you will not have access to the build panel until you log back in."
      confirmLabel="Logout"
      confirmType="danger"
      cancelLabel="Go back"
      onConfirm={logout}
    />
  )
}

export default LogoutModal
