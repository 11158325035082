import styled from 'styled-components'
import {InputField, TextArea} from '@get-wrecked/simple-components'
import FlagKey from './FlagKey'
import Modal from '../../../components/Modal'
import {useContext, useMemo, useState} from 'react'
import SavePrompt from '../../../components/SavePrompt'
import {FeatureFlagsContext} from '../../../contexts'
import {InputLabelWrapper} from '../../../components/styles'
import {convertLdFlag} from '../../../utils/launchdarkly'

const EditFlagModal = ({isLaunchDarklyFlag, flagKey, onSave = () => {}, onClose = () => {}}) => {
  const {flags, updateFlag, ldFlags, updateLdFlag} = useContext(FeatureFlagsContext)

  const currentLdFlag = useMemo(() => {
    if (!isLaunchDarklyFlag) {
      return undefined
    }
    return ldFlags.find(ldFlag => ldFlag.key === flagKey)
  }, [isLaunchDarklyFlag, flagKey, ldFlags])

  const currentFlag = useMemo(() => {
    return isLaunchDarklyFlag
      ? convertLdFlag(currentLdFlag)
      : flags[flagKey]
  }, [isLaunchDarklyFlag, currentLdFlag, flags])

  const [flagValue, setFlagValue] = useState(currentFlag.value)
  const [description, setDescription] = useState(currentFlag.description)
  const [name, setName] = useState(currentFlag.name)

  const [saving, setSaving] = useState(false)
  const hasChanges = flagValue !== currentFlag.value ||
    name !== currentFlag.name ||
    description !== currentFlag.description

  const save = async () => {
    try {
      setSaving(true)
      if (isLaunchDarklyFlag) {
        const payload = {}
        if (name !== currentFlag.name) {
          payload.name = name
        }
        if (description !== currentFlag.description) {
          payload.description = description
        }
        await updateLdFlag(flagKey, payload)
      } else {
        await updateFlag(flagKey, flagValue, {
          name,
          description
        })
      }
      if (typeof onSave === 'function') {
        onSave()
      }
    } catch (err) {
      // @todo push toast
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Modal
      id="edit-flag-modal"
      size="xsmall"
      header={
        <ModalHeader>
          <FlagName>
            {currentFlag.name}
          </FlagName>
        </ModalHeader>
      }
      onClose={onClose}
      gap="20px"
    >
      {/*<InputLabelWrapper>*/}
      {/*  Current Value*/}
      {/*  <ValueInputWrapper*/}
      {/*    value={flagValue}*/}
      {/*    type={flagType}*/}
      {/*    onChange={newValue => setFlagValue(newValue)}*/}
      {/*  />*/}
      {/*</InputLabelWrapper>*/}
      <InputLabelWrapper>
        Flag Key
        <FlagKey flagKey={flagKey} width="100%"/>
      </InputLabelWrapper>
      <InputLabelWrapper>
        Name
        <InputField
          placeholder={"Name this feature flag"}
          value={name === flagKey ? '' : name}
          onChange={e => setName(e.target.value)}
        />
      </InputLabelWrapper>
      <InputLabelWrapper>
        Description
        <TextArea
          autoResize={true}
          placeholder="Describe this feature flag"
          value={description}
          onChange={e => setDescription(e.target.value)}
          style={{
            minHeight: '75px',
            maxHeight: '200px'
          }}
        />
      </InputLabelWrapper>
      {
        hasChanges &&
        <SavePrompt
          visible={hasChanges}
          saving={saving}
          description={"Changes will take effect within a few minutes."}
          onButtonClick={save}
        />
      }
    </Modal>
  )
}

export default EditFlagModal

const ModalHeader = styled.div`
  margin-inline-end: auto;
  margin-inline-start: -40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  padding: 16px 0;
`

const FlagName = styled.span`
  font-size: 15px;
`
