import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Environments} from '@get-wrecked/updates/constants'
import useLatestVersion from '../../hooks/useLatestVersion'
import {colors} from '@get-wrecked/simple-components'

const propTypes = {
  module: PropTypes.string.isRequired
}

const ModulePlaceholder = ({module}) => {
  const currentVersion = useLatestVersion({
    environment: Environments.SANDBOX,
    module
  })

  return (
    <Container>
      <Text>
        {
          module === 'empty'
            ? <>Secondary module not used</>
            : <>Using development <strong>{module}</strong> version {currentVersion?.semver}</>
        }
      </Text>
    </Container>
  )
}

ModulePlaceholder.propTypes = propTypes

export default ModulePlaceholder

const Container = styled.div`
  border: 1px dashed ${colors.stroke['0A8']};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.text['70']};
  flex: 1;
  min-width: 250px;
`

const Text = styled.span`
  margin: 16px;
`
