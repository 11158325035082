import {useMemo} from 'react'
import useVersionList from './useVersionList'

const useLatestVersion = ({environment, module}) => {
  const versionList = useVersionList({environment, module})
  const version = useMemo(() => {
    return versionList?.[0]
  }, [versionList])
  return version
}

export default useLatestVersion
