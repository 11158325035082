import styled from 'styled-components'
import {useContext, useEffect, useRef, useState, Fragment} from 'react'
import {Button, Dropdown, Tooltip} from '@get-wrecked/simple-components'
import RolloutIcon from '../../components/Icons/RolloutIcon'
import {DIRECTION_DOWN, DIRECTION_UP, qualifiesForRollout} from '@get-wrecked/updates/utils/updates'
import {UserContext} from '../../contexts'
import UserSearchInput from '../../components/UserSearchInput'

const Rollout = ({enabled, direction, totalBuckets, percentage, seed, onUpdate}) => {
  const {user} = useContext(UserContext);
  const [isEnabled, setEnabled] = useState(enabled)
  const [currentTotalBuckets, setCurrentTotalBuckets] = useState(totalBuckets)
  const [inputPercentage, setInputPercentage] = useState(percentage)
  const [currentPercentage, setCurrentPercentage] = useState(percentage)
  const [currentSeed, setCurrentSeed] = useState(seed)
  const [currentDirection, setCurrentDirection] = useState(direction)
  const [showOptions, setShowOptions] = useState(false)
  const initialEnabledState = useRef(enabled)

  // for other users
  const [selectedUsers, setSelectedUsers] = useState([])
  const [userQualifications, setUserQualifications] = useState([])

  // for your own user
  const [qualification, setQualification] = useState(null)
  const timeoutRef = useRef(null)
  const userIdTimeoutRef = useRef(null)
  const userQualificationsTimeoutRef = useRef(null)

  const onEnable = () => {
    setEnabled(true)
    setCurrentDirection('UP')
    setInputPercentage(0)
    setCurrentPercentage(0)
    setCurrentTotalBuckets(100)
  }

  const onUpdatePercentage = e => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }

    const value = e.target.value
    const number = parseInt(value, 10)

    // update input field instantly, but not the visualization of percentiles
    setInputPercentage(value)

    if (e.keyCode) {
      setCurrentPercentage(number)
    } else {
      // @todo properly manage timeout
      timeoutRef.current = setTimeout(() => {
        setCurrentPercentage(number)
        timeoutRef.current = null
      }, 800)
    }
  }

  const onUpdateTotalBuckets = e => {
    const value = e.target.value
    setCurrentTotalBuckets(value)
  }

  const onUpdateSeed = e => {
    const value = e.target.value
    if (value.length > 16) {
      return
    }
    setCurrentSeed(value)
  }

  const yourPercentile = ((qualification?.clientBucket / currentTotalBuckets) * 100)
  const wasAlreadyEnabled = initialEnabledState.current === true && enabled

  useEffect(() => {
    if (userIdTimeoutRef.current) {
      clearTimeout(userIdTimeoutRef.current)
      userIdTimeoutRef.current = null
    }
    userIdTimeoutRef.current = setTimeout(async () => {
      setQualification(await qualifiesForRollout({
        clientIdentifier: user.userId,
        seed: currentSeed,
        rolloutPercentage: currentPercentage,
        rolloutDirection: currentDirection,
        totalBuckets: currentTotalBuckets,
      }))
      userIdTimeoutRef.current = null
    }, 200)
  }, [user?.userId, currentPercentage, currentTotalBuckets, currentSeed, currentDirection])

  useEffect(() => {
    if (userQualificationsTimeoutRef.current) {
      clearTimeout(userQualificationsTimeoutRef.current)
      userQualificationsTimeoutRef.current = null
    }
    if (!selectedUsers?.length) {
      return
    }
    userQualificationsTimeoutRef.current = setTimeout(async () => {
      const qualifications = []
      for (let user of selectedUsers) {
        qualifications.push(await qualifiesForRollout({
          clientIdentifier: user.userId,
          seed: currentSeed,
          rolloutPercentage: currentPercentage,
          rolloutDirection: currentDirection,
          totalBuckets: currentTotalBuckets,
        }))
      }
      setUserQualifications(qualifications)
      userQualificationsTimeoutRef.current = null
    }, 200)
  }, [selectedUsers, currentPercentage, currentTotalBuckets, currentSeed, currentDirection])

  useEffect(() => {
    const update = {}
    if (percentage !== currentPercentage) {
      update.percentage = currentPercentage
    }
    if (totalBuckets !== currentTotalBuckets) {
      update.totalBuckets = currentTotalBuckets
    }
    if (direction !== currentDirection) {
      update.direction = currentDirection
    }
    if (seed !== currentSeed) {
      update.seed = currentSeed
    }
    onUpdate(update)
  }, [isEnabled, currentPercentage, currentTotalBuckets, currentSeed, currentDirection])

  return (
    <Container>
      <Label>
        <RolloutIcon size={20} color={currentPercentage > 0 ? 'rgba(1,210,142,1)' : 'rgba(235,77,85,1)'}/>
        Staged Rollout
      </Label>

      <Description>
        Rollout a build to a portion of users. Users are bucketed into cohorts using consistent hashing based on their user ID, the total buckets, and any seed provided.
      </Description>

      {
        !isEnabled &&
        <Button
          variant="tertiary"
          size="medium"
          onClick={onEnable}
        >
          Enable Staged Rollout
        </Button>
      }
      {
        isEnabled &&
        <>

          <CohortContainer>
            <CohortRollout>
              <CohortLeft
                active={currentDirection === 'UP'}
                width={currentDirection === 'UP' ? `${currentPercentage}%` : `${100 - currentPercentage}%`}
                percentage={currentPercentage}
                direction={currentDirection}
              />
              <CohortRight
                active={currentDirection === 'DOWN'}
                width={currentDirection === 'UP' ? `${100 - currentPercentage}%` : `${currentPercentage}%`}
                percentage={currentPercentage}
                direction={currentDirection}
              />
              <CohortPercentageLabel
                direction={currentDirection}
                percentage={currentPercentage}
              >
                {currentPercentage}%
              </CohortPercentageLabel>
              {
                qualification &&
                <>
                  <CohortUserPlacement
                    $qualified={qualification.isQualified}
                    $percentile={yourPercentile}
                  >
                  </CohortUserPlacement>
                  <Tooltip
                    tooltip={`Your user places in p${Math.round(yourPercentile)}`}
                    position="bottom"
                    wrapperStyle={{
                      position: 'absolute',
                      top: '17px',
                      left: `calc(${yourPercentile}% - 8px)`,
                      right: yourPercentile > 85 ? '0' : 'initial',
                      transition: 'left 100ms ease',
                    }}
                  >
                    <UserThumbnail qualified={qualification.isQualified}>
                      <img src={user.thumbnail}/>
                    </UserThumbnail>
                  </Tooltip>
                </>
              }
              {
                selectedUsers.map((user, index) => {
                  const qualification = userQualifications[index]
                  if (!qualification) {
                    return
                  }
                  const percentile = (qualification?.clientBucket / currentTotalBuckets) * 100
                  return (
                    <Fragment key={`selected-user-${user.userId}`}>
                      <CohortUserPlacement
                        $qualified={qualification.isQualified}
                        $percentile={percentile}
                      >
                      </CohortUserPlacement>
                      <Tooltip
                        $percentile={percentile}
                        tooltip={`${user.userName} places in p${Math.round(percentile)}`}
                        position="bottom"
                        wrapperStyle={{
                          position: 'absolute',
                          top: '17px',
                          left: `calc(${percentile}% - 8px)`,
                          right: percentile > 85 ? '0' : 'initial',
                          transition: 'left 100ms ease',
                        }}
                      >
                        <UserThumbnail qualified={qualification.isQualified}>
                          <img src={user.thumbnail}/>
                        </UserThumbnail>
                      </Tooltip>
                    </Fragment>
                  )
                })
              }
            </CohortRollout>
            <CohortAnnotations>
              <span>p0</span>
              <span>p100</span>
            </CohortAnnotations>
          </CohortContainer>

          <Options>
            <Tooltip
              tooltip={'The percentage of buckets that will receive this update.'}
              position="bottom"
              style={{
                minWidth: '200px'
              }}
            >
              <InputBox>
                <RangeInput
                  id={'percentage-input'}
                  autoComplete={'off'}
                  type={'number'}
                  min={'0'}
                  max={'100'}
                  value={inputPercentage}
                  onChange={onUpdatePercentage}
                  // onKeyDown={onPercentageKeyPress}
                  size="4"
                />
                <IconContainer onClick={() => document.getElementById('percentage-input').focus()}>%</IconContainer>
              </InputBox>
            </Tooltip>
            {
              showOptions &&
              <>
                <Tooltip
                  tooltip={
                    <FlexColumn>
                      <span>
                        The total number of buckets to cohort users into. Increasing this creates more randomization for each cohort.
                      </span>
                      <span><strong>Cannot be changed after the rollout is enabled.</strong></span>
                    </FlexColumn>
                  }
                  position="bottom"
                  style={{
                    minWidth: '200px'
                  }}
                >
                  <InputBox disabled={wasAlreadyEnabled}>
                    <RangeInput
                      id={'total-buckets-input'}
                      autoComplete={'off'}
                      type={'number'}
                      min={'100'}
                      max={'10000'}
                      value={currentTotalBuckets}
                      onChange={onUpdateTotalBuckets}
                      size="6"
                    />
                    <IconContainer onClick={() => document.getElementById('total-buckets-input').focus()}>
                      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18.5V19.38C12 21.25 11.25 22 9.37 22H4.62C3.17 22 2 20.83 2 19.38V14.63C2 12.75 2.75 12 4.62 12H5.5V15.5C5.5 17.16 6.84 18.5 8.5 18.5H12Z" fill="rgba(255, 255, 255, 0.32)"/>
                        <path d="M17 13.5V14.37C17 15.82 15.82 17 14.37 17H9.62C7.75 17 7 16.25 7 14.37V9.62C7 8.17 8.17 7 9.62 7H10.5V10.5C10.5 12.16 11.84 13.5 13.5 13.5H17Z" fill="rgba(255, 255, 255, 0.32)"/>
                        <path d="M22 4.62V9.37C22 11.25 21.25 12 19.37 12H14.62C12.75 12 12 11.25 12 9.37V4.62C12 2.75 12.75 2 14.62 2H19.37C21.25 2 22 2.75 22 4.62Z" fill="rgba(255, 255, 255, 0.32)"/>
                      </svg>
                    </IconContainer>
                  </InputBox>
                </Tooltip>

                <Tooltip
                  tooltip={
                    <FlexColumn>
                      <span>
                        Adding a seed creates another layer of randomization when placing users in a bucket.
                      </span>
                      <span><strong>Cannot be changed after the rollout is enabled.</strong></span>
                    </FlexColumn>
                  }
                  position="bottom"
                  style={{
                    minWidth: '150px'
                  }}
                >
                  <InputBox disabled={wasAlreadyEnabled}>
                    <Input
                      id={'seed-input'}
                      placeholder={'seed'}
                      value={currentSeed}
                      onChange={onUpdateSeed}
                      size="12"
                    />
                    <IconContainer onClick={() => document.getElementById('seed-input').focus()}>
                      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 15.9991C14 17.7691 13.23 19.3691 12 20.4591C10.94 21.4191 9.54 21.9991 8 21.9991C4.69 21.9991 2 19.3091 2 15.9991C2 13.9743 3.01397 12.1804 4.5554 11.0964C4.80358 10.9219 5.1393 11.0413 5.27324 11.3135C6.21715 13.2322 7.95419 14.669 10.02 15.2291C10.65 15.4091 11.31 15.4991 12 15.4991C12.4872 15.4991 12.9539 15.4529 13.4074 15.3678C13.6958 15.3137 13.9828 15.4985 13.9955 15.7916C13.9985 15.8611 14 15.9305 14 15.9991Z" fill="rgba(255, 255, 255, 0.32)"/>
                        <path d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z" fill="rgba(255, 255, 255, 0.32)"/>
                        <path d="M22 15.9991C22 19.3091 19.31 21.9991 16 21.9991C15.2555 21.9991 14.5393 21.8633 13.8811 21.6131C13.5624 21.492 13.503 21.0841 13.7248 20.8253C14.8668 19.4928 15.5 17.785 15.5 15.9991C15.5 15.6591 15.47 15.3191 15.42 14.9991C15.3902 14.8146 15.4844 14.6333 15.6478 14.5428C16.9719 13.8098 18.0532 12.6866 18.727 11.3144C18.8609 11.0418 19.1968 10.9221 19.4452 11.0968C20.9863 12.1809 22 13.9746 22 15.9991Z" fill="rgba(255, 255, 255, 0.32)"/>
                      </svg>
                    </IconContainer>
                  </InputBox>
                </Tooltip>

                <Tooltip
                  tooltip={
                    <FlexColumn>
                      <Label>
                        Rollout Direction
                      </Label>
                      <span>
                        Rolling UP will rollout UP from p0 (0 percentile) through <code>p{currentPercentage}</code> of buckets.
                      </span>
                      <span>
                        Rolling down will start at p100 and roll DOWN to <code>p{100 - currentPercentage}</code>.
                      </span>
                      <span><strong>Cannot be changed after the rollout is enabled.</strong></span>
                    </FlexColumn>
                  }
                  position="bottom"
                  style={{
                    minWidth: '200px',
                    right: '0',
                    left: 'initial'
                  }}
                >
                  <Dropdown
                    value={currentDirection}
                    size="large"
                    options={[
                      {
                        label: `Rolling UP`,
                        value: DIRECTION_UP,
                        onClick: () => setCurrentDirection(DIRECTION_UP)
                      },
                      {
                        label: `Rolling DOWN`,
                        value: DIRECTION_DOWN,
                        onClick: () => setCurrentDirection(DIRECTION_DOWN)
                      }
                    ]}
                    disabled={wasAlreadyEnabled}
                    style={{
                      display: 'flex',
                      width: 'fit-content',
                      minWidth: 0
                    }}
                  />
                </Tooltip>
              </>
            }
            {
              !showOptions &&
              <Button
                variant="secondary"
                size="medium"
                onClick={() => setShowOptions(true)}
              >
                Advanced Options
              </Button>
            }
          </Options>

          {
            showOptions &&
            <>
              <Label>
                User Placement
              </Label>
              <Description>
                Map users to their rollout percentile placements (for visualization purposes only).
              </Description>
              <FlexRow>
                <InputContainer>
                  <UserSearchInputStyled
                    id={'search-users'}
                    placeholder={'Search users...'}
                    selectedUsers={selectedUsers}
                    onClickUser={user => {
                      if (selectedUsers.some(u => u.userId === user.userId)) {
                        setSelectedUsers(selectedUsers.filter(u => u.userId !== user.userId))
                      } else {
                        setSelectedUsers([
                          ...selectedUsers,
                          user
                        ])
                      }
                    }}
                  />
                </InputContainer>
              </FlexRow>
            </>
          }
        </>
      }
    </Container>
  )
}

export default Rollout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.075);
  border: 1px solid rgba(255, 255, 255, 0.1);
`

const Description = styled.span`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.64);
  font-weight: normal;
`

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.82);
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  height: 35px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  width: fit-content;
  ${props => props.disabled ? 'opacity: 0.35;' : ''}
  ${props => props.disabled ? 'pointer-events: none;' : ''}

  &:hover {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.5);
  }
`

const InputBox = styled(InputContainer)`
  padding: 0;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  height: 100%;
  font-weight: 500;
  padding: 0 8px;
  color: white;
  
  &:hover {
    background-color: transparent;
  }
  
  &:focus {
    outline: none;
  }
`

const UserSearchInputStyled = styled(UserSearchInput)`
  height: 100%;
  
   input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-weight: 500;
    padding: 0 8px;

    &:hover {
      background-color: transparent;
    }
  }
`

const RangeInput = styled(Input)`
  text-align: center;
  color: white;
  font-family: 'DM Sans', sans-serif;
  
  &:focus {
    outline: none;
  }
`

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.32);
  font-weight: 500;
  font-size: 17px;
`

const CohortContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2px 0 4px;
`

const CohortRollout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const CohortPercentile = styled.div`
  width: ${props => props.width || '100%'};
  border-radius: 8px;
  height: 16px;
  background-color: ${props => props.active ? 'rgba(1,210,142,0.64)' : 'rgba(255,184,75,0.64)'};
  border: 1px solid ${props => props.active ? '#01d28e' : '#FFB84B'};
  box-sizing: border-box;
  transition: background-color 300ms ease, width 200ms ease, opacity 200ms ease, border-radius 200ms ease;
`

const CohortLeft = styled(CohortPercentile)`
  border-top-right-radius: ${props => props.direction === 'UP' ? (props.percentage === 100 ? 6 : 0) : (props.percentage > 0 ? 0 : 6)};
  border-bottom-right-radius: ${props => props.direction === 'UP' ? (props.percentage === 100 ? 6 : 0) : (props.percentage > 0 ? 0 : 6)};
  ${props => props.direction === 'UP' ? `opacity: ${props.percentage === 0 ? 0 : 0.8};` : ''}
  ${props => props.direction === 'DOWN' && props.percentage === 100 ? `border: none;` : ''}
`

const CohortRight = styled(CohortPercentile)`
  border-top-left-radius: ${props => props.direction === 'UP' ? (props.percentage > 0 ? 0 : 6) : (props.percentage < 100 ? 0 : 6)};
  border-bottom-left-radius: ${props => props.direction === 'UP' ? (props.percentage > 0 ? 0 : 6) : (props.percentage < 100 ? 0 : 6)};
  ${props => props.direction === 'DOWN' ? `opacity: ${props.percentage === 0 ? 0 : 0.8};` : ''}
`

const CohortUserPlacement = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${({$qualified}) => $qualified ? '#01d28e' : 'white'};
  position: absolute;
  top: -2px;
  left: ${({$percentile}) => $percentile}%;
  transition: left 100ms ease, background-color 100ms ease;
`
const UserPlacementIcon = styled.svg`
  position: absolute;
  top: 17px;
  left: calc(${props => props.percentile}% - 8px);
  transition: left 100ms ease, fill 100ms ease;
  
  path {
    fill: ${props => props.qualified ? '#01d28e' : 'white'};
  }
`
const UserThumbnail = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid ${props => props.qualified ? '#01d28e' : 'white'};
  background-color: black;
  overflow: hidden;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
const UserTooltip = styled(Tooltip)`
  position: absolute;
  top: 17px;
  left: calc(${({$percentile}) => $percentile}% - 10px);
  transition: left 100ms ease;
`

const CohortPercentageLabel = styled.span`
  pointer-events: none;
  position: absolute;
  top: 0px;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 1px 1px 0px black;
  color: white;
  transition: left 200ms ease, right 200ms ease;
  ${(props) => props.direction === 'UP'
          ? `${props.percentage <= 5 ? 'left: 6px;' : `right: calc(100% - ${props.percentage}% + 4px);`};`
          : `${props.percentage <= 5 ? 'right: 5px;' : `left: calc(100% - ${props.percentage}% + 5px);`};`
  }
`

const CohortAnnotations = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
`

const FlexRow = styled.div`
  gap: 8px;
  display: flex;
`

const FlexColumn = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`
