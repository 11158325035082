import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

const ApplicationIcon = ({size = 24, color = '#fff'}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22C6.25 21.59 6.59 21.25 7 21.25H17C17.41 21.25 17.75 21.59 17.75 22C17.75 22.41 17.41 22.75 17 22.75Z" fill={color}/>
      <path d="M18 2H6C3.79 2 2 3.79 2 6V15C2 17.21 3.79 19 6 19H18C20.21 19 22 17.21 22 15V6C22 3.79 20.21 2 18 2ZM9.99 7.32C10.55 7.32 11 7.77 11 8.32C11 8.87 10.55 9.32 10 9.32C9.45 9.32 9 8.87 9 8.32C9 7.77 9.44 7.32 9.99 7.32ZM6.84 7.32C7.4 7.32 7.85 7.77 7.85 8.32C7.85 8.87 7.4 9.32 6.85 9.32C6.3 9.32 5.85 8.87 5.85 8.32C5.85 7.77 6.28 7.32 6.84 7.32ZM17.26 14.86H6.76C6.34 14.86 6 14.52 6 14.11C6 13.7 6.33 13.36 6.74 13.36H17.27C17.68 13.36 18.02 13.7 18.02 14.11C18.02 14.52 17.68 14.86 17.26 14.86ZM17.26 9.07H13.58C13.17 9.07 12.83 8.73 12.83 8.32C12.83 7.91 13.17 7.57 13.58 7.57H17.26C17.67 7.57 18.01 7.91 18.01 8.32C18.01 8.73 17.68 9.07 17.26 9.07Z" fill={color}/>
    </svg>
  )
}

ApplicationIcon.propTypes = propTypes

export default ApplicationIcon
