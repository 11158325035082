import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  style: PropTypes.object
}

const CheckIcon = ({size, color = '#fff', style = {}}) => {
  return (
    <svg width={`${size}`} height={`${parseInt(size, 10) * .772}`} style={style} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2266_1508)">
        <path d="M7.48 16.5L0 9.13L2.31 6.93L7.48 11.88L19.58 0L22 2.31L7.48 16.5Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_2266_1508">
          <rect width="22" height="16.5" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  )
}

CheckIcon.propTypes = propTypes

export default CheckIcon
