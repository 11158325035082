import PropTypes from 'prop-types'

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
}

const ChangelogIcon = ({size, color}) => {
  return (
    // <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
    //     fill={color}/>
    //   <path
    //     d="M8.49935 17.6901C7.88935 17.6901 7.32935 17.4701 6.91935 17.0701C6.42935 16.5801 6.21935 15.8701 6.32935 15.1201L6.75935 12.1101C6.83935 11.5301 7.21935 10.7801 7.62935 10.3701L15.5093 2.49006C17.4993 0.500059 19.5193 0.500059 21.5093 2.49006C22.5993 3.58006 23.0893 4.69006 22.9893 5.80006C22.8993 6.70006 22.4193 7.58006 21.5093 8.48006L13.6293 16.3601C13.2193 16.7701 12.4693 17.1501 11.8893 17.2301L8.87935 17.6601C8.74935 17.6901 8.61935 17.6901 8.49935 17.6901ZM16.5693 3.55006L8.68935 11.4301C8.49935 11.6201 8.27935 12.0601 8.23935 12.3201L7.80935 15.3301C7.76935 15.6201 7.82935 15.8601 7.97935 16.0101C8.12935 16.1601 8.36935 16.2201 8.65935 16.1801L11.6693 15.7501C11.9293 15.7101 12.3793 15.4901 12.5593 15.3001L20.4393 7.42006C21.0893 6.77006 21.4293 6.19006 21.4793 5.65006C21.5393 5.00006 21.1993 4.31006 20.4393 3.54006C18.8393 1.94006 17.7393 2.39006 16.5693 3.55006Z"
    //     fill={color}/>
    //   <path
    //     d="M19.8496 9.82978C19.7796 9.82978 19.7096 9.81978 19.6496 9.79978C17.0196 9.05978 14.9296 6.96978 14.1896 4.33978C14.0796 3.93978 14.3096 3.52978 14.7096 3.40978C15.1096 3.29978 15.5196 3.52978 15.6296 3.92978C16.2296 6.05978 17.9196 7.74978 20.0496 8.34978C20.4496 8.45978 20.6796 8.87978 20.5696 9.27978C20.4796 9.61978 20.1796 9.82978 19.8496 9.82978Z"
    //     fill={color}/>
    // </svg>
    // <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M20.5 11.3V7.04001C20.5 3.01001 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01001 3.5 7.04001V18.3C3.5 20.96 4.96001 21.59 6.73001 19.69L6.73999 19.68C7.55999 18.81 8.80999 18.88 9.51999 19.83L10.53 21.18" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M8 7H16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M9 11H15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M18.211 14.7703L14.671 18.3103C14.531 18.4503 14.401 18.7103 14.371 18.9003L14.181 20.2503C14.111 20.7403 14.451 21.0803 14.941 21.0103L16.291 20.8203C16.481 20.7903 16.751 20.6603 16.881 20.5203L20.421 16.9803C21.031 16.3703 21.321 15.6603 20.421 14.7603C19.531 13.8703 18.821 14.1603 18.211 14.7703Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M17.6992 15.2803C17.9992 16.3603 18.8392 17.2003 19.9192 17.5003" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3809 22.7502H3.2409C2.2909 22.7502 1.41091 22.3103 0.840908 21.5403C0.260908 20.7603 0.0909169 19.7802 0.380917 18.8502L4.59091 5.32019C4.97091 4.06019 6.13092 3.2002 7.45092 3.2002H19.7509C20.9609 3.2002 22.0509 3.92028 22.5109 5.04028C22.7609 5.62028 22.8109 6.2803 22.6609 6.9303L19.2909 20.4602C18.9709 21.8102 17.7709 22.7502 16.3809 22.7502ZM7.4609 4.71021C6.8109 4.71021 6.22091 5.14026 6.03091 5.77026L1.82092 19.3003C1.68092 19.7703 1.76091 20.2603 2.06091 20.6603C2.34091 21.0403 2.78091 21.2603 3.25091 21.2603H16.3909C17.0809 21.2603 17.6809 20.7902 17.8409 20.1202L21.2109 6.5802C21.2909 6.2502 21.2709 5.92025 21.1409 5.63025C20.9009 5.06025 20.3709 4.7002 19.7609 4.7002H7.4609V4.71021Z" fill={color}/>
      <path d="M20.78 22.75H16C15.59 22.75 15.25 22.41 15.25 22C15.25 21.59 15.59 21.25 16 21.25H20.78C21.19 21.25 21.57 21.08 21.85 20.78C22.13 20.48 22.27 20.08 22.24 19.67L21.25 6.05002C21.22 5.64002 21.53 5.27997 21.94 5.24997C22.35 5.22997 22.71 5.52991 22.74 5.93991L23.73 19.56C23.79 20.38 23.5 21.2 22.94 21.8C22.39 22.41 21.6 22.75 20.78 22.75Z" fill={color}/>
      <path d="M9.67977 7.12971C9.61977 7.12971 9.55977 7.11969 9.49977 7.10969C9.09977 7.00969 8.84979 6.60966 8.94979 6.19966L9.98976 1.87971C10.0898 1.47971 10.4898 1.22966 10.8998 1.32966C11.2998 1.42966 11.5498 1.8297 11.4498 2.2397L10.4098 6.55977C10.3298 6.89977 10.0198 7.12971 9.67977 7.12971Z" fill={color}/>
      <path d="M16.3795 7.13974C16.3295 7.13974 16.2694 7.13972 16.2194 7.11972C15.8194 7.02972 15.5595 6.62971 15.6395 6.22971L16.5794 1.88974C16.6694 1.47974 17.0694 1.22979 17.4694 1.30979C17.8694 1.38979 18.1294 1.79968 18.0494 2.19968L17.1094 6.53977C17.0394 6.89977 16.7295 7.13974 16.3795 7.13974Z" fill={color}/>
      <path d="M15.6992 12.75H7.69922C7.28922 12.75 6.94922 12.41 6.94922 12C6.94922 11.59 7.28922 11.25 7.69922 11.25H15.6992C16.1092 11.25 16.4492 11.59 16.4492 12C16.4492 12.41 16.1092 12.75 15.6992 12.75Z" fill={color}/>
      <path d="M14.6992 16.75H6.69922C6.28922 16.75 5.94922 16.41 5.94922 16C5.94922 15.59 6.28922 15.25 6.69922 15.25H14.6992C15.1092 15.25 15.4492 15.59 15.4492 16C15.4492 16.41 15.1092 16.75 14.6992 16.75Z" fill={color}/>
    </svg>
  )
}

ChangelogIcon.propTypes = propTypes

export default ChangelogIcon
