import {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Checkbox, colors, InputField} from '@get-wrecked/simple-components'
import {UpdateTypes} from '../UpdateTypeSelection'
import styled from 'styled-components'

const propTypes = {
  updateType: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  setConfirmed: PropTypes.func.isRequired
}

const ConfirmForceUpdatePrompt = ({updateType, isConfirmed, setConfirmed}) => {
  return (
    <Fragment>
      {
        updateType === UpdateTypes.FORCED &&
        <ConfirmationCheckbox onClick={() => setConfirmed(!isConfirmed)}>
          <Checkbox
            checked={isConfirmed}
            onChange={e => setConfirmed(e.target.checked)}
          />
          <span>I confirm I know what I'm doing by selecting <strong>Forced</strong> update</span>
        </ConfirmationCheckbox>
      }
      {
        updateType === UpdateTypes.IMMEDIATE_FORCED &&
        <ConfirmationInput>
          <span>To confirm you know what you're doing, type the word <code>immediate</code> below.</span>
          <InputField
            size="medium"
            placeholder="Confirm by typing the word 'immediate'"
            onChange={e => {
              setConfirmed(e.target.value === 'immediate')
            }}
          />
        </ConfirmationInput>
      }
    </Fragment>
  )
}

ConfirmForceUpdatePrompt.propTypes = propTypes

export default ConfirmForceUpdatePrompt

const ConfirmationCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text['30']};
  width: fit-content;
  cursor: pointer;
  
  &:hover {
    color: white;
  }
`

const ConfirmationInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 13px;
  font-weight: normal;
  color: ${colors.text['30']};
`
