import {createContext} from "react";

export const UserContext = createContext(undefined);
export const LoginContext = createContext(undefined);
export const SelectedVersionContext = createContext(undefined);
export const SelectedEnvironmentContext = createContext(undefined);
export const SelectedVersionHistoryContext = createContext(undefined);
export const SelectedActiveBuildContext = createContext(undefined);
export const BuildApiContext = createContext(undefined);
export const EnvironmentContext = createContext(undefined);
export const VersionListContext = createContext(undefined);
export const ToastContext = createContext([]);
export const WhatsNewContext = createContext({})
export const ModalContext = createContext([])
export const ReleasesContext = createContext({})
export const ChangelogsContext = createContext({})
export const FeatureFlagsContext = createContext({})
export const TimeAgoContext = createContext()
