import {useContext, useEffect, useMemo, useState} from 'react'
import equal from 'deep-equal'
import styled from 'styled-components'
import {Checkbox, colors, InputField, Tooltip} from '@get-wrecked/simple-components'
import {Environments} from '@get-wrecked/updates/constants'
import {ChangelogsContext, EnvironmentContext, ToastContext} from '../../../contexts'
import Modal from '../../../components/Modal'
import SavePrompt from '../../../components/SavePrompt'
import Badge from '../../../components/Badge'
import {ColoredEnvironmentIcon} from '../../../components/Environment'

const ChangelogEnvironmentsModal = ({id}) => {
  const {environments, getEnvironment, loaded: environmentsLoaded} = useContext(EnvironmentContext)
  const {changelogs, updateChangelog} = useContext(ChangelogsContext)
  const {pushToast} = useContext(ToastContext)

  const changelog = useMemo(() => {
    return changelogs.find(log => log.id === id)
  }, [id, changelogs])

  const [changelogEnvironments, setChangelogEnvironments] = useState(changelog.environments)
  const hasChanges = !equal(changelogEnvironments, changelog.environments)

  const [search, setSearch] = useState('')
  const [environmentOptions, setEnvironmentOptions] = useState([])

  const selectedEnvironments = useMemo(() => {
    if (!environmentsLoaded) {
      return []
    }
    return changelogEnvironments.map(env => getEnvironment(env))
  }, [environmentsLoaded, getEnvironment, environments, changelogEnvironments])

  const addEnvironment = environment => {
    setChangelogEnvironments([
      ...changelogEnvironments.filter(env => env !== (environment.value ?? environment)),
      environment.value
    ])
  }

  const removeEnvironment = environment => {
    setChangelogEnvironments(changelogEnvironments.filter(env => env !== (environment.value ?? environment)))
  }

  const onSave = async () => {
    try {
      await updateChangelog(id, {
        environments: changelogEnvironments
      })
    } catch (err) {
      pushToast({
        title: 'Error updating changelog environments',
        body: err.message,
        type: 'error'
      })
    }
  }

  useEffect(() => {
    const sortCriteria = (obj) => {
      if (obj.static && obj.public) return 0; // static and public true always first
      if (obj.static) return 1; // static and not public next
      if (obj.public) return 2; // public next
      return 3; // all the rest
    };

    if (!search?.length) {
      setEnvironmentOptions(
        environments
          .filter(env => !changelogEnvironments.includes(env.value))
          .sort((a, b) => sortCriteria(a) - sortCriteria(b))
      )
    } else {
      const options = environments
        .filter(env => {
          if (changelogEnvironments.includes(env.value)) {
            return false
          }
          return env.label.toLowerCase().includes(search.toLowerCase()) ||
            env.value.toLowerCase().includes(search.toLowerCase()) ||
            search.toLowerCase().includes(env.label.toLowerCase()) ||
            search.toLowerCase().includes(env.value.toLowerCase())
        })
        .sort((a, b) => sortCriteria(a) - sortCriteria(b))
      setEnvironmentOptions(options)
    }
  }, [search, environments, changelogEnvironments])

  return (
    <Modal
      id="changelog-environments-modal"
      size="small"
      header="Changelog Environments"
    >
      <Body>
        <InputField
          placeholder="Search environments..."
          onChange={e => setSearch(e.target.value)}
        />
        <EnvironmentsContainer className="scrollable y">
          {
            selectedEnvironments.map(env => {
              if (!env) {
                return
              }

              const canRemove = env.value !== Environments.SANDBOX

              const EnvCheckbox = (
                <EnvironmentCheckbox
                  key={`env-check-${env.value}`}
                  onClick={() => removeEnvironment(env)}
                  $disabled={!canRemove}
                >
                  <EnvironmentLabel>
                    <ColoredEnvironmentIcon
                      size={20}
                      environment={env}
                    />
                    {env.label}
                    {
                      env.public &&
                      <Badge>
                        Public
                      </Badge>
                    }
                  </EnvironmentLabel>
                  <Checkbox
                    onChange={e => console.log(e.target.checked)}
                    checked={true}
                    disabled={!canRemove}
                  />
                </EnvironmentCheckbox>
              )

              if (!canRemove) {
                return (
                  <Tooltip
                    key={`env-tooltip-${env.value}`}
                    tooltip={`Each changelog will always show in ${env.label}`}
                    position="bottom"
                  >
                    {EnvCheckbox}
                  </Tooltip>
                )
              }
              return EnvCheckbox
            })
          }
          {
            environmentOptions.map(env => {
              return (
                <EnvironmentCheckbox
                  key={`env-check-${env.value}`}
                  onClick={() => addEnvironment(env)}
                >
                  <EnvironmentLabel>
                    <ColoredEnvironmentIcon
                      size={20}
                      environment={env}
                    />
                    {env.label}
                    {
                      env.public &&
                      <Badge>
                        Public
                      </Badge>
                    }
                  </EnvironmentLabel>
                  <Checkbox
                    onChange={e => console.log(e.target.checked)}
                    checked={false}
                  />
                </EnvironmentCheckbox>
              )
            })
          }
        </EnvironmentsContainer>
      </Body>
      <SavePrompt
        visible={hasChanges}
        description={"All saved changes will take effect immediately"}
        onButtonClick={onSave}
      />
    </Modal>
  )
}

export default ChangelogEnvironmentsModal

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const EnvironmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 250px;
  max-height: 250px;
`

const EnvironmentCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.stroke['0A8']};
  background-color: ${colors.neutral['0A4']};
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    border: 1px solid ${colors.stroke['0A16']};
    background-color: ${colors.stroke['0A8']};
  }
  
  ${({$disabled}) => $disabled ? 'pointer-events: none; opacity: 0.64;' : ''}
`

const EnvironmentLabel = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`
