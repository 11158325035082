
const OverflowIcon = ({size = 24, color = '#fff'}) => {
  return (
    <svg width={`${size / 3}`} height={`${size}`} viewBox="0 0 8 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 20C2 21.1 2.9 22 4 22C5.1 22 6 21.1 6 20C6 18.9 5.1 18 4 18C2.9 18 2 18.9 2 20Z" fill={color} stroke={color} strokeWidth="1.5"/>
      <path d="M2 4C2 5.1 2.9 6 4 6C5.1 6 6 5.1 6 4C6 2.9 5.1 2 4 2C2.9 2 2 2.9 2 4Z" fill={color} stroke={color} strokeWidth="1.5"/>
      <path d="M2 12C2 13.1 2.9 14 4 14C5.1 14 6 13.1 6 12C6 10.9 5.1 10 4 10C2.9 10 2 10.9 2 12Z" fill={color} stroke={color} strokeWidth="1.5"/>
    </svg>
  )
}

export default OverflowIcon
