import axios from "axios";

const featureFlags = ['string_id_v2']
const userAgent = [
  `Medal-Builds/1.0.0 (${featureFlags.join('; ')})`
];

const get = async (route) => {
  return axios.get(`https://medal.tv/api${route.startsWith('/') ? route : `/${route}`}`, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
};

const post = async (route, body) => {
  return axios.post(`https://medal.tv/api${route.startsWith('/') ? route : `/${route}`}`, body, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
};

const deleteRequest = async (route) => {
  return axios.delete(`https://medal.tv/api${route.startsWith('/') ? route : `/${route}`}`, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
}

export const searchUsers = async (query, {limit = 10, offset = 0} = {}) => {
  const route = `/search?q=${query}&limit=${limit}&offset=${offset}&collection=user`
  return get(route)
}

export const getUser = async (userId) => {
  const route = `/users/${userId}`
  return get(route)
}

export const getUsers = async (userIds) => {
  const route = `/users?userIds=${userIds.join(',')}&enrichment=slim`
  return get(route)
}

export {
  get,
  post,
  deleteRequest
}
