const releaseAdminRoles = ['H-qdRGEkA', '1BnblabfN']
const staffRoles = ['1BnblabfN', '2t7AEfTl9', '3j26Dgv2q', '4Nau3hi1r', '5COwXzpdb', 'cY-HKKxeP'];

export const isMedalStaff = user => {
  return user?.roles?.some?.(role => staffRoles.includes(role.id));
}

export const isMedalAdmin = user => {
  return user?.roles?.some?.(role => releaseAdminRoles.includes(role.id));
}
