import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {useNavigate} from 'react-router';
import {Environments, Modules} from '@get-wrecked/updates/constants'
import {getAuth, signInWithCustomToken} from "firebase/auth";

import {
  BuildApiContext,
  ChangelogsContext,
  EnvironmentContext, FeatureFlagsContext,
  LoginContext,
  ModalContext,
  ReleasesContext,
  SelectedActiveBuildContext,
  SelectedEnvironmentContext,
  SelectedVersionContext,
  SelectedVersionHistoryContext, TimeAgoContext,
  ToastContext,
  UserContext,
  VersionListContext,
  WhatsNewContext,
} from '../index';

import {deleteRequest, get, post} from '../../utils/api';
import {isMedalAdmin, isMedalStaff} from '../../utils/user';
import {
  get as getBuildApi,
  post as postBuildApi,
  patch as patchBuildApi,
  deleteRequest as deleteBuildApi
} from '../../utils/builds-api';
import {versionListUrl} from '../../utils/versions';
import {setProperty, setUserId, track} from '../../utils/amplitude'
import {
  addChangelog,
  getAndListenChangelogs,
  getChangelogs,
  updateChangelog as updateFirestoreChangelog,
  deleteChangelog as deleteFirestoreChangelog,
} from '../../utils/firestore'
import {FlagSourceOptions} from '../../presentations/Modals/FeatureFlagsModal/flagsConstants'

/**
 * @deprecated
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const ModalProvider = ({children}) => {
  const [selectedEnvironment, setEnvironment] = useState(undefined);
  const [selectedVersion, setVersion] = useState(undefined);
  const [selectedVersionHistory, setVersionHistory] = useState(undefined);
  const [selectedActiveBuild, setActiveBuild] = useState(undefined);
  const [whatsNew, setWhatsNew] = useState(undefined);
  const navigate = useNavigate();

  const setSelectedEnvironment = useCallback((environment, shouldNavigate = true) => {
    if (!environment && shouldNavigate !== false) {
      navigate('/')
    }
    setEnvironment(environment)
  }, [])

  const setSelectedVersion = useCallback((version, shouldNavigate = true) => {
    if (!version && shouldNavigate !== false) {
      navigate('/')
    }
    setVersion(version)
  }, [])

  const setSelectedVersionHistory = useCallback((versionHistory, shouldNavigate = true) => {
    if (!versionHistory && shouldNavigate !== false) {
      navigate('/')
    }
    setVersionHistory(versionHistory)
  }, [])

  const setSelectedActiveBuild = useCallback((build, shouldNavigate = true) => {
    if (!build && shouldNavigate !== false) {
      navigate('/')
    }
    setActiveBuild(build)
  }, [])

  return (
    <SelectedEnvironmentContext.Provider value={{selectedEnvironment, setSelectedEnvironment}}>
      <SelectedVersionHistoryContext.Provider value={{selectedVersionHistory, setSelectedVersionHistory}}>
        <SelectedVersionContext.Provider value={{selectedVersion, setSelectedVersion}}>
          <SelectedActiveBuildContext.Provider value={{selectedActiveBuild, setSelectedActiveBuild}}>
            <WhatsNewContext.Provider value={{whatsNew, setWhatsNew}}>
              {children}
            </WhatsNewContext.Provider>
          </SelectedActiveBuildContext.Provider>
        </SelectedVersionContext.Provider>
      </SelectedVersionHistoryContext.Provider>
    </SelectedEnvironmentContext.Provider>
  )
}

const parseCachedUser = () => {
  const cachedUser = localStorage.getItem('cached-user')
  try {
    return JSON.parse(cachedUser)
  } catch (err) {
    console.error(`Error parsing cached user: ${err.message}`)
  }
  return undefined
}

export const AuthProvider = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [user, setUser] = useState(localStorage.getItem('medal-user') ? parseCachedUser() : undefined);
  const loadingLock = useRef(false);

  const login = async (username, password, callback = () => {}) => {
    try {
      const response = await post(`/authentication`, {
        userName: username,
        password: password
      })
      if (response?.data?.userId && response?.data?.key) {
        localStorage.setItem('medal-user', `${response.data.userId},${response.data.key}`);
        await loadUser();
        track('login')
        setLoggingIn(false);
        callback()
      } else if (response?.data?.errorMessage) {
        callback(response.data.errorMessage)
      }
    } catch (err) {
      console.error('Failed to login', err);
      if (err.response?.data?.errorMessage) {
        callback(err.response.data.errorMessage)
      } else {
        callback(err.toString())
      }
    }
    setLoading(false);
  }

  const logout = async () => {
    setUserId(undefined)
    const userId = localStorage.getItem('medal-user')?.split?.(',')?.[0];
    try {
      await deleteRequest(`/users/${userId}/sessions/@current`)
    } catch (err) {
      console.error(err);
    }
    localStorage.removeItem('medal-user');
    localStorage.removeItem('cached-user');
    setUser(undefined);
    setLoggingIn(false);
    setLoading(false);
  }

  const loadUser = async () => {
    if (loadingLock.current) {
      console.warn('Rate limited call to loadUser while already loading')
      return;
    }

    loadingLock.current = true;
    console.log('Attempting to load logged in user');
    const storedUser = localStorage.getItem('medal-user');
    if (storedUser && storedUser.split(',').length === 2) {
      try {
        const {data} = await get(`/users/${storedUser.split(',')[0]}`);
        if (!data.errorMessage && data.userId) {
          console.log('Loaded logged in user:', data.userId);

          let loggedInUser = {
            ...data,
            isStaff: isMedalStaff(data),
            isAdmin: isMedalAdmin(data)
          }

          const resp = await getBuildApi(`/profile/${data.userId}`)
          if (resp?.data && !resp.data.errorMessage) {
            const profile = resp.data
            console.log('Loaded users build profile');
            loggedInUser = {
              ...loggedInUser,
              ...profile
            }
          }

          setUser(loggedInUser);

          localStorage.setItem('cached-user', JSON.stringify(loggedInUser));
          setUserId(`${data.userId}`);
          setProperty('userName', data.userName)
        } else {
          throw new Error(data.errorMessage);
        }
      } catch (err) {
        localStorage.removeItem('medal-user');
        console.error('Failed to load logged in user:', storedUser, err);
        throw err
      }
    } else {
      console.warn('No logged in user found');
    }

    try {
      // separately, authenticate the user against firebase auth
      const res = await axios.post('https://api-v2.medal.tv/authentication/firestore', {}, {
        headers: {
          'x-authentication': storedUser
        }
      })

      const auth = getAuth()
      const userCredential = await signInWithCustomToken(auth, res.data.token)
      const user = userCredential.user;
      console.log('authenticated with firestore')
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error('firebase auth error:', errorCode, errorMessage)
    }

    loadingLock.current = false;
    setLoading(false);
  }

  useEffect(() => {
    loadUser();
  }, [])

  return (
    <UserContext.Provider value={{loading, user, setUser, loadUser}}>
      <LoginContext.Provider value={{loggingIn, setLoggingIn, login, logout}}>
        {children}
      </LoginContext.Provider>
    </UserContext.Provider>
  )
}

export const EnvironmentFilters = {
  ALL: 'All',
  DYNAMIC: 'Dynamic',
  PUBLIC: 'Public',
}

export const EnvironmentProvider = ({children}) => {
  const {user} = useContext(UserContext);
  const [loaded, setLoaded] = useState(false)
  const [environments, setEnvironments] = useState([]);
  const [activeEnvironments, setActiveEnvironments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [environmentFilter, setEnvironmentFilter] = useState(EnvironmentFilters.ALL)

  const fetchEnvironments = async () => {
    if (user?.isStaff) {
      console.log('Loading staff environments');
      const response = await axios.get(`https://builds-api.medal.tv/environments`, {
        headers: {
          'x-authentication': localStorage.getItem('medal-user')
        }
      });
      setEnvironments(response.data);
      setLoaded(true)
      return response.data;
    } else {
      console.log('Loading public environments');
      const response = await axios.get(`https://cdn.medal.tv/public/environments.json`);
      setEnvironments(response.data);
      setLoaded(true)
      return response.data;
    }
  }

  const getEnvironment = value => {
    return environments.find(environment => environment.value === value)
  }

  return (
    <EnvironmentContext.Provider value={{environments, activeEnvironments, setActiveEnvironments, fetchEnvironments, getEnvironment, searchQuery, setSearchQuery, environmentFilter, setEnvironmentFilter, loaded}}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export const BuildApiProvider = ({children}) => {
  const {loading, user} = useContext(UserContext);
  const {pushToast} = useContext(ToastContext);
  const {fetchReleaseSchedule} = useContext(ReleasesContext);
  const {fetchVersionList} = useContext(VersionListContext)
  const {environments, getEnvironment} = useContext(EnvironmentContext)
  const buildApiStateRef = useRef(undefined);
  const [buildApiState, setBuildApiState] = useState(undefined);
  const buildApiLock = useRef(false);
  const refreshIntervalRef = useRef(null);

  const apiGet = useCallback(async (route) => {
    try {
      return await getBuildApi(route)
    } catch (err) {
      const msg = err.response.data?.message || err.response.data?.errorMessage || err.toString()
      if (err.response.status === 0 && msg.includes('Network Error')) {
        pushToast({
          title: `Network Error`,
          body: 'Build server is offline',
          type: 'error'
        })
      } else {
        pushToast({
          title: `Error ${err.response.status}`,
          body: err.response.data?.message || err.response.data?.errorMessage || err.toString(),
          type: 'error'
        })
      }
      throw err
    }
  }, [pushToast])

  const apiPost = useCallback(async (route, body) => {
    try {
      return await postBuildApi(route, body)
    } catch (err) {
      pushToast({
        title: `Error ${err.response.status}`,
        body: err.response.data?.message || err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
      throw err
    }
  }, [pushToast])

  const fetchBuildApiState = useCallback(async () => {
    if (buildApiLock.current) {
      console.warn('Rate limited GET build api state request, already requested');
      return;
    }
    buildApiLock.current = true;
    try {
      const response = await apiGet('/');
      const state = response.data
      const currentState = {
        stateHash: buildApiStateRef.current?.stateHash,
        scheduleHash: buildApiStateRef.current?.scheduleHash,
        productionHash: buildApiStateRef.current?.productionHash,
        earlyAccessHash: buildApiStateRef.current?.earlyAccessHash,
      }
      if (state?.stateHash && currentState.stateHash !== state.stateHash) {
        console.log('Build state updated:', buildApiStateRef.current?.stateHash + '-> ' + state.stateHash);
        buildApiStateRef.current = state;
        setBuildApiState(state);
      }
      if (state?.scheduleHash && currentState.scheduleHash !== state.scheduleHash) {
        console.log('Release schedule changes, fetching latest...')
        buildApiStateRef.current = state;
        await fetchReleaseSchedule()
      }
      if (environments.length > 0 && state?.productionHash && currentState.productionHash !== state.productionHash) {
        console.log('Production versions changed, fetching latest...')
        buildApiStateRef.current = state;
        try {
          await Promise.all([
            fetchVersionList({
              environment: getEnvironment(Environments.PRODUCTION),
              module: Modules.ELECTRON
            }),
            fetchVersionList({
              environment: getEnvironment(Environments.PRODUCTION),
              module: Modules.RECORDER
            })
          ])
        } catch (err) {
          console.error('Error fetching latest production versions:', err)
        }
      }
      if (environments.length > 0 && state?.earlyAccessHash && currentState.earlyAccessHash !== state.earlyAccessHash) {
        console.log('Early Access versions changed, fetching latest...')
        buildApiStateRef.current = state;
        try {
          await Promise.all([
            fetchVersionList({
              environment: getEnvironment(Environments.EARLY_ACCESS),
              module: Modules.ELECTRON
            }),
            fetchVersionList({
              environment: getEnvironment(Environments.EARLY_ACCESS),
              module: Modules.RECORDER
            })
          ])
        } catch (err) {
          console.error('Error fetching latest early access versions:', err)
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      buildApiLock.current = false;
    }
  }, [apiGet, environments, getEnvironment, fetchVersionList])

  useEffect(() => {
    if (!loading && user?.isStaff && buildApiState && Object.keys(buildApiState).length > 0 && !refreshIntervalRef.current) {
      console.log('Starting recurring poll for build api state...');
      refreshIntervalRef.current = setInterval(() => {
        fetchBuildApiState();
      }, 5000)
    } else if (!user?.isStaff && refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
      refreshIntervalRef.current = null;
    }
  }, [fetchBuildApiState, buildApiState, user, loading]);

  useEffect(() => {
    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    }
  }, [])

  return (
    <BuildApiContext.Provider value={{buildApiState, fetchBuildApiState, apiGet, apiPost}}>
      {children}
    </BuildApiContext.Provider>
  )
}

export const VersionListProvider = ({children}) => {
  const {user} = useContext(UserContext);
  const versionListsRef = useRef({});
  const [versionLists, setVersionLists] = useState({});

  const loadVersionList = useCallback(async ({environment, module}) => {
    const config = user?.isStaff ? {headers: {'x-authentication': localStorage.getItem('medal-user')}} : {};
    return (await axios.get(versionListUrl({user, environment, module}), config)).data;
  }, [user])

  const appendOrModify = ({environment, module, versionList}) => {
    const currentVersionLists = versionListsRef.current;
    const versionListKey = `${environment.value}.${module}`;
    const newList = {
      ...currentVersionLists
    }
    newList[versionListKey] = versionList;
    versionListsRef.current = newList;
    return newList;
  }

  const fetchVersionList = async ({environment, module}) => {
    try {
      const versionList = await loadVersionList({environment, module})
      setVersionLists(appendOrModify({environment, module, versionList}))
      return versionList;
    } catch (err) {
      console.error(`Error loading versionlist for ${environment} ${module}:`, err);
    }
    return null;
  }

  return (
    <VersionListContext.Provider value={{versionLists, fetchVersionList}}>
      {children}
    </VersionListContext.Provider>
  )
}

export const ToastProvider = ({children}) => {
  const [toasts, setToasts] = useState([])
  const pushToast = (props) => {
    const newToasts = toasts.slice(0);
    newToasts.push({...props, id: uuidv4()})
    setToasts(newToasts)
  }
  const removeToast = (id) => {
    setToasts(toasts.filter(toast => toast.id !== id))
  }
  return (
    <ToastContext.Provider value={{toasts, setToasts, pushToast, removeToast}}>
      {children}
    </ToastContext.Provider>
  )
}

export const ModalsProvider = ({children}) => {
  const [modals, setModals] = useState([])

  const openModal = (id, context) => {
    if (isModalOpen(id)) {
      return
    }
    setModals([
      ...modals,
      {id, context}
    ])
  }

  const closeModal = (id) => {
    setModals(modals.filter(modal => modal.id !== id))
  }

  const isModalOpen = id => {
    return modals.some(modal => modal.id === id)
  }

  return (
    <ModalContext.Provider value={{modals, openModal, closeModal, isModalOpen}}>
      {children}
    </ModalContext.Provider>
  )
}

export const ReleasesProvider = ({children}) => {
  const {user} = useContext(UserContext)
  const {pushToast} = useContext(ToastContext)
  const [releaseSchedule, setReleaseSchedule] = useState({})

  const fetchReleaseSchedule = async () => {
    try {
      const resp = await getBuildApi('/releases/schedules')
      setReleaseSchedule(resp.data)
    } catch (err) {
      pushToast({
        title: 'Error loading release schedule',
        body: err.response.data || err.message,
        type: 'error'
      })
    }
  }

  useEffect(() => {
    if (user?.userId) {
      fetchReleaseSchedule()
    }
  }, [user])

  return (
    <ReleasesContext.Provider value = {{releaseSchedule, fetchReleaseSchedule}}>
      {children}
    </ReleasesContext.Provider>
  )
}

export const ChangelogsProvider = ({children}) => {
  const {loading, user} = useContext(UserContext)
  const [changelogs, setChangelogs] = useState([])
  const unsubRef = useRef()

  const loadChangelogs = useCallback(async () => {
    const changelogs = await getChangelogs({
      includeDrafts: true
    })
    const logs = []
    changelogs.forEach(doc => {
      logs.push({
        id: doc.id,
        ...doc.data()
      })
    })
    setChangelogs(logs)
    return logs
  }, [])

  const subChangelogs = useCallback(async () => {
    if (unsubRef.current) {
      console.warn('Unsubbing from existing changelogs listener')
      unsubRef.current()
    }
    unsubRef.current = getAndListenChangelogs({
      includeDrafts: true
    }, (snapshot) => {
      const logs = []
      snapshot.forEach(doc => {
        logs.push({
          id: doc.id,
          ...doc.data()
        })
      })
      setChangelogs(logs)
    })
    return unsubRef.current
  }, [])

  const unsubChangelogs = () => {
    if (typeof unsubRef.current === 'function') {
      unsubRef.current()
      unsubRef.current = undefined
    }
  }

  const createChangelog = async ({title, description, environments, sections} = {}) => {
    const now = Date.now()
    return addChangelog({
      title: title ?? 'New Changelog',
      description: description ?? '',
      environments: environments ?? [Environments.SANDBOX],
      sections: sections ?? [],
      activeUsers: [],
      published: false,
      createdAt: now,
      lastUpdatedAt: now,
      lastUpdatedBy: {
        userName: user.userName,
        userId: user.userId
      }
    })
  }

  const updateChangelog = async (id, props, silentUpdate) => {
    return updateFirestoreChangelog(id, {
      ...props,
      ...(silentUpdate ? {} : {
        lastUpdatedAt: Date.now(),
        lastUpdatedBy: {
          userName: user.userName,
          userId: user.userId
        }
      })
    })
  }

  const deleteChangelog = async id => {
    if (!changelogs.some(log => log.id === id)) {
      throw new Error(`No changelog by id: ${id}`)
    }
    return deleteFirestoreChangelog(id)
  }

  const getChangelog = id => {
    return changelogs.find(log => log.id === id)
  }

  return (
    <ChangelogsContext.Provider value={{changelogs, loadChangelogs, subChangelogs, unsubChangelogs, getChangelog, createChangelog, updateChangelog, deleteChangelog}}>
      {children}
    </ChangelogsContext.Provider>
  )
}

export const FeatureFlagsProvider = ({children}) => {
  const [loaded, setLoaded] = useState(false)
  const [flags, setFlags] = useState({})
  const [ldFlags, setLdFlags] = useState([])
  const [ldProjectKey, setLdProjectKeyState] = useState(localStorage.getItem('ldProjectKey') ?? FlagSourceOptions[0].value)
  const ldProjectKeyRef = useRef(ldProjectKey)

  const setLdProjectKey = key => {
    localStorage.setItem('ldProjectKey', key)
    setLdProjectKeyState(key)
  }

  const fetchFlags = async () => {
    try {
      const resp = await getBuildApi('/flags')
      setFlags(resp.data)
      setLoaded(true)
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const updateFlags = async (allFlags) => {
    try {
      const resp = await postBuildApi('/flags', allFlags)
      setFlags(resp.data)
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const addFlag = async (key, value, {description} = {}) => {
    try {
      const resp = await postBuildApi(`/flags/${key}`, {
        value,
        description
      })
      const flag = resp.data
      console.log('added flag:', flag)
      setFlags({
        ...flags,
        [key]: flag
      })
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const updateFlag = async (key, value, {description} = {}) => {
    try {
      const resp = await patchBuildApi(`/flags/${key}`, {
        value,
        description
      })
      const flag = resp.data
      setFlags({
        ...flags,
        [key]: flag
      })
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const deleteFlag = async (key) => {
    try {
      const resp = await deleteBuildApi(`/flags/${key}`)
      setFlags(resp.data)
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const fetchLdFlags = async () => {
    try {
      const resp = await getBuildApi(`/ld/flags?projectKey=${ldProjectKey}`)
      const respFlags = resp.data.items
      const filteredLdFlags = respFlags.filter(flag => flag.kind === 'boolean')
      console.log('ldFlags:', ldProjectKey, filteredLdFlags)
      setLdFlags(filteredLdFlags)
    } catch (err) {
      console.error(err)
    }
  }

  const updateLdFlag = async (key, payload) => {
    try {
      const resp = await patchBuildApi(`/ld/flags/${key}?projectKey=${ldProjectKey}`, payload)
      const flag = resp.data
      const flagIndex = ldFlags.findIndex(el => el.key === key)
      const newLdFlags = ldFlags.slice()
      newLdFlags[flagIndex] = flag
      setLdFlags(newLdFlags)
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const createLdFlag = async ({key, name, description}) => {
    try {
      const resp = await postBuildApi(`/ld/flags?projectKey=${ldProjectKey}`, {
        key,
        name,
        description
      })
      const flag = resp.data
      const newLdFlags = ldFlags.slice()
      newLdFlags.unshift(flag)
      setLdFlags(newLdFlags)
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  const deleteLdFlag = async (key) => {
    try {
      const resp = await deleteBuildApi(`/ld/flags/${key}?projectKey=${ldProjectKey}`)
      if (resp.status === 204) {
        const newLdFlags = ldFlags
          .slice()
          .filter(flag => flag.key !== key)
        setLdFlags(newLdFlags)
      } else {
        throw new Error('Unexpected status deleting LD flag: ' + resp.status)
      }
    } catch (err) {
      // @todo push toast
      console.error(err)
    }
  }

  useEffect(() => {
    const reload = async () => {
      setLoaded(false)
      await fetchLdFlags()
      setLoaded(true)
    }
    if (ldProjectKeyRef.current !== ldProjectKey) {
      ldProjectKeyRef.current = ldProjectKey
      reload()
    }
  }, [ldProjectKey])

  return (
    <FeatureFlagsContext.Provider value={{loaded, flags, fetchFlags, updateFlags, addFlag, updateFlag, deleteFlag, ldFlags, fetchLdFlags, createLdFlag, updateLdFlag, deleteLdFlag, ldProjectKey, setLdProjectKey}}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}


export const TimeAgoProvider = ({ children }) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 60000) // Update every minute
    return () => clearInterval(interval)
  }, [])

  return (
    <TimeAgoContext.Provider value={currentDate}>
      {children}
    </TimeAgoContext.Provider>
  );
};
