import {useContext} from 'react'
import styled from 'styled-components'
import {ToastContext} from '../../contexts'
import Toast from '../Toast'

const ToastContainer = () => {
  const {toasts} = useContext(ToastContext)
  return (
    <Container>
      {
        toasts.map(toast => {
          return (
            <Toast
              key={`toast-${toast.id}`}
              {...toast}
            />
          )
        })
      }
    </Container>
  )
}

export default ToastContainer;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 2;
  pointer-events: none;
  padding: 32px;
  box-sizing: border-box;
  gap: 16px;
`
