import {useEffect, useState} from 'react'

const useMediaQuery = (mediaQuery) => {
  const [query, setQuery] = useState(window.matchMedia(`(${mediaQuery})`))

  useEffect(() => {
    function mediaQueryResizeHandler(query) {
      setQuery(query)
    }

    let media = query.current ?? window.matchMedia(`(${mediaQuery})`)
    media.addEventListener('change', mediaQueryResizeHandler)
    return () => {
      media.removeEventListener('change', mediaQueryResizeHandler)
    }
  }, [])

  return query?.matches
}

export default useMediaQuery
