import {Fragment, useContext} from 'react'
import VersionHistory from '../Modals/VersionHistoryModal'
import {deriveActiveBuilds} from '../../utils/builds-api'
import EnvironmentModal from '../Modals/EnvironmentModal'
import VersionModal from '../Modals/VersionModal'
import {
  BuildApiContext, EnvironmentContext,
  ModalContext, SelectedActiveBuildContext,
  SelectedEnvironmentContext,
  SelectedVersionContext,
  SelectedVersionHistoryContext, UserContext, WhatsNewContext,
} from '../../contexts'
import ActiveBuildModal from '../Modals/ActiveBuildModal'
import GitHubNotesModal from '../Modals/GitHubNotesModal'
import LogoutModal from '../Modals/LogoutModal'
import PromotionModal from '../Modals/PromotionModal'
import RetryBuildModal from '../Modals/RetryBuildModal'
import ApprovalModal from '../Modals/ApprovalModal'
import UserSettingsModal from '../Modals/UserSettingsModal'
import ScheduleModal from '../Modals/ScheduleModal'
import ChangelogsModal from '../Modals/ChangelogsModal'
import DeleteChangelogModal from '../Modals/DeleteChangelogModal'
import ChangelogEnvironmentsModal from '../Modals/ChangelogEnvironmentsModal'
import PublishChangelogModal from '../Modals/PublishChangelogModal'
import ChangelogVideoModal from '../Modals/ChangelogsModal/changelogVideoModal'
import FeatureFlagsModal from '../Modals/FeatureFlagsModal'
import DeleteFeatureFlagModal from '../Modals/DeleteFeatureFlagModal'

const ModalContainer = () => {
  const {selectedEnvironment} = useContext(SelectedEnvironmentContext)
  const {selectedVersion} = useContext(SelectedVersionContext)
  const {selectedVersionHistory} = useContext(SelectedVersionHistoryContext)
  const {selectedActiveBuild} = useContext(SelectedActiveBuildContext)
  const {whatsNew} = useContext(WhatsNewContext)
  const {user} = useContext(UserContext)
  const {buildApiState} = useContext(BuildApiContext)
  const {environments} = useContext(EnvironmentContext)
  const {modals} = useContext(ModalContext)

  // NOTE: these are all legacy modals before the ModalContext system was added
  return (
    <Fragment>
      {
        selectedVersionHistory &&
        <VersionHistory
          user={user}
          environment={environments.find(env => env.value === selectedVersionHistory.value)}
          activeBuilds={deriveActiveBuilds(buildApiState, {environment: environments.find(env => env.value === selectedVersionHistory.value)})}
        />
      }
      {
        selectedEnvironment &&
        <EnvironmentModal
          user={user}
          environment={environments.find(env => env.value === selectedEnvironment.value)}
        />
      }
      {
        selectedVersion &&
        <VersionModal
          user={user}
          version={selectedVersion.version}
          environment={selectedVersion.environment}
          module={selectedVersion.module}
        />
      }
      {
        whatsNew?.title &&
        <GitHubNotesModal/>
      }
      {
        selectedActiveBuild &&
        <ActiveBuildModal user={user}/>
      }
      {
        modals.map(modal => (
          <Fragment key={modal.id}>
            {renderModal(modal)}
          </Fragment>
        ))
      }
    </Fragment>
  )
}

const renderModal = ({id, context}) => {
  switch (id) {
    case 'promotion-modal':
      return (
        <PromotionModal {...context}/>
      )

    case 'retry-build':
      return (
        <RetryBuildModal {...context}/>
      )

    case 'approval-modal':
      return (
        <ApprovalModal {...context}/>
      )

    case 'schedule-modal':
      return (
        <ScheduleModal {...context}/>
      )

    case 'user-settings':
      return (
        <UserSettingsModal/>
      )

    case 'changelogs-modal':
      return (
        <ChangelogsModal {...context}/>
      )

    case 'publish-changelog':
      return (
        <PublishChangelogModal {...context}/>
      )

    case 'delete-changelog':
      return (
        <DeleteChangelogModal {...context}/>
      )

    case 'changelog-environments-modal':
      return (
        <ChangelogEnvironmentsModal {...context}/>
      )

    case 'add-changelog-video':
      return (
        <ChangelogVideoModal {...context}/>
      )

    case 'feature-flags-modal':
      return (
        <FeatureFlagsModal {...context}/>
      )

    case 'delete-feature-flag':
      return (
        <DeleteFeatureFlagModal {...context}/>
      )

    case 'logout':
      return (
        <LogoutModal/>
      )

    default:
      console.warn('Unsupported modal id:', id)
      break
  }
}

export default ModalContainer;
