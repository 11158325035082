import styled from 'styled-components'
import {Fragment} from 'react'
import PropTypes from 'prop-types'
import {colors, Dropdown} from '@get-wrecked/simple-components'
import InfoIcon from '../Icons/InfoIcon'
import WarningIcon from '../Icons/WarningIcon'
import {WarningText} from '../styles'

export const UpdateTypes = {
  DEFAULT: 'Default',
  SILENT: 'Silent',
  FORCED: 'Forced',
  IMMEDIATE_FORCED: 'Immediate Forced'
}

export const deriveUpdateType = version => {
  if (version.forceUpdate === true) {
    return UpdateTypes.FORCED
  } else if (version.forceUpdate === 'immediate') {
    return UpdateTypes.IMMEDIATE_FORCED
  } else if (version.promptUpdate === false) {
    return UpdateTypes.SILENT
  }
  return UpdateTypes.DEFAULT
}

const propTypes = {
  updateType: PropTypes.string.isRequired,
  onSelectUpdateType: PropTypes.func.isRequired
}

const UpdateTypeSelection = ({updateType, onSelectUpdateType}) => {
  const onSelect = option => {
    const updateConfig = {}
    switch (option) {
      case UpdateTypes.DEFAULT:
        updateConfig.autoUpdate = true
        updateConfig.promptUpdate = true
        updateConfig.forceUpdate = false
        break
      case UpdateTypes.SILENT:
        updateConfig.autoUpdate = false
        updateConfig.promptUpdate = false
        updateConfig.forceUpdate = false
        break
      case UpdateTypes.FORCED:
        updateConfig.autoUpdate = true
        updateConfig.promptUpdate = true
        updateConfig.forceUpdate = true
        break
      case UpdateTypes.IMMEDIATE_FORCED:
        updateConfig.autoUpdate = true
        updateConfig.promptUpdate = true
        updateConfig.forceUpdate = 'immediate'
        break
    }
    onSelectUpdateType(option, updateConfig)
  }

  return (
    <Container>
      <UpdateTypeSection>
        <UpdateTypeDescription>
          <UpdateTypeLabel>Update Type</UpdateTypeLabel>
          <span>How do you want this update to rollout to users?</span>
        </UpdateTypeDescription>
        <Dropdown
          value={updateType}
          size="large"
          options={Object.values(UpdateTypes)}
          onSelect={onSelect}
          style={{
            minWidth: '100px'
          }}
        />
      </UpdateTypeSection>

      <Hint>
        {
          updateType === UpdateTypes.DEFAULT &&
          <Fragment>
            <InfoIcon size="23" color={colors.text['30']}/>
            Show update CTAs, respect user's update settings
          </Fragment>
        }
        {
          updateType === UpdateTypes.SILENT &&
          <Fragment>
            <InfoIcon size="23" color={colors.text['30']}/>
            Hide update CTAs, always background update
          </Fragment>
        }
        {
          updateType === UpdateTypes.FORCED &&
          <Fragment>
            <WarningIcon size="23" color={colors.error['50']}/>
            <span>
              Always download and install on app restart, skip background updates.
              <br/><strong>Only use if you know what you're doing!</strong>
            </span>
          </Fragment>
        }
        {
          updateType === UpdateTypes.IMMEDIATE_FORCED &&
          <Fragment>
            <WarningIcon size="23" color={colors.error['50']}/>
            <span>
              Immediately restart the app when games are closed, bypass background updates and install updates during restart. <WarningText>Only use this for emergencies!</WarningText>
            </span>
          </Fragment>
        }
      </Hint>
    </Container>
  )
}

UpdateTypeSelection.propTypes = propTypes

export default UpdateTypeSelection

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const UpdateTypeSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${colors.text['30']};
`

const UpdateTypeLabel = styled.span`
  font-weight: 500;
  color: white;
`

const UpdateTypeDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  color: ${colors.text['30']};
`

const Hint = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
  color: ${colors.text['30']};
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${colors.stroke['0A8']};
  font-size: 14px;
  background-color: ${colors.background['third-layer']};
  line-height: 1.5;
  
  svg {
    flex-shrink: 0;
  }
`
