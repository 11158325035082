import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button, Checkbox, colors, InputField, LoadingSpinner} from '@get-wrecked/simple-components'
import {Fragment, useEffect, useState} from 'react'
import {Divider} from '../styles'

const propTypes = {
  visible: PropTypes.bool,
  saving: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  buttonLabel: PropTypes.string,
  buttonType: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'ghost',
    'ghost-neutral',
    'danger',
    'success'
  ]),
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  confirmMethod: PropTypes.oneOf(['checkbox', 'input']),
  confirmString: PropTypes.string,
  confirmLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

const DEFAULT_CONFIRM_STRING = 'confirm'

const SavePrompt = ({visible, saving, description, buttonLabel, buttonType, onButtonClick, disabled, requireConfirmation, confirmMethod, confirmLabel, confirmString}) => {
  const [isConfirmed, setConfirmed] = useState(false)

  const saveButton = (saving
    ? <LoadingSpinner size="20px" style={{margin: '0 22px'}}/>
    : <Button variant={buttonType ?? 'success'} size="large" onClick={onButtonClick} disabled={disabled || (requireConfirmation && !isConfirmed) || !visible}>
      {buttonLabel ?? 'Save'}
    </Button>)

  // when the confirmation requirements change, reset the confirmed state
  useEffect(() => {
    if (isConfirmed) {
      setConfirmed(false)
    }
  }, [requireConfirmation, confirmMethod])

  // separately, when visibility of the prompt changes, reset the confirmed state
  useEffect(() => {
    if (isConfirmed) {
      setConfirmed(false)
    }
  }, [visible])

  return (
    <Floating>
      <Container $visible={visible}>
        <Row>
          <Description>
            {description}
          </Description>
          {
            !requireConfirmation &&
            saveButton
          }
        </Row>
        {
          requireConfirmation && confirmMethod &&
          <Fragment>
            <Divider $color={colors.stroke['0A16']}/>
            <Row>
              {
                confirmMethod === 'checkbox' &&
                <ConfirmationCheckbox onClick={() => setConfirmed(!isConfirmed)}>
                  <Checkbox
                    checked={isConfirmed}
                    onChange={e => setConfirmed(e.target.checked)}
                  />
                  <span>{confirmLabel ?? `I confirm I know what I'm doing`}</span>
                </ConfirmationCheckbox>
              }
              {
                confirmMethod === 'input' &&
                <ConfirmationInput>
                  {
                    confirmLabel ||
                    (<span>To confirm you know what you're doing, type the word <code>{confirmString ?? DEFAULT_CONFIRM_STRING}</code> below:</span>)
                  }
                  <InputField
                    size="medium"
                    placeholder={`Confirm by typing the word '${confirmString ?? DEFAULT_CONFIRM_STRING}'`}
                    onChange={e => {
                      setConfirmed(e.target.value === (confirmString ?? DEFAULT_CONFIRM_STRING))
                    }}
                  />
                </ConfirmationInput>
              }
              {saveButton}
            </Row>
          </Fragment>
        }
      </Container>
    </Floating>
  )
}

SavePrompt.propTypes = propTypes

export default SavePrompt

const Floating = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
`

const Container = styled.div`
  background-color: ${colors.background['first-layer']};
  border-radius: 8px;
  border: 1px solid ${colors.stroke['0A16']};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
  padding: 20px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.64);
  min-height: 70px;
  max-width: calc(100% - 32px);
  
  transition: opacity 100ms ease, transform 100ms ease;
  transform: translateY(${({$visible}) => $visible ? '0' : 'calc(100% + 32px)'});
  opacity: ${({$visible}) => $visible ? '1' : '0'};
  pointer-events: ${({$visible}) => $visible ? 'initial' : 'none'};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

const Description = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-inline-end: auto;
  color: ${colors.text['30']};
`

const ConfirmationCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text['30']};
  width: fit-content;
  cursor: pointer;
  
  &:hover {
    color: white;
  }
`

const ConfirmationInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 13px;
  font-weight: normal;
  color: ${colors.text['30']};
`
