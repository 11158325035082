import styled from 'styled-components'
import PropTypes from 'prop-types'
import {colors, InputField, Toggle} from '@get-wrecked/simple-components'
import {DefaultFlagTypeValues, FlagTypes} from './flagsConstants'
import ArrayInput from './ArrayInput'

const propTypes = {
  type: PropTypes.oneOf(Object.values(FlagTypes)).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

const ValueInput = ({type, value, disabled, onClick, onChange = () => {}}) => {
  const currentValue = value ?? DefaultFlagTypeValues[type]
  switch (type) {
    case FlagTypes.NUMBER:
      return (
        <InputField
          type="number"
          placeholder="Enter a numeric value"
          size="large"
          value={currentValue}
          onClick={onClick}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
        />
      )
    case FlagTypes.STRING:
      return (
        <InputField
          placeholder="Enter a text value"
          size="large"
          value={currentValue}
          onClick={onClick}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
        />
      )
    case FlagTypes.BOOLEAN:
      return (
        <Toggle
          size="small"
          active={currentValue}
          onClick={(e) => {
            if (typeof onClick === 'function') {
              onClick(e)
            } else {
              onChange(!currentValue)
            }
          }}
          disabled={disabled}
        />
      )
    case FlagTypes.ARRAY:
      return (
        <ArrayInput
          value={currentValue}
          valueType={currentValue?.[0] ? typeof currentValue[0] : FlagTypes.STRING}
          onChange={newValue => onChange(newValue)}
          disabled={disabled}
        />
      )

    default:
      return <Container>{currentValue}</Container>
  }
}

ValueInput.propTypes = propTypes

export default ValueInput

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${colors.neutral['0A4']};
  border: 1px solid ${colors.neutral['0A4']};
  padding: 5px 5px 5px 10px;
`
