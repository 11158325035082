import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "build/:buildId",
        element: <App/>
      },
      {
        path: "environment/:environment",
        element: <App/>
      },
      {
        path: "version/:environment/:module/:version",
        element: <App/>
      },
      {
        path: "history/:environment",
        element: <App/>
      },
      {
        path: "settings",
        element: <App/>
      },
      {
        path: "schedule",
        element: <App/>
      },
      {
        path: "schedule/:environment",
        element: <App/>
      },
      {
        path: "changelogs",
        element: <App/>
      },
      {
        path: "changelogs/:id",
        element: <App/>
      },
      {
        path: "flags",
        element: <App/>
      },
      {
        path: "flags/:flag",
        element: <App/>
      },
      {
        path: "flags/:id",
        element: <App/>
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // disabled because it re-requests version lists concurrently on initial app mount
  // <React.StrictMode>
    <RouterProvider router={router}/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
