import {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, InputField} from '@get-wrecked/simple-components'
import Modal from '../../../components/Modal'
import {ToastContext, UserContext} from '../../../contexts'
import Avatar from '../../../components/Avatar'
import SavePrompt from '../../../components/SavePrompt'
import {get, post} from '../../../utils/builds-api'

const UserSettingsModal = () => {
  const {user, setUser} = useContext(UserContext)
  const {pushToast} = useContext(ToastContext)
  const [loading, setLoading] = useState(true)
  const [slackMemberId, setSlackMemberId] = useState(user.slackMemberId)
  const [githubUsername, setGitHubUsername] = useState(user.githubUsername)
  const hasChanges = !!slackMemberId?.length !== !!user.slackMemberId ||
    !!githubUsername?.length !== !!user.githubUsername ||
    (user.slackMemberId && slackMemberId !== user.slackMemberId) ||
    (user.githubUsername && githubUsername !== user.githubUsername)

  const onClickSave = async () => {
    try {
      const resp = await post(`/profile/${user.userId}`, {
        slackMemberId,
        githubUsername
      })
      if (resp?.data && !resp.data.errorMessage) {
        setUser({
          ...user,
          slackMemberId,
          githubUsername
        })
      } else {
        throw new Error(resp.data.errorMessage)
      }
    } catch (err) {
      pushToast({
        title: 'Error saving user build profile',
        body: err.response?.data || err.message,
        type: 'error'
      })
    }
  }

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const resp = await get(`/profile/${user.userId}`)
        if (resp?.data && !resp.data.errorMessage) {
          const profile = resp.data
          setUser({
            ...user,
            ...profile
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    loadProfile()
  }, [])

  return (
    <Modal
      id="user-settings"
      header="Your Settings"
      size="small"
      gap="20px"
      navigateOnClose={true}
    >
      <Body>
        <MedalUser>
          <Cover src={user.coverPhoto}/>
          <Metadata>
            <Avatar
              image={user.thumbnail}
              size="88px"
              backgroundColor={colors.background['second-layer']}
            />
            <TextColumn>
              {user.userName}
              <UserId>{user.userId}</UserId>
            </TextColumn>
          </Metadata>
        </MedalUser>
        <Settings>
          <Input>
            <span>Slack member ID (<a href="https://i.imgur.com/8pxFA9W.png" target="_blank">example</a>):</span>
            <InputField
              size="large"
              placeholder={loading ? '' : "Paste your Slack member ID here"}
              value={slackMemberId}
              onChange={e => setSlackMemberId(e.target.value.trim())}
              disabled={loading}
            />
          </Input>
          <Input>
            GitHub username:
            <InputField
              size="large"
              placeholder={loading ? '' : "Paste your GitHub username here"}
              value={githubUsername}
              onChange={e => setGitHubUsername(e.target.value)}
              disabled={loading}
            />
          </Input>
        </Settings>
      </Body>
      <SavePrompt
        visible={!!hasChanges}
        description={<span>Save changes to your build profile.<br/><i>This is separate from your actual Medal profile.</i></span>}
        onButtonClick={onClickSave}
      />
    </Modal>
  )
}

export default UserSettingsModal

const Body = styled.div`
  display: flex;
  gap: 20px;
`

const MedalUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  aspect-ratio: 1/1;
  border: 1px solid ${colors.stroke['0A8']};
  position: relative;
  background: radial-gradient(rgba(255, 184, 75, 0.16) 0%, ${colors.background['third-layer']} 75%);
  transition: box-shadow 300ms ease, background 300ms ease;
`

const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  opacity: 0;
  border-radius: 8px;
`

const Metadata = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
`

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //color: white;
  font-weight: 500;
  font-size: 16px;
`

const UserId = styled.span`
  font-size: 13px;
  font-family: "Courier New", monospace;
  font-weight: normal;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.4);
`

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 16px;
`

const Input = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 500;
  font-size: 12px;
`
