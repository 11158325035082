import {Fragment, useContext} from 'react'
import PropTypes from 'prop-types'
import {colors, Tooltip} from '@get-wrecked/simple-components'
import {Approvals} from '@get-wrecked/updates/constants'
import useCountdown from '../../hooks/useCountdown'
import {ModalContext, ReleasesContext} from '../../contexts'
import useApprovals from '../../hooks/useApprovals'
import CountdownBadge from './countdown'

const {MINIMUM_APPROVALS} = Approvals

const propTypes = {
  environment: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired,
  versions: PropTypes.object.isRequired
}

const ReleaseStatus = ({environment, child, versions}) => {
  const {openModal} = useContext(ModalContext)
  const {releaseSchedule} = useContext(ReleasesContext)

  // approval status
  const {
    approvals,
    isLoading,
  } = useApprovals({
    environment: child,
    versions,
    pollInterval: 5 * 1000
  })

  const hasMinimumRequiredApprovals = approvals.length >= MINIMUM_APPROVALS
  const remainingApprovals = Math.max(0, MINIMUM_APPROVALS - approvals.length)

  // countdown stuff
  const {countdownLabel, timeLeft} = useCountdown({
    targetDay: releaseSchedule[child?.value]?.day,
    targetTime: releaseSchedule[child?.value]?.hour
  })

  const onClickBadge = () => {
    openModal('approval-modal', {environment: child})
  }

  return !isLoading && (
    <Tooltip
      tooltip={
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span>{environment.label} release is {releaseSchedule[child?.value]?.day} at {releaseSchedule[child?.value]?.hour} ET</span>
          {
            hasMinimumRequiredApprovals
              ? <span style={{color: colors.success['30']}}>QA passed & Release Manager approved</span>
              : <span style={{color: colors.brand.primary['30']}}>Pending QA review and Release Manager approval</span>
          }
          {
            !hasMinimumRequiredApprovals &&
            <span>({remainingApprovals} more approvals required)</span>
          }
        </div>
      }
      position="bottom"
    >
      <CountdownBadge
        type={hasMinimumRequiredApprovals ? 'success' : 'brand'}
        label={
          <Fragment>
            <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.00001 1.33331C4.32668 1.33331 1.33334 4.32665 1.33334 7.99998C1.33334 11.6733 4.32668 14.6666 8.00001 14.6666C11.6733 14.6666 14.6667 11.6733 14.6667 7.99998C14.6667 4.32665 11.6733 1.33331 8.00001 1.33331ZM10.9 10.38C10.8067 10.54 10.64 10.6266 10.4667 10.6266C10.38 10.6266 10.2933 10.6066 10.2133 10.5533L8.14668 9.31998C7.63334 9.01331 7.25334 8.33998 7.25334 7.74665V5.01331C7.25334 4.73998 7.48001 4.51331 7.75334 4.51331C8.02668 4.51331 8.25334 4.73998 8.25334 5.01331V7.74665C8.25334 7.98665 8.45334 8.33998 8.66001 8.45998L10.7267 9.69331C10.9667 9.83331 11.0467 10.14 10.9 10.38Z"
                fill={hasMinimumRequiredApprovals ? colors.success['10'] : colors.brand.primary['10']}
              />
            </svg>
            {countdownLabel}
          </Fragment>
        }
        onClick={onClickBadge}
      >
        {
          hasMinimumRequiredApprovals
            ? <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.7933 1.33331H5.20668C2.78001 1.33331 1.33334 2.77998 1.33334 5.20665V10.7866C1.33334 13.22 2.78001 14.6666 5.20668 14.6666H10.7867C13.2133 14.6666 14.66 13.22 14.66 10.7933V5.20665C14.6667 2.77998 13.22 1.33331 10.7933 1.33331ZM11.1867 6.46665L7.40668 10.2466C7.31334 10.34 7.18668 10.3933 7.05334 10.3933C6.92001 10.3933 6.79334 10.34 6.70001 10.2466L4.81334 8.35998C4.62001 8.16665 4.62001 7.84665 4.81334 7.65331C5.00668 7.45998 5.32668 7.45998 5.52001 7.65331L7.05334 9.18665L10.48 5.75998C10.6733 5.56665 10.9933 5.56665 11.1867 5.75998C11.38 5.95331 11.38 6.26665 11.1867 6.46665Z"
                fill={colors.success['50']}
              />
            </svg>
            : <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7933 1.33331H5.20668C2.78001 1.33331 1.33334 2.77998 1.33334 5.20665V10.7933C1.33334 13.22 2.78001 14.6666 5.20668 14.6666H10.7933C13.22 14.6666 14.6667 13.22 14.6667 10.7933V5.20665C14.6667 2.77998 13.22 1.33331 10.7933 1.33331ZM6.64668 9.93331L5.14668 11.4333C5.04668 11.5333 4.92001 11.58 4.79334 11.58C4.66668 11.58 4.53334 11.5333 4.44001 11.4333L3.94001 10.9333C3.74001 10.74 3.74001 10.42 3.94001 10.2266C4.13334 10.0333 4.44668 10.0333 4.64668 10.2266L4.79334 10.3733L5.94001 9.22665C6.13334 9.03331 6.44668 9.03331 6.64668 9.22665C6.84001 9.41998 6.84001 9.73998 6.64668 9.93331ZM6.64668 5.26665L5.14668 6.76665C5.04668 6.86665 4.92001 6.91331 4.79334 6.91331C4.66668 6.91331 4.53334 6.86665 4.44001 6.76665L3.94001 6.26665C3.74001 6.07331 3.74001 5.75331 3.94001 5.55998C4.13334 5.36665 4.44668 5.36665 4.64668 5.55998L4.79334 5.70665L5.94001 4.55998C6.13334 4.36665 6.44668 4.36665 6.64668 4.55998C6.84001 4.75331 6.84001 5.07331 6.64668 5.26665ZM11.7067 11.08H8.20668C7.93334 11.08 7.70668 10.8533 7.70668 10.58C7.70668 10.3066 7.93334 10.08 8.20668 10.08H11.7067C11.9867 10.08 12.2067 10.3066 12.2067 10.58C12.2067 10.8533 11.9867 11.08 11.7067 11.08ZM11.7067 6.41331H8.20668C7.93334 6.41331 7.70668 6.18665 7.70668 5.91331C7.70668 5.63998 7.93334 5.41331 8.20668 5.41331H11.7067C11.9867 5.41331 12.2067 5.63998 12.2067 5.91331C12.2067 6.18665 11.9867 6.41331 11.7067 6.41331Z" fill="#FFC366"/>
            </svg>
        }
        {
          hasMinimumRequiredApprovals
            ? 'Release Approved'
            : `Pending Review`
        }
        <svg id="pointer" width="8" height="8" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginInlineStart: '4px'}}>
          <path
            d="M7.19969 5.49188L5.22969 3.52188L2.01969 0.31188C1.33969 -0.35812 0.179688 0.12188 0.179688 1.08188V7.31188V12.9219C0.179688 13.8819 1.33969 14.3619 2.01969 13.6819L7.19969 8.50188C8.02969 7.68188 8.02969 6.32188 7.19969 5.49188Z"
            fill={hasMinimumRequiredApprovals ? colors.success['50'] : colors.brand.primary['10']}
          />
        </svg>
      </CountdownBadge>
    </Tooltip>
  )
}

ReleaseStatus.propTypes = propTypes

export default ReleaseStatus
