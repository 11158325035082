import ConfirmationDialog from '../../ConfirmationDialog'
import {useContext} from 'react'
import {ChangelogsContext, ToastContext} from '../../../contexts'

const PublishChangelogModal = ({id, title, published}) => {
  const {updateChangelog} = useContext(ChangelogsContext)
  const {pushToast} = useContext(ToastContext)

  const onConfirm = async () => {
    try {
      await updateChangelog(id, {
        published: !published
      })
    } catch (err) {
      if (err.code === 'permission-denied') {
        pushToast({
          title: 'Missing changelog permissions',
          body: 'Ask @Josh on Slack for publish access',
          type: 'error'
        })
      } else {
        pushToast({
          title: 'Unexpected changelog publishing error',
          body: err.message,
          type: 'error'
        })
        console.error(err)
      }
    }
  }

  return (
    <ConfirmationDialog
      modalId="publish-changelog"
      title="Are you sure?"
      description={<span>Please confirm that you wish to {published ? 'unpublish' : 'publish'} the changelog: <strong>{title}</strong></span>}
      confirmLabel={published ? 'Unpublish' : 'Publish'}
      confirmType={published ? 'danger' : 'primary'}
      cancelLabel="Nevermind"
      onConfirm={onConfirm}
    />
  )
}

export default PublishChangelogModal
