import {useContext, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {colors, InputField} from '@get-wrecked/simple-components'
import CheckIcon from '../Icons/CheckIcon'
import {searchUsers} from '../../utils/api'
import {ToastContext} from '../../contexts'

const UserSearchInput = ({placeholder, onClickUser, selectedUsers, disabled}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const searchTimeoutRef = useRef()
  const {pushToast} = useContext(ToastContext);

  const executeSearchQuery = async () => {
    if (!searchQuery?.length) {
      setSearchResults([])
      return
    }
    try {
      const results = await searchUsers(searchQuery)
      if (results?.data?.users) {
        setSearchResults(results.data.users)
      } else if (results?.data?.errorMessage) {
        pushToast({
          title: 'Error searching for users',
          body: JSON.stringify(results.data.errorMessage),
          type: 'error'
        })
      }
    } catch (err) {
      pushToast({
        title: 'Error searching for users',
        body: err.response.data || err.message,
        type: 'error'
      })
    }
  }

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current)
    }
    searchTimeoutRef.current = setTimeout(() => {
      executeSearchQuery()
    }, 500)
  }, [searchQuery])

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current)
      }
    }
  }, [])

  return (
    <Container>
      <InputField
        id="user-search-input"
        size="medium"
        placeholder={placeholder || 'Search users...'}
        onChange={e => {
          setSearchQuery(e.target.value)
        }}
        value={searchQuery}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.target.blur()
          }
        }}
        onBlur={() => {
          if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
          }
          executeSearchQuery()
        }}
        style={{
          pointerEvents: disabled ? 'none' : 'all',
          width: '100%'
        }}
      />
      {
        searchQuery?.length > 0 && searchResults.length > 0 &&
        <SearchResults className="scrollable y">
          {
            searchResults.map(u => {
              return (
                <SearchResult key={`${u.userId}-result`} onClick={e => {
                  e.stopPropagation()
                  setSearchQuery('')
                  setSearchResults([])
                  onClickUser(u)
                }}>
                  {
                    u.thumbnail &&
                    <AvatarContainer>
                      <img src={u?.thumbnail}/>
                    </AvatarContainer>
                  }
                  {u.userName}
                  {
                    Array.isArray(selectedUsers) && selectedUsers.some(user => user.userId === u.userId) &&
                    <CheckIcon size={16} color={'#01d28e'}/>
                  }
                </SearchResult>
              )
            })
          }
        </SearchResults>
      }
    </Container>
  )
}

export default UserSearchInput

const Container = styled.div`
  position: relative;
`

const AvatarContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const SearchResults = styled.div`
  position: absolute;
  top: calc(100% - 4px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 8px;
  max-height: 180px;
  pointer-events: all;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 1px 24px rgba(0, 0, 0, 0.64);
  background-color: ${colors.background['third-layer']};
  border: 1px solid rgba(255, 255, 255, 0.064);
`

const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  padding: 6px 8px;
  font-size: 13px;
  background-color: transparent;
  pointer-events: all;
  gap: 8px;
  box-sizing: border-box;
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.neutral['0A4']};
  }
`
