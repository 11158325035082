import React, {useContext, useEffect} from 'react'
import {useLocation, useParams} from "react-router-dom";
import {
  BuildApiContext,
  EnvironmentContext, ModalContext, SelectedActiveBuildContext,
  SelectedEnvironmentContext,
  SelectedVersionContext, SelectedVersionHistoryContext,
  UserContext, VersionListContext,
} from "./contexts";
import {deriveActiveBuilds} from "./utils/builds-api";
import {parseQueryString} from './utils/string'

const Routing = () => {
  const {user} = useContext(UserContext);
  const {buildApiState} = useContext(BuildApiContext);
  const {selectedEnvironment, setSelectedEnvironment} = useContext(SelectedEnvironmentContext);
  const {selectedVersion, setSelectedVersion} = useContext(SelectedVersionContext);
  const {selectedVersionHistory, setSelectedVersionHistory} = useContext(SelectedVersionHistoryContext);
  const {selectedActiveBuild, setSelectedActiveBuild} = useContext(SelectedActiveBuildContext);
  const {environments, searchQuery, setSearchQuery} = useContext(EnvironmentContext);
  const {versionLists} = useContext(VersionListContext);
  const {openModal, isModalOpen} = useContext(ModalContext)
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    // home page logic
    if (location.pathname === '/') {
      if (location.search.includes('q=')) {
        const queryParams = parseQueryString(location.search)
        if (searchQuery !== queryParams.q) {
          setSearchQuery(queryParams.q)
        }
        return
      } else if (searchQuery?.length > 0 && searchQuery !== '') {
        setSearchQuery('')
        return
      }
    }

    // settings page
    if (location.pathname === '/settings') {
      openModal('user-settings')
      return
    }

    // schedule page
    if (location.pathname.startsWith('/schedule') && !isModalOpen('schedule-modal')) {
      openModal('schedule-modal')
      return
    }

    // changelogs page
    if (location.pathname.startsWith('/changelogs') && !isModalOpen('changelogs-modal')) {
      openModal('changelogs-modal')
      return
    }

    // feature flags page
    if (location.pathname.startsWith('/flags') && !isModalOpen('feature-flags-modal')) {
      openModal('feature-flags-modal')
      return
    }
  }, [location])

  // check route on startup
  useEffect(() => {
    if (!user?.isStaff) {
      return
    }

    if (!location.pathname.startsWith('/build') && selectedActiveBuild) {
      setSelectedActiveBuild(undefined, false)
    } else if (!location.pathname.startsWith('/environment') && selectedEnvironment) {
      setSelectedEnvironment(undefined, false)
    } else if (!location.pathname.startsWith('/version') && selectedVersion) {
      setSelectedVersion(undefined, false)
    } else if (!location.pathname.startsWith('/history') && selectedVersionHistory) {
      setSelectedVersionHistory(undefined, false)
    }

    if (location.pathname.startsWith('/build')) {
      if (params?.buildId && params.buildId !== selectedActiveBuild?.buildId && buildApiState) {
        const activeBuild = deriveActiveBuilds(buildApiState).find(build => build.buildId === params.buildId)
        if (activeBuild) {
          setSelectedActiveBuild(deriveActiveBuilds(buildApiState).find(build => build.buildId === params.buildId))
        } else if (buildApiState.processedBuilds[params.buildId]?.json?.build) {
          setSelectedActiveBuild(buildApiState.processedBuilds[params.buildId].json?.build)
        }
      }
    } else if (location.pathname.startsWith('/environment')) {
      if (params?.environment && params.environment !== selectedEnvironment?.value) {
        const environment = environments.find(env => env.value === params.environment)
        if (environment) {
          setSelectedEnvironment(environment)
        }
      }
    } else if (location.pathname.startsWith('/version')) {
      if (params?.environment &&
        params?.module &&
        params?.version &&
        selectedVersion?.module !== params.module &&
        selectedVersion?.environment !== params?.environment &&
        selectedVersion?.version?.semver !== params.version
      ) {
        const environment = environments?.find?.(env => env?.value === params.environment)
        const version = versionLists?.[`${environment?.value}.${params.module}`]?.find?.(version => version.semver === params.version)
        if (environment && version) {
          setSelectedVersion({
            environment,
            module: params.module,
            version
          })
        }
      }
    } else if (location.pathname.startsWith('/history')) {
      if (params?.environment && params.environment !== selectedVersionHistory?.value) {
        const environment = environments.find(env => env.value === params.environment)
        if (environment) {
          setSelectedVersionHistory(environment)
        }
      }
    }
  }, [params, user, buildApiState, selectedActiveBuild, selectedEnvironment, selectedVersionHistory, selectedVersion, environments, versionLists])

  return null
}

export default Routing
