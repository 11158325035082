import {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components'
import {CloseButton, InputField} from '@get-wrecked/simple-components'
import Module from "../../../components/Module";
import CheckboxSetting from "../../../components/CheckboxSetting";
import {BuildApiContext, EnvironmentContext, SelectedEnvironmentContext} from "../../../contexts";
import Modal from '../../../components/Modal'
import {getUsers} from '../../../utils/api'
import UserSearchInput from '../../../components/UserSearchInput'
import SavePrompt from '../../../components/SavePrompt'
import ModulePlaceholder from '../../../components/Module/placeholder'
import {Divider} from '../../../components/styles'
import Rollout from '../../Rollout'
import {DEFAULT_TOTAL_BUCKETS, DIRECTION_UP} from '@get-wrecked/updates/utils/updates'

function arraysHaveSameStrings(arr1, arr2) {
  if (!arr1 && !arr2) {
    return true
  }
  if (!arr1 || !arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((element) => arr2.includes(element));
}

const EnvironmentModal = ({environment}) => {
  const {setSelectedEnvironment} = useContext(SelectedEnvironmentContext);
  const {fetchEnvironments} = useContext(EnvironmentContext);
  const {apiPost} = useContext(BuildApiContext);
  const [label, setLabel] = useState(environment.label);
  const [description, setDescription] = useState(environment.description || '');
  const [isStatic, setStatic] = useState(environment.static);
  const [isPublic, setPublic] = useState(environment.public);
  const [userIds, setUserIds] = useState(environment.userIds);
  const [users, setUsers] = useState([]);
  const [experimental, setExperimental] = useState(environment.experimental);
  const [stagingApi, setStagingApi] = useState(environment.stagingApi);
  const [saving, setSaving] = useState(false);

  const hasChanges = useMemo(() => {
    return label !== environment.label ||
      isPublic !== environment.public ||
      isStatic !== environment.static ||
      experimental !== environment.experimental ||
      stagingApi !== environment.stagingApi ||
      ((environment.description === undefined && description?.length > 0) || (environment.description !== undefined && environment.description !== description)) ||
      !arraysHaveSameStrings(environment.userIds, userIds)
  }, [environment, description, label, isPublic, isStatic, experimental, userIds, stagingApi])

  const close = () => {
    setSelectedEnvironment(undefined);
  }

  const save = async (e) => {
    // @todo post to build api
    setSaving(true);
    try {
      const body = {
        experimental,
        label,
        description,
        userIds: userIds === undefined ? null : userIds,
        'public': isPublic,
        'static': isStatic,
        stagingApi,
      };
      const resp = await apiPost(`/environments/${environment.value}`, body)
      if (resp.data?.value === environment.value) {
        await fetchEnvironments();
      } else {
        setLabel(environment.label);
        setStatic(environment.static);
        setPublic(environment.public);
        setExperimental(environment.experimental);
        setStatic(environment.stagingApi)
        setDescription(environment.description);
      }
    } catch (err) {
      console.error(err);
    }
    setSaving(false);
  }

  const checkUsers = async () => {
    const requestUsers = async (requestUserIds) => {
      const results = await getUsers(requestUserIds)
      if (results?.data?.length) {
        return results.data.map(u => {
          return {...u, userName: u.username}
        })
      }
      return []
    }

    if (userIds?.length > 0) {
      const missingUserIds = userIds.filter(userId => !users.some(u => u.userId === userId))
      if (missingUserIds.length > 0) {
        const chunkSize = 100
        if (missingUserIds.length > chunkSize) {
          const newUsers = []
          for (let i = 0; i < missingUserIds.length; i += chunkSize) {
            const resp = await requestUsers(missingUserIds.slice(i, i + chunkSize))
            newUsers.push(...resp)
          }
          setUsers([
            ...users,
            ...newUsers
          ])
        } else {
          const newUsers = await requestUsers(missingUserIds)
          setUsers([
            ...users,
            ...newUsers
          ])
        }
      }
    }
  }

  const addUser = addUser => {
    if (userIds === undefined) {
      setUserIds([
        addUser.userId
      ])
      setUsers([
        addUser
      ])
    } else if (!userIds.includes(addUser.userId)) {
      const newUsers = [
        ...users.filter(u => addUser.userId !== u.userId),
        addUser
      ]
      const newUserIds = [
        ...userIds,
        addUser.userId
      ]
      setUsers(newUsers)
      setUserIds(newUserIds)
    }
  }

  const removeUserId = (removeUserId) => {
    // remove the user
    const newUsers = [
      ...users.filter(u => removeUserId!== u.userId),
    ]
    const newUserIds = [
      ...userIds.filter(userId => userId !== removeUserId)
    ]
    if (newUserIds.length === 0) {
      setUserIds(undefined)
      setUsers([])
    } else {
      setUsers(newUsers)
      setUserIds(newUserIds)
    }
  }

  const allowedUsers = <Fragment>
    Turn this on to only allow users listed here. When turned on and no users are listed, only staff can access the environment.
    <div className={`animate-height-toggle ${userIds !== undefined ? 'open' : 'closed'}`}>
      <UserSearchInput
        id={'grant-user-search'}
        placeholder={'Search users to grant access...'}
        onClickUser={user => {
          if (Array.isArray(userIds) && userIds.includes(user.userId)) {
            removeUserId(user.userId)
          } else {
            addUser(user)
          }
        }}
        disabled={userIds === undefined}
      />
    </div>

    {
      userIds?.length > 0 && users?.length > 0 &&
      <UsersList>
        {
          users.map(user => {
            return (
              <User key={`${user.userId}-user`} onClick={e => e.stopPropagation()}>
                {
                  user.thumbnail &&
                  <AvatarContainer>
                    <AvatarImg src={user?.thumbnail}/>
                  </AvatarContainer>
                }
                {user.userName}
                <CloseButton
                  size="16"
                  position="-4px"
                  onClick={e => {
                    e.stopPropagation()
                    removeUserId(user.userId)
                  }}
                />
              </User>
            )
          })
        }
      </UsersList>
    }
  </Fragment>

  useEffect(() => {
    checkUsers()
  }, [userIds])

  return (
    <Modal
      id="environment-modal"
      header={label}
      size="medium"
      onClose={close}
    >
      <SavePrompt
        visible={hasChanges || saving}
        saving={saving}
        description={`You have pending changes. Saved changes will take effect within 1 minute.`}
        onButtonClick={save}
      />

      <InputField
        size="large"
        value={label}
        onChange={e => setLabel(e.target.value)}
      />

      <Divider/>

      {/*<Rollout*/}
      {/*  // enabled, direction, totalBuckets, percentage, seed, onUpdate*/}
      {/*  enabled={false}*/}
      {/*  direction={DIRECTION_UP}*/}
      {/*  totalBuckets={DEFAULT_TOTAL_BUCKETS}*/}
      {/*  percentage={10}*/}
      {/*  onUpdate={(rollout) => console.log('rollout:', rollout)}*/}
      {/*/>*/}
      {/*<Divider/>*/}

      <Toggles>
        <CheckboxSetting
          id={'static'}
          label={'Dynamic environment '}
          description={'When enabled, this environment disappears when the associated PR is merged/closed.'}
          checked={!isStatic}
          disabled={true}
        />
        <CheckboxSetting
          id={'staging-api'}
          label={'Staging API + Paddle Sandbox'}
          description={'When enabled, the electron app will use our staging backend API and Paddle sandbox.'}
          checked={stagingApi}
          onChange={checked => setStagingApi(checked)}
          disabled={isPublic || isStatic || userIds?.length > 0}
        />
        <CheckboxSetting
          id={'public'}
          label={'Publicly available'}
          description={'When enabled, this environment will be available to every user as an opt-in environment.'}
          checked={isPublic}
          onChange={checked => setPublic(checked)}
          disabled={isStatic || stagingApi}
        />

        <CheckboxSetting
          id={'allow-users'}
          label={'Explicitly allow users'}
          description={allowedUsers}
          checked={userIds !== undefined}
          onChange={checked => {
            setUserIds(checked ? (environment.userIds || []) : undefined)
            if (!checked) {
              setUsers([])
            }
          }}
          disabled={environment.static || stagingApi}
        />
      </Toggles>

      <Divider/>

      {
        environment.modules.length > 0 &&
        <Fragment>
          Latest Module Versions
          <Modules>
            {
              environment.modules.length === 1 && environment.modules[0] === 'recorder' &&
              <ModulePlaceholder module={'electron'}/>
            }
            {
              environment.modules.map(module => {
                return (
                  <Module
                    key={`${environment.value}-${module}`}
                    id={`environment-${environment.value}-${module}`}
                    environment={environment}
                    module={module}
                  />
                )
              })
            }
            {
              environment.modules.length === 1 && environment.modules[0] === 'electron' &&
              <ModulePlaceholder module={'recorder'}/>
            }
          </Modules>
        </Fragment>
      }
    </Modal>
  )
}

export default EnvironmentModal;

const Modules = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
`

const UsersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
`

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.064);
  font-weight: 500;
  pointer-events: all;
  cursor: default;
  position: relative;

  .remove-user {
    display: none;
  }
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    
    .remove-user {
      display: flex;
    }
  }
`

const AvatarContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
`

const AvatarImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Toggles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
