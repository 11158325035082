import Modal from '../../../components/Modal'
import {Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Button, CircleButton, colors, ContextMenu, InputField, Tooltip} from '@get-wrecked/simple-components'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import equal from 'deep-equal'
import {ChangelogsContext, EnvironmentContext, ModalContext, ToastContext, UserContext} from '../../../contexts'
import useTimeAgo from '../../../hooks/useTimeAgo'
import Badge from '../../../components/Badge'
import {WarningText} from '../../../components/styles'
import OverflowIcon from '../../../components/Icons/OverflowIcon'
import {useLocation, useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {addChangelogActiveUser, removeChangelogActiveUser, updateChangelog} from '../../../utils/firestore'
import useMediaQuery from '../../../hooks/useMediaQuery'
import ConfirmationDialog from '../../ConfirmationDialog'
import {ColoredEnvironmentIcon} from '../../../components/Environment'

const DEFAULT_VIEW = 'preview'
const COMPACT_BOUNDS = 'max-width: 800px'
const COMPACT_NAV_BOUNDS = 'max-width: 580px'

const ChangelogsModal = () => {
  const {loading, user} = useContext(UserContext)
  const {pushToast} = useContext(ToastContext)
  const {closeModal} = useContext(ModalContext)
  const {changelogs, subChangelogs, unsubChangelogs, createChangelog} = useContext(ChangelogsContext)

  // navigational states
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [selectedId, setSelectedId] = useState(null)
  const selectedIdRef = useRef(selectedId)

  const selectedChangelog = useMemo(() => {
    return changelogs.find(log => log.id === selectedId)
  }, [changelogs, selectedId])

  // view states
  // previewMode can be one of the following: preview, editor, split
  const [previewMode, setPreviewMode] = useState(localStorage.getItem('changelogs.previewMode') ?? DEFAULT_VIEW) // @todo previewMode based on viewport width
  const isCompact = useMediaQuery(COMPACT_BOUNDS)
  const isCompactRef = useRef(isCompact)
  const isCompactNav = useMediaQuery(COMPACT_NAV_BOUNDS)
  const canSplitViewExpanded = useMediaQuery('min-width: 1200px')
  const canSplitViewCompact = useMediaQuery('min-width: 900px')
  const [listExpanded, setListExpanded] = useState(!isCompact && Boolean(localStorage.getItem('changelogs.listExpanded') ?? true))
  const canSplitView = !isCompact && listExpanded
    ? canSplitViewExpanded
    : canSplitViewCompact

  // permissions states
  const [hasPermissions, setHasPermissions] = useState(true)

  const onCreateNew = async () => {
    const ref = await createChangelog()
    navigate(`/changelogs/${ref.id}`)
  }

  const onClickPreviewMode = (mode) => {
    localStorage.setItem('changelogs.previewMode', mode)
    setPreviewMode(mode)
  }

  const tryUpdateActiveUsers = async (id, user, action) => {
    if (hasPermissions !== false) {
      try {
        if (action === 'add') {
          await addChangelogActiveUser(id, user)
        } else if (action === 'remove') {
          await removeChangelogActiveUser(id, user)
        }
      } catch (err) {
        if (err.code === 'permission-denied') {
          setHasPermissions(false)
          pushToast({
            title: 'Missing changelog permissions',
            body: 'Ask @Josh on Slack for write access',
            type: 'error'
          })
        } else if (err.code === 'not-found') {
          // silently fail here, a non-issue
          console.log('Skipping activity update, changelog deleted:', id)
        } else {
          pushToast({
            title: 'Error updating active editors',
            body: err.message,
            type: 'error'
          })
          console.error(err.code, err)
        }
      }
    }
  }

  const addSelf = async (id) => {
    console.log('adding self to active editors for:', id)
    return tryUpdateActiveUsers(id, {
      userName: user.userName,
      userId: user.userId,
      thumbnail: user.thumbnail
    }, 'add')
  }

  const removeSelf = async (id) => {
    const logId = id ?? selectedId
    return tryUpdateActiveUsers(logId, {
      userName: user.userName,
      userId: user.userId,
      thumbnail: user.thumbnail
    }, 'remove')
  }

  // handle route navigation
  useEffect(() => {
    const defaultFallback = () => navigate(`/changelogs/${changelogs[0].id}`, {replace: true})

    // if location + id param changes and doesn't match the selected id, navigate to it
    if (changelogs.length > 0 && location.pathname.startsWith('/changelog')) {
      if (params.id && selectedId !== params.id) {
        const log = changelogs.find(log => log.id === params.id)
        if (log) {
          selectedIdRef.current = params.id
          setSelectedId(params.id)
        } else {
          // @todo alert no changelog by that id
          pushToast({
            title: 'Missing or invalid changelog',
            body: `Unable to find changelog by the ID of '${params.id}'`,
            type: 'error'
          })
          defaultFallback()
        }
      } else if (params.id && selectedId === params.id && !selectedChangelog) {
        console.log('changelog likely deleted, defaulting to most recent.')
        defaultFallback()
      } else if (!params.id) {
        console.log('no changelog id provided, defaulting to most recent.')
        defaultFallback()
      }
    } else if (!location.pathname.startsWith('/changelog')) {
      closeModal('changelogs-modal')
    }
  }, [changelogs, location, params, selectedId])

  useEffect(() => {
    if (!loading && user?.userId) {
      subChangelogs()
    }
    return () => {
      unsubChangelogs()
    }
  }, [loading, user])

  useEffect(() => {
    const onImageClick = (event) => {
      if (event.target.tagName === 'IMG') {
        const imageUrl = event.target.src
        window.open(imageUrl, '_blank')
      }
    }
    document.addEventListener('click', onImageClick)
    return () => {
      document.removeEventListener('click', onImageClick)
    }
  }, [])

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (selectedId) {
        if (event) {
          const message = 'Are you sure you want to leave?'
          event.preventDefault()
          event.returnValue = message
        }
        removeSelf(selectedId)
      }
    }

    if (selectedId) {
      if (previewMode === 'preview' && !selectedChangelog.description?.length) {
        setPreviewMode('editor')
      } else {
        setPreviewMode(localStorage.getItem('changelogs.previewMode') ?? DEFAULT_VIEW)
      }
      addSelf(selectedId)
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      if (selectedId) {
        removeSelf(selectedId)
      }
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [selectedId])

  useEffect(() => {
    const wasCompact = isCompactRef.current
    isCompactRef.current = isCompact
    if (wasCompact !== isCompact) {
      if (isCompact && listExpanded) {
        setListExpanded(false)
      } else if (!isCompact && !listExpanded && Boolean(localStorage.getItem('changelogs.listExpanded') ?? true)) {
        setListExpanded(true)
      }
    }
  }, [isCompact, listExpanded])

  return (
    <Modal
      id={'changelogs-modal'}
      header={
        <Fragment>
          <ModalHeader>
            <ListToggle
              onClick={() => {
                const expanded = !listExpanded
                if (!isCompact) {
                  localStorage.setItem('changelogs.listExpanded', expanded ? 'true' : '')
                }
                setListExpanded(expanded)
              }}
            >
              <CollapseExpandIcon
                id="hamburger"
                size={22}
                color={'white'}
              />
              {
                listExpanded
                  ? <svg id="collapse" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke={'white'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20.5 12H3.67004" stroke={'white'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  : <svg id="collapse" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke={'white'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.5 12H20.33" stroke={'white'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              }
              Changelogs
            </ListToggle>
          </ModalHeader>
          <ModalActions>
            {
              !isCompactNav && selectedChangelog?.activeUsers?.length > 0 &&
              <ActiveUsers>
                {
                  selectedChangelog.activeUsers.map(u => {
                    return (
                      <Tooltip
                        key={`active-${u.userId}`}
                        tooltip={<span style={{textTransform: 'none'}}>{u.userId === user.userId ? 'You are' : `${u.userName} is`} viewing this changelog</span>}
                        position="bottom"
                      >
                        <ActiveUser>
                          <img src={u.thumbnail}/>
                        </ActiveUser>
                      </Tooltip>
                    )
                  })
                }
              </ActiveUsers>
            }
            <PreviewMode>
              <div
                className={previewMode === 'preview' ? 'selected' : ''}
                onClick={() => onClickPreviewMode('preview')}
              >
                Preview
              </div>
              <div
                className={(previewMode === 'editor' || (previewMode === 'split' && !canSplitView)) ? 'selected' : ''}
                onClick={() => onClickPreviewMode('editor')}
              >
                Editor
              </div>
              {
                canSplitView &&
                <div
                  className={previewMode === 'split' ? 'selected' : ''}
                  onClick={() => onClickPreviewMode('split')}
                >
                  Split
                </div>
              }
            </PreviewMode>
            <Tooltip
              tooltip={
                hasPermissions
                  ? 'Start a new changelog'
                  : <SaveTooltip>
                    <WarningText>You don't have permissions!</WarningText>
                    Ask @Josh on Slack.
                  </SaveTooltip>
              }
              position="left"
            >
              <Button
                size="small"
                variant="secondary"
                style={{
                  marginLeft: 'auto',
                  width: isCompactNav ? '28px' : 'auto',
                  ...(isCompactNav ? {padding: '0'} : {})
                }}
                onClick={onCreateNew}
                disabled={!hasPermissions}
              >
                <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7H13" stroke={colors.brand.primary['50']} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 13V1" stroke={colors.brand.primary['50']} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {
                  !isCompactNav &&
                  'Create New'
                }
              </Button>
            </Tooltip>
          </ModalActions>
        </Fragment>
      }
      size="fullscreen"
      navigateOnClose={'home'}
      bodyPadding={'0px'}
      scrollable={false}
    >
      <Body>
        {
          listExpanded &&
          <List
            className="scrollable y"
            $isCompact={isCompact}
          >
            {
              changelogs.map(log => {
                return (
                  <ChangelogEntry
                    key={log.id}
                    isSelected={selectedId === log.id}
                    onClick={() => {
                      navigate(`/changelogs/${log.id}`)
                      if (isCompact) {
                        setListExpanded(false)
                      }
                    }}
                    {...log}
                  />
                )
              })
            }
          </List>
        }
        {
          selectedChangelog &&
          <ActiveChangelog
            log={selectedChangelog}
            previewMode={!canSplitView && previewMode === 'split' ? 'editor' : previewMode}
            hasPermissions={hasPermissions}
          />
        }
      </Body>
    </Modal>
  )
}

export default ChangelogsModal

const CollapseExpandIcon = ({id, size = 24, color = 'white'}) => {
  return (
    <svg id={id} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 7H21" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M3 12H21" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M3 17H21" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const ChangelogEntry = ({isSelected, createdAt, lastUpdatedAt, published, title, onClick}) => {
  const timeAgo = useTimeAgo(createdAt)
  const updatedTimeAgo = useTimeAgo(lastUpdatedAt)
  return (
    <ListEntry
      onClick={onClick}
      $selected={isSelected}
    >
      <TitleWrapper>
        <Title>
          {title}
        </Title>
      </TitleWrapper>
      <Metadata>
        <Badge type={published ? 'gray' : 'brand'}>
          {published ? 'Published' : 'Draft'}
        </Badge>
        {
          lastUpdatedAt > createdAt
            ? <SubText>{updatedTimeAgo}</SubText>
            : <SubText>{timeAgo}</SubText>
        }
      </Metadata>
    </ListEntry>
  )
}

const ActiveChangelog = ({previewMode, hasPermissions, log}) => {
  const {user} = useContext(UserContext)
  const {pushToast} = useContext(ToastContext)
  const {openModal} = useContext(ModalContext)
  const {updateChangelog} = useContext(ChangelogsContext)
  const timeAgo = useTimeAgo(log.createdAt)
  const updatedTimeAgo = useTimeAgo(log.lastUpdatedAt)
  const idRef = useRef(log.id)
  const localUpdateRef = useRef(false)
  const isEditing = previewMode === 'split' || previewMode === 'editor'
  const splitPreview = previewMode === 'split'
  const percentageScrolledRef = useRef()

  const [state, setState] = useState({
    title: log.title,
    description: log.description,
    earlyAccess: log.earlyAccess ?? null,
    testFlightLink: log.testFlightLink ?? null
  })

  const hasChanges = useMemo(() => {
    return state.title !== log.title ||
      state.description !== log.description ||
      (
        !equal(state.earlyAccess ?? null, log.earlyAccess ?? null) && // objects arent equal
        (!!state.earlyAccess?.promoteEarlyAccess !== !!log.earlyAccess?.promoteEarlyAccess || // and any of bool values arent
        !!state.earlyAccess?.promoteDiscord !== !!log.earlyAccess?.promoteDiscord)
      ) ||
      (state.testFlightLink ?? null) !== (log.testFlightLink ?? null) // @todo fix reload initially showing
  }, [state, log])

  const hasLocalChanges = hasChanges && localUpdateRef.current
  const hasRemoteChanges = hasChanges && localUpdateRef.current < log.lastUpdatedAt && log.lastUpdatedBy?.userId !== user.userId

  const body = useMemo(() => {
    if (!state.description) {
      return ''
    }
    return state.description.split('<br/>').join('\n')
  }, [state])

  const onSave = () => {
    try {
      localUpdateRef.current = false
      updateChangelog(log.id, state)
    } catch (err) {
      pushToast({
        title: 'Missing changelog permissions',
        body: 'Ask @Josh on Slack for write access'
      })
      console.error(err)
    }
  }

  const onTogglePublished = () => {
    openModal('publish-changelog', {
      id: log.id,
      title: state.title,
      published: log.published
    })
  }

  const onReload = () => {
    localUpdateRef.current = false
    updateState({
      title: log.title,
      description: log.description,
      earlyAccess: log.earlyAccess ?? null,
      testFlightLink: log.testFlightLink ?? null
    })
  }

  const updateState = useCallback((newState, isLocalUpdate) => {
    if (isLocalUpdate) {
      localUpdateRef.current = Date.now()
    }
    console.log('state:', state)
    console.log('newState:', newState)
    console.log('joined:', {...state, ...newState})
    setState({
      ...state,
      ...newState
    })
  }, [state, setState])

  const onPercentageScrolled = (percentage) => {
    percentageScrolledRef.current = percentage
  }

  // listen for changes to the selected log
  useEffect(() => {
    if (log.id !== idRef.current) {
      idRef.current = log.id
      localUpdateRef.current = false // reset to no local changes
      percentageScrolledRef.current = 0 // reset to not scrolled
      console.log('updating state from changes to log ID')
      updateState({
        title: log.title,
        description: log.description,
        earlyAccess: log.earlyAccess ?? null,
        testFlightLink: log.testFlightLink ?? null
      })
    }
  }, [log])

  // listen for remote changes to the active log
  useEffect(() => {
    if (log.id === idRef.current) {
      const logState = {
        title: log.title,
        description: log.description,
        earlyAccess: log.earlyAccess ?? null,
        testFlightLink: log.testFlightLink ?? null
      }

      // check if title or description was updated remotely
      if (!equal(logState, state)) {
        // check if user has local changes
        if (localUpdateRef.current) {
          // use has changes, prompt user to resolve
          // @todo
          // console.log('local state out of sync now:', localUpdateRef.current, logState, state)
        } else {
          // no local changes, can update state directly
          console.log('updating state from remote changes to current log')
          updateState(logState)
        }
      }
    }
  }, [log, state])

  return (
    <Fragment>
      {
        splitPreview && isEditing &&
        <Fragment>
          <ChangelogContents
            {...log}
            title={state.title}
            body={state.description}
            earlyAccess={state.earlyAccess}
            testFlightLink={state.testFlightLink}
            subtext={<Fragment>{log.lastUpdatedAt > log.createdAt ? <Fragment>Updated {updatedTimeAgo}</Fragment> : <Fragment>Created {timeAgo}</Fragment>}</Fragment>}
            hasPermissions={hasPermissions}
            editMode={true}
            showPublishButton={!hasLocalChanges && !hasRemoteChanges}
            showSaveButton={hasLocalChanges && !hasRemoteChanges}
            hasRemoteChanges={hasRemoteChanges}
            onReload={onReload}
            onSave={onSave}
            onTogglePublished={onTogglePublished}
            onPercentageScrolled={onPercentageScrolled}
            updateLocalState={newState => updateState(newState, true)}
          />
          <VerticalDivider/>
        </Fragment>
      }
      <ChangelogContents
        {...log}
        title={state.title}
        body={!splitPreview && isEditing ? state.description : body}
        earlyAccess={state.earlyAccess}
        testFlightLink={state.testFlightLink}
        subtext={<Fragment>{log.lastUpdatedAt > log.createdAt ? <Fragment>Updated {updatedTimeAgo}</Fragment> : <Fragment>Created {timeAgo}</Fragment>}</Fragment>}
        hasPermissions={hasPermissions}
        editMode={!splitPreview && isEditing}
        isLivePreview={splitPreview && isEditing}
        showPublishButton={!splitPreview && !hasLocalChanges && !hasRemoteChanges}
        showSaveButton={!splitPreview && hasLocalChanges && !hasRemoteChanges}
        hasRemoteChanges={!(splitPreview && isEditing) && hasRemoteChanges}
        onReload={onReload}
        onSave={onSave}
        onTogglePublished={onTogglePublished}
        onPercentageScrolled={!splitPreview && isEditing ? onPercentageScrolled : undefined}
        updateLocalState={newState => updateState(newState, true)}
      />
    </Fragment>
  )
}

const ChangelogContents = ({
  id,
  title,
  body,
  subtext,
  published,
  environments,
  video,
  earlyAccess,
  testFlightLink,
  hasPermissions,
  isLivePreview,
  showSaveButton,
  showPublishButton,
  hasRemoteChanges,
  editMode,
  onReload = () => {},
  onSave = () => {},
  onTogglePublished = () => {},
  onPercentageScrolled = () => {},
  updateLocalState = () => {}
}) => {
  const {openModal} = useContext(ModalContext)
  const {pushToast} = useContext(ToastContext)
  const {environments: allEnvironments, getEnvironment} = useContext(EnvironmentContext)
  const textAreaRef = useRef()
  const [removingVideo, setRemovingVideo] = useState(false)
  const [addingTestFlightLink, setAddingTestFlightLink] = useState(false)
  const [removingTestFlightLink, setRemovingTestFlightLink] = useState(false)

  const environmentObjects = useMemo(() => {
    return environments
      .filter(env => allEnvironments.some(e => e.value === env))
      .map(env => {
        return getEnvironment(env === 'development' ? 'sandbox' : env)
      })
  }, [environments, allEnvironments])

  const onScroll = () => {
    const editor = textAreaRef.current
    const percentageScrolled = (editor.scrollTop / (editor.scrollHeight - editor.clientHeight)) * 100;

    // Set the scroll position in div2 based on the percentage scrolled in div1
    const livePreview = document.getElementById('live-preview')
    if (livePreview) {
      livePreview.scrollTop = (percentageScrolled / 100) * (livePreview.scrollHeight - livePreview.clientHeight)
    }

    if (typeof onPercentageScrolled === 'function') {
      onPercentageScrolled(percentageScrolled)
    }
  }

  const overflowMenuItems = useMemo(() => {
    const options = []
    if (published) {
      options.push({
        label: 'Unpublish',
        onClick: () => {
          onTogglePublished()
        }
      })
      options.push({
        divider: true
      })
    }
    options.push({
      label: <WarningText>Delete changelog</WarningText>,
      onClick: () => {
        if (published) {
          pushToast({
            title: 'Cannot delete published changelogs',
            body: 'If you want to delete this changelog, first unpublish it.',
          })
        } else {
          openModal('delete-changelog', {
            id,
            title
          })
        }
      }
    })
    return options
  }, [id, title, published])

  const onEditVideo = () => {
    openModal('add-changelog-video', {
      id
    })
  }

  const onRemoveVideo = async () => {
    if (!removingVideo) {
      setRemovingVideo(true)
      return
    }
    try {
      await updateChangelog(id, {
        video: null
      })
      updateLocalState({})
    } catch (err) {
      console.error(err)
    }
    setRemovingVideo(false)
  }


  const onRemoveTestFlightLink = async (e) => {
    if (e) {
      e.stopPropagation()
    }

    if (!removingTestFlightLink) {
      setRemovingTestFlightLink(true)
      return
    }
    try {
      await updateChangelog(id, {
        testFlightLink: null
      })
      updateLocalState({
        testFlightLink: null
      })
    } catch (err) {
      console.error(err)
    }
    setRemovingTestFlightLink(false)
  }

  const sectionMenuItems = useMemo(() => {
    const options = []
    if (!video?.url) {
      options.push({
        label: 'Video',
        onClick: onEditVideo
      })
    }
    if (!earlyAccess?.promoteEarlyAccess) {
      options.push({
        label: 'Get Early Access CTA',
        onClick: () => {
          updateLocalState({
            earlyAccess: {
              ...(earlyAccess ?? {}),
              promoteEarlyAccess: true
            }
          })
        }
      })
    }
    if (!earlyAccess?.promoteDiscord) {
      options.push({
        label: 'Join Early Access Discord CTA',
        onClick: () => {
          updateLocalState({
            earlyAccess: {
              ...(earlyAccess ?? {}),
              promoteDiscord: true
            }
          })
        }
      })
    }

    if (!testFlightLink?.includes('testflight.apple.com')) {
      options.push({
        label: 'TestFlight CTA',
        onClick: () => {
          setAddingTestFlightLink(true)
        }
      })
    }
    return options
  }, [id, video, earlyAccess, testFlightLink, updateLocalState])

  useEffect(() => {
    if (id && textAreaRef.current) {
      textAreaRef.current.focus()
    }
  }, [id])

  const sections = []
  if (earlyAccess?.promoteEarlyAccess) {
    sections.push(
      <EarlyAccessPromo
        editMode={isLivePreview || !editMode}
        onRemove={() => {
          updateLocalState({
            earlyAccess: {
              ...(earlyAccess ?? {}),
              promoteEarlyAccess: false
            }
          })
        }}
      />
    )
  }
  if (earlyAccess?.promoteDiscord) {
    sections.push(
      <EarlyAccessDiscordPromo
        editMode={isLivePreview || !editMode}
        onRemove={e => {
          e.stopPropagation()
          updateLocalState({
            earlyAccess: {
              ...(earlyAccess ?? {}),
              promoteDiscord: false
            }
          })
        }}
      />
    )
  }
  if (testFlightLink?.length && testFlightLink.includes('testflight.apple.com')) {
    sections.push(
      <TestFlightPromo
        editMode={isLivePreview || editMode}
        testFlightLink={testFlightLink}
        onRemove={onRemoveTestFlightLink}
      />
    )
  }

  return (
    <ActiveChangelogContainer>
      <ChangelogContainer>
        <Header>
          <HeaderLeftAligned>
            {
              !isLivePreview
                ? <Fragment>
                  <TitleWrapper>
                    {
                      editMode
                        ? <InlineTitleEditor
                          title={title}
                          onChange={e => updateLocalState({
                            title: e.target.value
                          })}
                        />
                        : <Title>{title}</Title>
                    }
                  </TitleWrapper>
                  <SubText>
                    {
                      environmentObjects.map((env, index) => {
                        return (
                          <Badge
                            key={`env-badge-${env.value ?? env}-${index}`}
                            type={editMode ? 'gray' : 'default'}
                            onClick={editMode ? () => {
                              openModal('changelog-environments-modal', {id})
                            } : undefined}
                          >
                            <ColoredEnvironmentIcon
                              size={16}
                              environment={env}
                            />
                            {env?.label ?? env}
                          </Badge>
                        )
                      })
                    }
                    {
                      editMode &&
                      <Tooltip
                        tooltip={
                          <span>
                            Add environment
                            <br/>
                            <span style={{fontWeight: 'normal'}}>Choose an environment to show this changelog in</span>
                          </span>
                        }
                        position="bottom"
                      >
                        <Badge
                          type="gray"
                          onClick={() => openModal('changelog-environments-modal', {id})}
                        >
                          <svg width="8" height="8" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7H13" stroke={'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7 13V1" stroke={'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </Badge>
                      </Tooltip>
                    }
                    {subtext}
                  </SubText>
                </Fragment>
                : <SubText>Live Preview</SubText>
            }
          </HeaderLeftAligned>
          <ChangelogActions>
            {
              !showPublishButton && showSaveButton &&
              <Tooltip
                tooltip={
                  hasPermissions
                    ? 'Changes will take place immediately'
                    : <SaveTooltip>
                      <WarningText>You don't have permissions!</WarningText>
                      Ask @Josh on Slack.
                    </SaveTooltip>
                }
                position="left"
              >
                <Button
                  size="small"
                  variant="success"
                  onClick={onSave}
                  disabled={!hasPermissions}
                >
                  Save
                </Button>
              </Tooltip>
            }
            {
              showPublishButton && !published &&
              <Tooltip
                tooltip={
                  hasPermissions
                    ? (
                      published
                        ? 'Unpublish and return to draft mode -- users won\'t see it'
                        : 'Publish to users'
                    )
                    : <SaveTooltip>
                      <WarningText>You don't have permissions!</WarningText>
                      Ask @Josh on Slack.
                    </SaveTooltip>
                }
                position="left"
              >
                <Button
                  size="small"
                  variant={published ? 'tertiary' : 'secondary'}
                  onClick={onTogglePublished}
                  disabled={!hasPermissions}
                >
                  {published ? 'Unpublish' : 'Publish'}
                </Button>
              </Tooltip>
            }
            {
              hasRemoteChanges &&
              <Tooltip
                tooltip={
                  <span>
                    Changes have been made since you started editing.
                    <br/>
                    Click to load the latest version (you will lose unsaved changes)
                  </span>
                }
                position="left"
              >
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={onReload}
                >
                  Reload
                </Button>
              </Tooltip>
            }
            <ActionButtons>
              {
                !isLivePreview && editMode && sectionMenuItems.length > 0 &&
                <ContextMenu
                  triggerId={`${id}-add-section`}
                  position="bottom"
                  alignment="right"
                  items={sectionMenuItems}
                >
                  <Tooltip
                    tooltip="Add section"
                    position="left"
                  >
                    <CircleButton id={`${id}-add-section`}>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
                          fill="white"
                        />
                      </svg>
                    </CircleButton>
                  </Tooltip>
                </ContextMenu>
              }
              {
                !isLivePreview &&
                <ContextMenu
                  triggerId={`${id}-overflow`}
                  position="bottom"
                  alignment="right"
                  items={overflowMenuItems}
                >
                  <CircleButton id={`${id}-overflow`}>
                    <OverflowIcon size={16}/>
                  </CircleButton>
                </ContextMenu>
              }
            </ActionButtons>
          </ChangelogActions>
        </Header>
        <ChangelogBody className={isLivePreview || !editMode ? 'scrollable y' : undefined}>
          {
            (isLivePreview || !editMode) && video?.url &&
            <VideoContainer>
              <video
                src={video.url}
                poster={video.poster}
                controls={true}
              />
              {
                (isLivePreview || editMode) &&
                <SectionActions>
                  <Button
                    size="xsmall"
                    variant="ghost-neutral"
                    onClick={onEditVideo}
                  >
                    Edit
                  </Button>
                  <Button
                    size="xsmall"
                    variant="danger"
                    onClick={onRemoveVideo}
                  >
                    Remove
                  </Button>
                </SectionActions>
              }
            </VideoContainer>
          }
          {
            (editMode && !isLivePreview)
              ? <TextArea
                ref={textAreaRef}
                id={`textarea`}
                autoFocus={editMode}
                className="scrollable y"
                onChange={e => {
                  updateLocalState({
                    description: e.target.value
                  })
                }}
                onScroll={onScroll}
                value={body}
                placeholder={'Eagerly awaiting your keystrokes...'}
              />
              : <MarkdownStyle id={isLivePreview ? 'live-preview' : ''}>
                <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                  {body}
                </Markdown>
              </MarkdownStyle>
          }
          {
            (isLivePreview || !editMode) && sections.length > 0 &&
            <Sections>
              {
                sections.map((section, index) => {
                  return (
                    <Fragment key={`section-${index}`}>
                      {section}
                      {
                        index < (sections.length - 1) &&
                        <Divider/>
                      }
                    </Fragment>
                  )
                })
              }
            </Sections>
          }
        </ChangelogBody>
      </ChangelogContainer>
      {
        addingTestFlightLink &&
        <AddTestFlightLinkModal
          testFlightLink={testFlightLink}
          onAdd={(link) => {
            updateLocalState({
              testFlightLink: link
            })
            setAddingTestFlightLink(false)
          }}
          onClose={() => setAddingTestFlightLink(false)}
        />
      }
      {
        removingVideo &&
        <ConfirmationDialog
          modalId="confirm-remove-video"
          title="Are you sure?"
          description="Removing the video takes effect immediately for anyone viewing the changelog."
          confirmType="danger"
          confirmLabel="Remove Video"
          onConfirm={onRemoveVideo}
          onCancel={() => setRemovingVideo(false)}
        />
      }
      {
        removingTestFlightLink &&
        <ConfirmationDialog
          modalId="confirm-remove-testflight-link"
          title="Are you sure?"
          description={
            <span>
              You are about to remove the following TestFlight link:
              <br/>
              <code>{testFlightLink}</code>
            </span>
          }
          confirmType="danger"
          confirmLabel="Remove TestFlight Link"
          onConfirm={onRemoveTestFlightLink}
          onCancel={() => setRemovingTestFlightLink(false)}
        />
      }
    </ActiveChangelogContainer>
  )
}

const InlineTitleEditor = ({title, onChange}) => {
  return <InlineTitleInput
    value={title}
    onChange={onChange}
  />
}

const pattern = /^https:\/\/testflight\.apple\.com\/join\/[A-Za-z0-9]+$/
const AddTestFlightLinkModal = ({testFlightLink, onAdd = () => {}, onClose = () => {}}) => {
  const [link, setLink] = useState(testFlightLink ?? '')
  const isLinkValid = pattern.test(link)
  return (
    <Modal
      id="add-testflight-link"
      size="small"
      header="Add TestFlight Link"
      onClose={onClose}
    >
      <AddLinkModalBody>
        <InputField
          value={link}
          placeholder="Add a testflight.apple.com link"
          onChange={e => {
            const value = e.target.value
            setLink(value)
          }}
          style={{flex: 1}}
        />
        <Button
          variant="secondary"
          size="large"
          disabled={!isLinkValid}
          onClick={() => onAdd(link)}
        >
          Add Link
        </Button>
      </AddLinkModalBody>
    </Modal>
  )
}

const EarlyAccessPromo = ({editMode, onRemove = () => {}}) => {
  return (
    <Tooltip
      tooltip={
        <span>
          For preview purposes only.
          <br/>In the app, the install CTA only shows to users on Latest Stable.
          <br/>If the user is already on Early Access, it indicates that.
        </span>
      }
      position="bottom"
    >
      <Section>
        <SectionLeft>
          <img src={'https://cdn.medal.tv/assets/img/beta-icon.jpg'}/>
          <SectionBody>
            <SectionTitle>
              Get Early Access
            </SectionTitle>
            <SectionDescription>
              Get exclusive first access to updates like this
            </SectionDescription>
          </SectionBody>
        </SectionLeft>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {}}
        >
          Install Early Access
        </Button>

        {
          editMode &&
          <SectionActions>
            <Button
              size="xsmall"
              variant="danger"
              onClick={onRemove}
            >
              Remove
            </Button>
          </SectionActions>
        }
      </Section>
    </Tooltip>
  )
}

const EarlyAccessDiscordPromo = ({editMode, onRemove = () => {}}) => {
  return (
    <Tooltip
      tooltip={
        <span>
          For preview purposes only.
          <br/>In the app, this will only show to users already on Early Access.
        </span>
      }
      position="bottom"
    >
      <Section onClick={() => window.open('https://discord.gg/UkcF74Wru4', '_blank')}>
        <SectionLeft>
          <img src={'https://i.imgur.com/LiMLuUo.png'}/>
          <SectionBody>
            <SectionTitle>
              Early Access Discord
            </SectionTitle>
            <SectionDescription>
              Join our Discord and give us feedback!
            </SectionDescription>
          </SectionBody>
        </SectionLeft>
        <Button
          size="medium"
          variant="secondary"
          onClick={() => {}}
        >
          Join Discord
        </Button>

        {
          editMode &&
          <SectionActions>
            <Button
              size="xsmall"
              variant="danger"
              onClick={onRemove}
            >
              Remove
            </Button>
          </SectionActions>
        }
      </Section>
    </Tooltip>
  )
}

const TestFlightPromo = ({editMode, testFlightLink, onRemove = () => {}}) => {
  return (
    <Section onClick={() => window.open(testFlightLink, '_blank')}>
      <SectionLeft>
        <img src={'https://cdn.medal.tv/assets/img/testflight-64x64.png'}/>
        <SectionBody>
          <SectionTitle>
            Join TestFlight on iOS
          </SectionTitle>
          <SectionDescription>
            Get Medal on TestFlight for iOS for exclusive first access to features
          </SectionDescription>
        </SectionBody>
      </SectionLeft>
      <Button
        size="medium"
        variant="secondary"
        onClick={() => {}}
      >
        Join TestFlight
      </Button>

      {
        editMode &&
        <SectionActions>
          <Button
            size="xsmall"
            variant="danger"
            onClick={onRemove}
          >
            Remove
          </Button>
        </SectionActions>
      }
    </Section>
  )
}

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-inline-start: -46px;
`

const ListToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  padding-inline-start: 6px;
  margin-inline-start: -6px;
  border-radius: 8px;
  cursor: pointer;
  gap: 12px;

  #collapse {
    display: none;
  }

  #hamburger {
    display: block;
  }

  svg {
    opacity: 0.72;
  }

  &:hover {
    opacity: 1;
    background-color: ${colors.neutral['0A16']};

    #collapse {
      display: block;
    }

    #hamburger {
      display: none;
    }

    svg {
      opacity: 0.72;
    }
  }
`

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 16px;
  border-right: 1px solid ${colors.stroke['0A8']};
  margin-inline-start: auto;
`

const PreviewMode = styled.div`
  display: flex;
  height: 28px;
  border-radius: 8px;
  border: 1px solid ${colors.neutral['0A24']};
  background-color: ${colors.stroke['0A8']};
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 100%;
    cursor: pointer;
    font-size: 12px;
    color: ${colors.text['30']};

    &:hover, &.selected {
      background-color: ${colors.stroke['0A8']};
    }

    &.selected {
      background-color: ${colors.background['first-layer']};
    }
  }

  > div:not(:last-child) {
    border-right: 1px solid ${colors.neutral['0A24']};
  }
`

const ActiveUsers = styled.div`
  display: flex;
  margin-right: 4px;
`

const ActiveUser = styled.div`
  width: 28px;
  height: 28px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: -4px;
  background-color: ${colors.background['first-layer']};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  border-right: 1px solid ${colors.stroke['0A8']};
  flex-shrink: 0;
  max-width: 300px;

  ${({$isCompact}) => {
    if ($isCompact) {
      return `
        background-color: ${colors.background['second-layer']};
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      `
    }
    return ''
  }}

  div:not(:last-child) {
    border-bottom: 1px solid ${colors.stroke['0A8']};
  }
`

const ListEntry = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  white-space: nowrap;
  ${({$selected}) => $selected ? `background-color: ${colors.neutral['0A4']};` : ''}
  gap: 4px;

  &:hover {
    background-color: ${colors.neutral['0A4']};
  }
`

const TitleWrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
`

const TitleCSS = css`
  font-size: 15px;
  font-weight: 500;
  color: white;
  min-height: 25px;
  line-height: 25px;
`

const Title = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${TitleCSS}
`

const InlineTitleInput = styled.input`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  border-radius: 0;
  display: flex;
  flex: 1;

  &:hover {
    background: none;
    border: none;
    outline: none;
    border-radius: 0;
    text-decoration: underline;
  }

  &:active, &:focus {
    text-decoration: none;
  }

  ${TitleCSS}
`

const Metadata = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 0px;
  background-color: ${colors.stroke['0A8']};
`

const ActiveChangelogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;

`

const ChangelogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: min(95vw, 900px);
  height: 100%;
`

const ChangelogBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
  width: 100%;
  border-bottom: 1px solid ${colors.stroke['0A8']};
  min-height: 62px;
  gap: 12px;
`

const HeaderLeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  flex: 1;
  min-width: 0;
`

const SubText = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 12px;
  color: ${colors.text['70']};
`

const ChangelogActions = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: auto;
  gap: 12px;
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

const SaveTooltip = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-transform: none;
`

const SectionActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(8px);
  border-top-right-radius: 12px;
  border-bottom-left-radius: 11px;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 6px;
  opacity: 0;
  pointer-events: none;
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
  flex: none;
  
  video {
    width: 100%;
  }
  
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.64);
    outline: 1px solid ${colors.neutral['0A24']};
    outline-offset: -1px;
    
    ${SectionActions} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const TextArea = styled.textarea`
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  font-size: 15px;
  color: ${colors.text['30']};
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 12px 0;
  resize: none;
  height: 100%;

  &:hover {
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
  }
`

const MarkdownStyle = styled.div`
  font-size: 15px;
  color: ${colors.text['30']};
  white-space: pre-line;
  display: flex;
  flex-direction: column;

  blockquote {
    margin: 6px 0;
    padding: 0 0 0 16px;
    border-left: 2px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;

    p {
      margin: 0;
      padding: 0;
      line-height: 1.35;
      white-space: pre-line;
    }
  }

  img {
    display: block;
    border-radius: 8px;
    margin: 4px 0;
    transition: box-shadow 200ms ease, outline 200ms ease;
    max-width: 100%;
    outline: 1px solid ${colors.stroke['0A8']};
    outline-offset: -1px;
    cursor: pointer;

    &:hover {
      outline-color: ${colors.stroke['0A16']};
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.64);
    }
  }

  p {
    line-height: 1.75;
    user-select: text;
    margin: 0;
  }

  strong {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.84);
  }

  a {
    user-select: text;
  }

  hr {
    margin: 24px 0;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1em;
    margin: 12px 0 6px;
    color: ${colors.text['30']};
  }

  h1 {
    font-size: 22px;
    color: white;
    margin-top: 20px;
  }

  h2 {
    font-size: 20px;
    color: white;
  }

  h3 {
    font-size: 18px;
    color: white;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 14px;
  }

  ul, ol {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 18px;
    padding: 0;
    line-height: 1.3;
  }

  li {
    margin: 3px 0;
  }
`

const Sections = styled.div`
  border-radius: 12px;
  background-color: ${colors.neutral['0A4']};
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  transition: background-color 100ms ease;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  gap: 12px;
  position: relative;
  overflow: hidden;

  ${({ $inactive }) => ($inactive ? 'cursor: none; pointer-events: none;' : '')}
  
  &:hover {
    background-color: ${colors.neutral['0A4']};
    outline: 1px solid ${colors.neutral['0A24']};
    outline-offset: -1px;

    ${SectionActions} {
      opacity: 1;
      pointer-events: auto;
    }
  }

  img {
    width: 42px;
    height: 42px;
    border-radius: 8px;
  }
`

const SectionLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const SectionTitle = styled.span`
  font-weight: bold;
  color: white;
`

const SectionDescription = styled.span``

const Divider = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: ${colors.stroke['0A8']};
`

const AddLinkModalBody = styled.div`
  display: flex;
  gap: 12px;
`
