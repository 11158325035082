import {useContext, useMemo} from 'react'
import {VersionListContext} from '../contexts'

const useVersionList = ({environment, module}) => {
  const {versionLists} = useContext(VersionListContext)
  const versionList = useMemo(() => {
    const envValue = environment?.value ?? environment
    if (!envValue) {
      return []
    }
    return versionLists?.[`${environment?.value ?? environment}.${module}`] || [];
  }, [versionLists, environment, module])
  return versionList
}

export default useVersionList
