import axios from "axios";

const featureFlags = ['string_id_v2']
const userAgent = [
  `Medal-Builds/1.0.0 (${featureFlags.join('; ')})`
];

const get = async (route) => {
  return axios.get(`https://builds-api.medal.tv${route.startsWith('/') ? route : `/${route}`}`, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
};

const post = async (route, body) => {
  return axios.post(`https://builds-api.medal.tv${route.startsWith('/') ? route : `/${route}`}`, body, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
};

const patch = async (route, body) => {
  return axios.patch(`https://builds-api.medal.tv${route.startsWith('/') ? route : `/${route}`}`, body, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    }
  })
};

const deleteRequest = async (route, body) => {
  return axios.delete(`https://builds-api.medal.tv${route.startsWith('/') ? route : `/${route}`}`, {
    headers: {
      'Medal-User-Agent': userAgent,
      'x-authentication': localStorage.getItem('medal-user')
    },
    data: body
  })
}

const deriveActiveBuilds = (buildApiState, {environment, module} = {}) => {
  if (!buildApiState?.activeBuilds) {
    return []
  }
  return Object.values(buildApiState.activeBuilds).filter(build => {
    if (environment) {
      return build.environment === environment && (module ? build.module === module : true)
    } else {
      return module ? build.module === module : true
    }
  })
}

export {
  get,
  post,
  patch,
  deleteRequest,
  deriveActiveBuilds,
}
