import {Fragment, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, colors, LoadingSpinner} from '@get-wrecked/simple-components'
import Modal from '../../../components/Modal'
import styled from 'styled-components'
import {capitalize} from '../../../utils/string'
import {get} from '../../../utils/builds-api'
import {BuildApiContext, ToastContext, VersionListContext} from '../../../contexts'
import UpdateTypeSelection, {deriveUpdateType} from '../../../components/UpdateTypeSelection'
import ConfirmForceUpdatePrompt from '../../../components/ConfirmForceUpdatePrompt'
import useVersionList from '../../../hooks/useVersionList'

const propTypes = {
  parentEnvironment: PropTypes.object.isRequired,
  parentVersion: PropTypes.object.isRequired,
  environment: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  version: PropTypes.object.isRequired
}

const UpdateTypes = {
  DEFAULT: 'Default',
  SILENT: 'Silent',
  FORCED: 'Forced',
  IMMEDIATE_FORCED: 'Immediate Forced'
}

const PromotionModal = ({parentEnvironment, parentVersion, environment, module, version}) => {
  const [updateType, setUpdateType] = useState(deriveUpdateType(version))
  const [autoUpdate, setAutoUpdate] = useState(version.autoUpdate)
  const [promptUpdate, setPromptUpdate] = useState(version.promptUpdate)
  const [forceUpdate, setForceUpdate] = useState(version.forceUpdate)
  const [isPromoting, setPromoting] = useState(false)
  const [confirmForceUpdate, setConfirmForceUpdate] = useState(false)
  const {pushToast} = useContext(ToastContext)
  const {fetchVersionList} = useContext(VersionListContext);
  const {apiPost} = useContext(BuildApiContext);

  const versionList = useVersionList({
    environment,
    module
  })

  const latestVersion = useMemo(() => {
    return versionList.find(v => v.semver === version.semver)
  }, [versionList, version])

  const hasChanges = useMemo(() => {
    return autoUpdate !== latestVersion.autoUpdate ||
      promptUpdate !== latestVersion.promptUpdate ||
      forceUpdate !== latestVersion.forceUpdate
  }, [latestVersion, autoUpdate, promptUpdate, forceUpdate])

  const openPromoteUrl = useCallback(async (newWindow) => {
    try {
      // target this branch for github release
      const promoteTargetBranch = environment.parent === 'production'
        ? 'production'
        : environment.parent === 'staging'
          ? 'early-access'
          : null;

      // semver tag suffix for github release
      const tagSuffix = environment.parent === 'production'
        ? ''
        : environment.parent === 'staging'
          ? '-ea'
          : '';

      // use repo for github release based on module
      const repo = module === 'electron'
        ? 'medal-electron'
        : 'MedalStudio'

      const tagName = version?.semver
      const title = `${parentEnvironment.label} ${capitalize(module)} ${version?.semver}`
      const bodyHeader = `> Automatic promotion of ${module} **${environment.label} ${version?.semver}** to **${parentEnvironment.label}**\nRelease notes generated based on difference between \`${parentVersion?.semver}${tagSuffix}\` and \`${version?.semver}${tagSuffix}\`\n\n`
      const isPrerelease = environment.parent === 'staging'
      const url = `/github/notes/${repo}/${tagName}${tagSuffix}/${promoteTargetBranch}/${parentVersion?.semver}${tagSuffix}`
      const {data} = await get(url)
      newWindow.location.href = `https://github.com/get-wrecked/${repo}/releases/new?target=${promoteTargetBranch}${isPrerelease ? '&prerelease=true' : ''}&tag=${tagName}${tagSuffix}&title=${encodeURI(title)}&body=${encodeURIComponent(`${bodyHeader}${data?.body?.length < 2000 ? data?.body : ''}`)}`
    } catch (err) {
      pushToast({
        title: 'Error promoting build',
        body: err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
    }
  }, [parentEnvironment, environment, module, version, parentVersion])

  const save = async e => {
    try {
      const body = {
        autoUpdate,
        promptUpdate,
        forceUpdate,
      }
      const resp = await apiPost(`/versions/${environment.value}/${module}/${latestVersion.semver}`, body)
      if (resp.data.find(v => v.semver === latestVersion.semver)) {
        await fetchVersionList({environment, module})
      } else {
        console.warn('DID NOT RE-FETCH VERSIONLIST AFTER SAVE')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onPromote = useCallback(async () => {
    setPromoting(true)
    await save()
    const newWindow = window.open()
    await openPromoteUrl(newWindow)
    setPromoting(false)
  }, [openPromoteUrl, save, latestVersion])

  useEffect(() => {
    setConfirmForceUpdate(false)
  }, [updateType])

  useEffect(() => {
    setAutoUpdate(latestVersion.autoUpdate)
    setPromptUpdate(latestVersion.promptUpdate)
    setForceUpdate(latestVersion.forceUpdate)
  }, [latestVersion])

  return (
    <Modal
      id="promotion-modal"
      size="small"
      header={<Fragment>Promote {module} {version.semver} to {parentEnvironment.label}</Fragment>}
    >
      <SelectionWrapper>
        <UpdateTypeSelection
          updateType={updateType}
          onSelectUpdateType={(option, updateConfig) => {
            setUpdateType(option)
            setAutoUpdate(updateConfig.autoUpdate)
            setPromptUpdate(updateConfig.promptUpdate)
            setForceUpdate(updateConfig.forceUpdate)
          }}
        />
      </SelectionWrapper>
      <Actions>
        <ConfirmForceUpdatePrompt
          isConfirmed={confirmForceUpdate}
          setConfirmed={setConfirmForceUpdate}
          updateType={updateType}
        />
        {
          isPromoting
            ? <Loading><LoadingSpinner size="24px"/></Loading>
            : <Button
              variant={hasChanges ? 'success' : 'secondary'}
              size="large"
              onClick={onPromote}
              disabled={(updateType === UpdateTypes.FORCED || updateType === UpdateTypes.IMMEDIATE_FORCED) && !confirmForceUpdate}
            >
              {hasChanges ? 'Save & Continue to GitHub' : 'Continue to GitHub'}
            </Button>
        }
      </Actions>
    </Modal>
  )
}

PromotionModal.propTypes = propTypes

export default PromotionModal

// only necessary for dropdown to not cause overflow to scroll
const SelectionWrapper = styled.div`
  margin: 8px 0;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-top: 20px;
  gap: 20px;
  border-top: 1px solid ${colors.stroke['0A8']};
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
`
