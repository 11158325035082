import {useMemo} from 'react'
import useVersionList from './useVersionList'
import {Modules} from '@get-wrecked/updates/constants'

const useLatestVersions = (environment) => {
  const electronVersions = useVersionList({environment, module: Modules.ELECTRON})
  const recorderVersions = useVersionList({environment, module: Modules.RECORDER})
  const versions = useMemo(() => {
    return {
      [Modules.ELECTRON]: electronVersions?.[0],
      [Modules.RECORDER]: recorderVersions?.[0]
    }
  }, [electronVersions, recorderVersions])
  return versions
}

export default useLatestVersions
