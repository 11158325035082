import {Fragment, useContext, useEffect, useState, useRef, useMemo} from "react";
import {Button, colors, LoadingSpinner} from '@get-wrecked/simple-components'
import {EnvironmentContext, SelectedActiveBuildContext, ToastContext} from "../../../contexts";
import {get, post} from "../../../utils/builds-api";
import useTimeAgo from "../../../hooks/useTimeAgo";
import ConfirmationDialog from "../../ConfirmationDialog";
import PullRequestIcon from "../../../components/Icons/PullRequestIcon";
import Modal from '../../../components/Modal'
import {showNotification} from '../../../utils/notifications'
import {useNavigate} from 'react-router'
import styled from 'styled-components'
import Badge from '../../../components/Badge'
import GitHubIcon from '../../../components/Icons/GitHubIcon'
import {getUser} from '../../../utils/api'
import Avatar from '../../../components/Avatar'

const toMinsAndSecs = (millis) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

const ActiveBuildModal = () => {
  const {pushToast} = useContext(ToastContext);
  const {selectedActiveBuild, setSelectedActiveBuild} = useContext(SelectedActiveBuildContext);
  const {environments} = useContext(EnvironmentContext);
  const stateHash = useRef(undefined);
  const [loading, setLoading] = useState(true);
  const [buildProcessor, setBuildProcessor] = useState(undefined);
  const [buildLog, setBuildLog] = useState('');
  const [steps, setSteps] = useState([]);
  const timestamp = useMemo(() => {
    return buildProcessor?.state?.end || buildProcessor?.state?.start
  }, [buildProcessor?.state?.end, buildProcessor?.state?.start])
  const startedTimeAgo = useTimeAgo(timestamp);
  const intervalRef = useRef(undefined);
  const [cancelling, setCancelling] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [pullRequests, setPullRequests] = useState([])
  const environment = useMemo(() => {
    return environments.find(env => env.value === selectedActiveBuild?.environment)
  }, [environments, selectedActiveBuild])
  const build = selectedActiveBuild;
  const repository = build.module === 'electron' ? 'medal-electron' : 'MedalStudio'
  const navigate = useNavigate()

  const buildStatus = useMemo(() => {
    return buildProcessor?.state?.canceled === true
      ? 'canceled'
      : buildProcessor?.state?.releaseState
  }, [selectedActiveBuild, buildProcessor])
  const initialBuildStatus = useRef(buildStatus)

  const close = () => {
    setSelectedActiveBuild(undefined);
  }

  const fetchBuildState = async () => {
    try {
      const resp = await get(`/build/${build?.buildId}`);
      if (resp.data.stateHash !== stateHash.current) {
        stateHash.current = resp.data.stateHash;
        setBuildProcessor(resp.data.buildProcessor);
      }
    } catch (err) {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
        intervalRef.current = undefined;
      }
      console.error(err);
    }
  }

  const fetchBuildLog = async () => {
    try {
      const resp = await get(`/build/${build?.buildId}/log`);
      if (resp.data !== buildLog) {
        setBuildLog(resp.data);
      }
    } catch (err) {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
        intervalRef.current = undefined;
      }
      console.error(err);
    }
    if (loading) {
      setLoading(false)
    }
  }

  const fetchPulls = async () => {
    try {
      const resp = await get(`/github/pulls/${build.repository}/${encodeURIComponent(build.branch)}`);
      setPullRequests(resp.data);
    } catch (err) {
      console.error('Error fetching pulls:', err);
    }
  }

  const onCancelBuild = async (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!confirmCancel) {
      setConfirmCancel(true);
      return;
    }
    setCancelling(true);
    try {
      const resp = await post(`/build/${build.buildId}/cancel`)
    } catch (err) {
      pushToast({
        title: 'Error canceling build',
        body: err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
      console.log(err)
    }
    setCancelling(false);
    setConfirmCancel(false)
  }

  const cancelable = buildProcessor?.state?.releaseState !== 'success' &&
    buildProcessor?.state?.canceled === false &&
    buildProcessor.state.releaseState !== 'canceled' &&
    buildProcessor.state.releaseState !== 'failure'

  useEffect(() => {
    fetchBuildState();
  }, [])

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        fetchBuildState()
        fetchBuildLog()
      }, 2000)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = undefined;
      }
    }
  }, [buildProcessor])

  useEffect(() => {
    if (buildLog.length > 0) {
      // split into steps
      setSteps(buildLog.split('-- STEP'))

      // Regular expression to match each section starting with '[info] -- STEP'
      const sectionRegex = /\[info\] -- STEP.*?(?=\[info\] -- STEP|$)/gs

      // Split the string into sections using the regular expression
      const sections = buildLog.match(sectionRegex)

      const processedSections = sections.map((section, index) => {
        // Example processing: extract the step name
        const stepNameMatch = section.match(/\[info\] -- STEP (.*)/)
        const stepName = stepNameMatch ? stepNameMatch[1].trim() : 'Unknown Step'

        return {
          sectionNumber: index + 1,
          stepName: stepName,
          content: section
        }
      })
      setSteps(processedSections)

      const logElement = document.getElementById("build-log");
      if (logElement?.scrollTop !== logElement?.scrollHeight) {
        logElement.scrollTop = logElement.scrollHeight;
      }
    }
  }, [buildLog])

  useEffect(() => {
    if (Object.keys(buildProcessor?.state?.scriptsExecuted || {}).length > 0) {
      const stepsElement = document.getElementById("build-steps");
      if (stepsElement?.scrollTop !== stepsElement?.scrollHeight) {
        stepsElement.scrollTop = stepsElement.scrollHeight;
      }
    }
  }, [buildProcessor?.state?.scriptsExecuted])

  useEffect(() => {
    if (!!build?.branch && !pullRequests?.length && environment?.static === false) {
      fetchPulls()
    }
  }, [build, pullRequests, environment])

  useEffect(() => {
    if (buildStatus === 'started') {
      initialBuildStatus.current = buildStatus
    } else if (initialBuildStatus.current && buildStatus !== initialBuildStatus.current) {
      showNotification({
        title: `${environment?.label ?? '(Unknown Environment)'} ${build.module} ${buildStatus}`,
        body: `Click here to view build logs`,
        onClick: () => {
          navigate(`/build/${build.buildId}`)
        }
      })
    }
  }, [buildStatus])

  console.log(build)

  return (
    <Fragment>
      <Modal
        id={'active-build-modal'}
        size="fullscreen"
        onClose={close}
        exitOnClick={false}
        bodyPadding="16px"
        header={
          <Header>
            <Label style={{marginBottom: '8px'}}>
              <BuildOwner owner={build?.owner}/>
              {environment?.label ?? '(Unknown Environment)'}: {build.module === 'electron' ? 'Electron' : 'Recorder'} {build.version}
            </Label>
            <Actions>
              <a target="_blank" href={`https://github.com/get-wrecked/${repository}/tree/${build.branch}`}>
                <Button variant="tertiary" size="xsmall" onClick={() => {}}>
                  <GitHubIcon
                    size={14}
                    color="white"
                  />
                  {build.branch}
                </Button>
              </a>
              {
                pullRequests?.length > 0 &&
                <a target="_blank" href={pullRequests[0].html_url}>
                  <Button variant="tertiary" size="xsmall" onClick={() => {}}>
                    <PullRequestIcon
                      pullRequest={pullRequests[0]}
                      size={14}
                    />
                    #{pullRequests[0].number}
                  </Button>
                </a>
              }
              <Badge type={buildStatus === 'started' ? 'brand' : (buildStatus === 'success' ? 'success' : (buildStatus === 'canceled' ? 'gray' : 'warning'))}>
                {buildStatus?.toUpperCase?.()}
              </Badge>
              {
                cancelable &&
                <Button
                  variant="danger"
                  size="xsmall"
                  onClick={onCancelBuild}
                >
                  Cancel
                </Button>
              }
              <LoadingIndicator>
                {
                  cancelable &&
                  <LoadingSpinner size="10px"/>
                }
                {startedTimeAgo}
              </LoadingIndicator>
              {
                !!buildProcessor?.state?.end &&
                <span>Duration: {toMinsAndSecs(buildProcessor?.state.end - buildProcessor?.state.start)}</span>
              }
            </Actions>
          </Header>
        }
      >
        <Body>
          {
            loading &&
            <LoadingSpinner style={{marginBottom: '8px'}}/>
          }
          <Steps>
            {
              !loading &&
              steps.map((step, index) => {
                return (
                  <StepBox
                    key={step.stepName}
                    status={(buildStatus !== 'failure' || index < steps.length - 1) ? ((index < steps.length - 1 || buildStatus === 'success') ? 'done' : (buildStatus === 'canceled' ? 'canceled' : 'active')) : 'error'}
                    expanded={index === steps.length - 1 && buildStatus !== 'success'}
                    {...step}
                  />
                )
              })
            }
          </Steps>
          {/*{*/}
          {/*  !loading && steps.length === 0 && buildLog?.length > 0 &&*/}
          {/*  <code id="build-log" className="snippet scrollable both" style={{minHeight: '300px', maxHeight: '77vh', resize: 'vertical'}}>*/}
          {/*    {buildLog.split('\n').map((line, index) => <Fragment key={`line-${index}`}>{line}<br/></Fragment>)}*/}
          {/*  </code>*/}
          {/*}*/}
          {/*{*/}
          {/*  buildProcessor?.state?.filesSigned?.preInstall?.length > 0 &&*/}
          {/*  <Fragment>*/}
          {/*    <div className="table scrollable y" style={{maxHeight: '200px'}}>*/}
          {/*      <h4 className="table-row">Files Signed (pre-install)</h4>*/}
          {/*      {buildProcessor.state.filesSigned.preInstall.map((file, index) => {*/}
          {/*        return (*/}
          {/*          <div className="table-row" key={`signed-pre-${index}`}>*/}
          {/*            <div className="table-item">*/}
          {/*              📁 {file}*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </Fragment>*/}
          {/*}*/}
          {/*{*/}
          {/*  buildProcessor?.state?.filesSigned?.postBuild?.length > 0 &&*/}
          {/*  <Fragment>*/}
          {/*    <div className="table scrollable y" style={{maxHeight: '200px'}}>*/}
          {/*      <h4 className="table-row">Files Signed (post-build)</h4>*/}
          {/*      {buildProcessor.state.filesSigned.postBuild.map((file, index) => {*/}
          {/*        return (*/}
          {/*          <div className="table-row" key={`signed-post-${index}`}>*/}
          {/*            <div className="table-item">*/}
          {/*              📁 {file}*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </Fragment>*/}
          {/*}*/}
          {/*{*/}
          {/*  buildProcessor?.state?.releasePhases?.length > 0 &&*/}
          {/*  <Fragment>*/}
          {/*    <div className="table scrollable y">*/}
          {/*      <h4 className="table-row">Release Phases Executed</h4>*/}
          {/*      {buildProcessor?.state?.releasePhases.map((phase, index) => {*/}
          {/*        return (*/}
          {/*          <div className="table-row" key={`release-phase-${index}`}>*/}
          {/*            <div className="table-item release-phase" key={`phase-${phase}-${index}`}>*/}
          {/*              <span>✓</span> {phase.replace(/([a-z])([A-Z])/g, '$1 $2')}*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </Fragment>*/}
          {/*}*/}
        </Body>
      </Modal>
      {
        confirmCancel &&
        <ConfirmationDialog
          modalId={'confirm-cancel-build'}
          title={`Confirm Build Cancellation`}
          description={`Are you sure you want to cancel this ${build.module} build?`}
          onConfirm={onCancelBuild}
          onCancel={() => setConfirmCancel(false)}
        />
      }
    </Fragment>
  )
}

export default ActiveBuildModal;

const BuildOwner = ({owner}) => {
  const [ownerUser, setOwnerUser] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (!owner) {
        return
      }
      const user = await getUser(owner)
      setOwnerUser(user.data)
    }
    load()
  }, [owner])

  if (!ownerUser?.userId) {
    return ''
  }

  return (
    <Avatar image={ownerUser.thumbnail}/>
  )
}

const StepIcon = ({color = 'white', size = 24}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.94 13.27C9.26 14.29 8.32 15.12 7.22 15.67C7.12 15.72 7 15.75 6.89 15.75C6.61 15.75 6.35 15.6 6.22 15.34C6.03 14.97 6.18 14.52 6.56 14.33C7.43 13.9 8.17 13.24 8.7 12.44C8.88 12.17 8.88 11.83 8.7 11.56C8.16 10.76 7.42 10.1 6.56 9.67C6.18 9.49 6.03 9.04 6.22 8.66C6.4 8.29 6.85 8.14 7.22 8.33C8.32 8.88 9.26 9.71 9.94 10.73C10.46 11.5 10.46 12.5 9.94 13.27ZM17 15.75H13C12.59 15.75 12.25 15.41 12.25 15C12.25 14.59 12.59 14.25 13 14.25H17C17.41 14.25 17.75 14.59 17.75 15C17.75 15.41 17.41 15.75 17 15.75Z"
        fill={color}/>
    </svg>
  )
}

const StepBox = ({status, stepName, content, expanded}) => {
  const [isExpanded, setExpanded] = useState(expanded ?? true)
  const logsRef = useRef()

  const color = useMemo(() => {
    if (status === 'done') {
      return colors.success['50']
    } else if (status === 'error') {
      return colors.error['50']
    } else if (status === 'active') {
      return colors.brand.primary['50']
    }
    return colors.text['30']
  }, [status])

  const scrollToBottom = () => {
    const logElement = logsRef.current
    if (logElement?.scrollTop !== logElement?.scrollHeight) {
      logElement.scrollTop = logElement.scrollHeight;
    }
  }

  const lines = useMemo(() => {
    const lines = content
      .trim()
      .split('\n')

    return lines
      .slice(1, lines.length)
      .map((line, index) => <Fragment key={`line-${index}`}>{line}<br/></Fragment>)
  }, [content])

  useEffect(() => {
    setExpanded(expanded)
  }, [expanded])

  useEffect(() => {
    if (isExpanded) {
      scrollToBottom()
    }
  }, [isExpanded, content])

  if (lines.length === 0) {
    return ''
  }

  return (
    <Step>
      <StepHeader
        $expanded={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      >
        <StepIcon
          color={color}
          size={20}
        />
        {stepName}
        {
          status === 'active' &&
          <LoadingSpinner size="18px"/>
        }
      </StepHeader>
      {
        isExpanded && content?.length > 0 &&
        <Code
          ref={logsRef}
          className="scrollable both"
        >
          {lines}
        </Code>
      }
    </Step>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  padding: 16px 0;
  margin-left: -40px;
`

const Label = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  color: white;
  font-size: 16px;
  text-align: left;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 13px;
  color: ${colors.text['30']};
`

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
`

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${colors.neutral['0A4']};
  border: 1px solid ${colors.stroke['0A8']};
  overflow: hidden;
`

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  gap: 10px;
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.stroke['0A8']};
  }
`

const Code = styled.code`
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.65);
  color: rgba(255, 255, 255, 0.75);
  line-height: 1.5;
  font-size: 12px;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: scroll;
  padding: 16px;
  resize: vertical;
  border-radius: 0;
  border: none;
  max-height: min(420px, 70vh);
`
