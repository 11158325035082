import styled, { keyframes, css } from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

const loadingPlaceholderAnimation = keyframes`
  0% { background-position: -650px 0; }
  100% { background-position: 650px 0; }
`

export const LoadingPlaceholder = css`
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${loadingPlaceholderAnimation};
  background: linear-gradient(to right, ${({$backgroundColor}) => $backgroundColor || colors.neutral['0A4']} 2%, ${({$loadingOverlayColor}) => $loadingOverlayColor || colors.stroke['0A8']} 18%, ${({$backgroundColor}) => $backgroundColor || colors.neutral['0A4']} 33%);
  background-size: ${({$loadingSpace}) => $loadingSpace ?? '1300px'}; // Animation Area
`

export const Divider = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: ${({$color}) => $color ?? colors.stroke['0A8']};
  margin: ${({$margin}) => $margin ?? '20px'} 0;
`

export const WarningText = styled.span`
  color: ${colors.error['10']};
`

export const SuccessText = styled.span`
  color: ${colors.success['10']};
`

export const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: ${colors.text['30']};
  font-size: 12px;
  font-weight: 500;
`
