import {useContext, useState} from 'react'
import styled from 'styled-components'
import {colors, InputField, Tooltip} from '@get-wrecked/simple-components'
import {ChangelogsContext, ModalContext} from '../../../contexts'
import Modal from '../../../components/Modal'
import SavePrompt from '../../../components/SavePrompt'

const ChangelogVideoModal = ({id}) => {
  const {closeModal} = useContext(ModalContext)
  const {getChangelog, updateChangelog} = useContext(ChangelogsContext)
  const changelog = getChangelog(id)
  const [url, setUrl] = useState(changelog.video?.url ?? null) // https://cdn.medal.tv/assets/video/Go%20Live%202.mp4
  const [poster, setPoster] = useState(changelog.video?.poster ?? null) // https://cdn.medal.tv/assets/img/may-23-release/go-live-thumbnail.png
  const [saving, setSaving] = useState(false)
  const hasChanges = url !== changelog.video?.url || poster !== changelog.video?.poster

  const onSave = async () => {
    setSaving(true)
    try {
      await updateChangelog(id, {
        video: {
          url: url ?? null,
          poster: poster ?? null
        }
      })
    } catch (err) {
      console.error(err)
    }
    setSaving(false)
    closeModal('add-changelog-video')
  }

  return (
    <Modal
      id={'add-changelog-video'}
      size={"medium"}
      header="Add Cover Video"
    >
      <Body>
        <Input>
          <Tooltip
            tooltip="Embed an .mp4 at the top of the changelog"
            position="bottom"
          >
            <Icon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.36 7.78125H2V11.2512H6.36V7.78125Z" fill="white"/>
                <path d="M6.35844 6.28891V2.12891C4.07844 2.54891 2.58844 4.01891 2.14844 6.27891H6.32844C6.33844 6.27891 6.34844 6.28891 6.35844 6.28891Z" fill="white"/>
                <path d="M6.36 16.31V12.75H2V16.28H6.24C6.28 16.28 6.32 16.3 6.36 16.31Z" fill="white"/>
                <path d="M21.8494 6.27969C21.4194 4.08969 20.0194 2.64969 17.8594 2.17969V6.27969H21.8494Z" fill="white"/>
                <path d="M16.3594 11.25V2.01C16.3094 2 16.2494 2 16.1894 2H7.85938V11.25H16.3594Z" fill="white"/>
                <path d="M21.9994 12.75H17.8594V16.28H21.9994V12.75Z" fill="white"/>
                <path d="M17.8594 21.8212C19.9994 21.3512 21.3994 19.9313 21.8394 17.7812H17.8594V21.8212Z" fill="white"/>
                <path d="M6.24016 17.7817H2.16016C2.62016 20.0017 4.10016 21.4517 6.36016 21.8717V17.7617C6.32016 17.7717 6.28016 17.7817 6.24016 17.7817Z" fill="white"/>
                <path d="M21.9994 7.78125H17.8594V11.2512H21.9994V7.78125Z" fill="white"/>
                <path d="M7.85938 12.75V22H16.1894C16.2494 22 16.3094 22 16.3594 21.99V12.75H7.85938Z" fill="white"/>
              </svg>
            </Icon>
          </Tooltip>
          <InputField
            value={url ?? ''}
            placeholder={"Add a video URL"}
            onChange={e => setUrl(e.target.value)}
            style={{
              flex: '1'
            }}
          />
        </Input>
        <Input>
          <Tooltip
            tooltip="Customize the thumbnail preview shown before the video plays"
            position="bottom"
          >
            <Icon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.58078 19.0112L2.56078 19.0312C2.29078 18.4413 2.12078 17.7713 2.05078 17.0312C2.12078 17.7613 2.31078 18.4212 2.58078 19.0112Z"
                  fill="white"/>
                <path
                  d="M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z"
                  fill="white"/>
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z"
                  fill="white"/>
              </svg>
            </Icon>
          </Tooltip>
          <InputField
            value={poster ?? ''}
            placeholder={"Add a thumbnail image URL"}
            onChange={e => setPoster(e.target.value)}
            style={{
              flex: '1'
            }}
          />
        </Input>
        {
          url &&
          <ChangelogVideo
            src={url}
            poster={poster}
            controls={true}
          />
        }
      </Body>
      <SavePrompt
        visible={hasChanges}
        saving={saving}
        description={"Are you sure? Changes will take effect immediately."}
        onButtonClick={onSave}
      />
    </Modal>
  )
}

export default ChangelogVideoModal

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Input = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;
  
  svg {
    flex-shrink: 0;
  }
`

const Icon = styled.div`
  flex-shrink: 0;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background-color: ${colors.stroke['0A8']};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChangelogVideo = styled.video`
  border-radius: 8px;
`
