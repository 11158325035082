import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import {colors} from '@get-wrecked/simple-components'

const propTypes = {
  type: PropTypes.oneOf([
    'regular',
    'brand',
    'success',
    'error',
  ]).isRequired,
  style: PropTypes.object
}

const CountdownBadge = ({type, label, children, style, onClick}) => {
  let Component
  switch (type) {
    case 'regular':
      Component = Regular
      break
    case 'brand':
      Component = Brand
      break
    case 'success':
      Component = Success
      break
    case 'error':
      Component = Error
      break
  }
  return (
    <Component
      style={style}
      onClick={onClick}
      $hasChildren={!!children}
    >
      {label}
      {
        children &&
        <InnerContainer>
          {children}
        </InnerContainer>
      }
    </Component>
  )
}

CountdownBadge.propTypes = propTypes

export default CountdownBadge

const ContainerCss = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 11px;
  font-weight: 500;
`

const Container = styled.div`
  padding-inline-start: 6px;
  padding-inline-end: ${({$hasChildren}) => $hasChildren ? '0' : '6px'};
  border-radius: 5px;
  height: 22px;
  cursor: ${({onClick}) => typeof onClick === 'function' ? 'pointer' : 'normal'};
  ${ContainerCss}
  
  #arrow {
    opacity: 0;
    max-width: 0;
    overflow: hidden;
    transition: max-width 100ms ease, opacity 100ms ease;
  }
  
  &:hover {
    #arrow {
      opacity: 1;
      max-width: 16px;
    }

    #pointer {
      path {
        fill: white;
      }
    }
  }
`

const InnerContainer = styled.div`
  margin-inline-start: 6px;
  background-color: ${colors.background['third-layer']};
  border-radius: 4px;
  padding: 0 5px;
  height: 100%;
  ${ContainerCss}
`

const Regular = styled(Container)`
  border: 1px solid ${colors.stroke['0A8']};
  box-sizing: border-box;
  background-color: ${colors.stroke['0A16']};
  color: ${colors.text['30']};
`

const Brand = styled(Container)`
  background-color: ${colors.brand.primary['90']};
  border: 1px solid ${colors.brand.primary['90']};
  color: ${colors.brand.primary['10']};
  
  &:hover {
    background-color: ${colors.brand.primary['80']};
    border: 1px solid ${colors.brand.primary['80']};
    color: white;
    
    #icon {
      path {
        fill: white;
      }
    }
  }
`

const Success = styled(Container)`
  background-color: ${colors.success['70']};
  border: 1px solid ${colors.success['70']};
  color: ${colors.success['10']};

  &:hover {
    background-color: ${colors.success['60']};
    border: 1px solid ${colors.success['60']};
    color: white;

    #icon {
      path {
        fill: white;
      }
    }
  }
`

const Error = styled(Container)`
  background-color: ${colors.error['70']};
  border: 1px solid ${colors.error['70']};
  color: white;
`
