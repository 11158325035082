import PropTypes from 'prop-types'
import styled from 'styled-components'
import {colors, ContextMenu} from '@get-wrecked/simple-components'
import {WarningText} from '../styles'
import Avatar from '../Avatar'
import {useNavigate} from 'react-router'
import {getNotifier} from '../Icons/PushNotificationIcon'

const propTypes = {
  user: PropTypes.object.isRequired
}

const AvatarMenu = ({user, logout}) => {
  const navigate = useNavigate()
  const configuredProfile = !!user.githubUsername && !!user.slackMemberId
  return (
    <ContextMenu
      triggerId="user-avatar-container"
      position="bottom"
      alignment="right"
      items={[
        {
          label: <a href={`https://medal.tv/u/${encodeURI(user.userName)}`} target="_blank">Profile</a>
        },
        {
          label: configuredProfile ? 'Settings' : <NotifySettings>
            Settings
            <div style={{display: 'flex', position: 'absolute', left: -16, width: 8, height: 8, pointerEvents: 'none'}}>
              {getNotifier({notifyColor: colors.error['50'], size: 8, border: 'none'})}
            </div>
          </NotifySettings>,
          onClick: e => {
            navigate('/settings')
          }
        },
        {
          divider: true
        },
        {
          label: <WarningText>Logout</WarningText>,
          onClick: e => {
            logout()
          }
        }
      ]}
    >
      <Avatar
        id="user-avatar-container"
        image={user.thumbnail}
        clickable={true}
        notifyColor={!configuredProfile ? colors.error['50'] : undefined}
      />
    </ContextMenu>
  )
}

AvatarMenu.propTypes = propTypes

export default AvatarMenu

const NotifySettings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: relative;
`
