
const ErrorCrossIcon = ({size, color = '#ff5050'}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.5" cy="4.5" r="3.5" fill="#000"/>
      <path d="M7.68241 1.32064C6.79093 0.430638 5.60896 -0.0193614 4.43702 0.000638587C3.30514 0.0106386 2.17325 0.460638 1.31182 1.32064C0.710823 1.91064 0.320175 2.65064 0.129859 3.42064C0.0196755 3.84064 -0.020391 4.27064 0.00965892 4.70064V4.75064C0.00965892 4.82064 0.0196751 4.88064 0.0296917 4.96064C0.0296917 4.96064 0.0296919 4.96064 0.0397086 4.97064V5.00064C0.129858 5.98064 0.550557 6.93064 1.31182 7.68064C2.47375 8.84064 4.10647 9.23064 5.57891 8.86064C6.01965 8.75064 6.45036 8.57064 6.85103 8.33064C7.15153 8.16064 7.43199 7.94064 7.68241 7.68064C8.43366 6.93064 8.86437 5.98064 8.95452 4.99064C8.96454 4.99064 8.96454 4.97064 8.96454 4.96064C8.98457 4.89064 8.98457 4.81064 8.98457 4.74064C8.98457 4.73064 8.99459 4.71064 8.99459 4.69064C9.05469 3.48064 8.61396 2.24064 7.68241 1.32064ZM6.22999 6.21064C5.93951 6.50064 5.46873 6.50064 5.16823 6.21064L4.50713 5.55064L3.826 6.23064C3.5255 6.53064 3.05472 6.53064 2.76424 6.23064C2.46374 5.94064 2.46374 5.47064 2.76424 5.17064L3.44537 4.49064L2.78427 3.83064C2.49379 3.53064 2.49379 3.06064 2.78427 2.77064C3.08477 2.47064 3.55555 2.47064 3.85605 2.77064L4.50713 3.43064L5.13818 2.79064C5.43868 2.50064 5.90946 2.50064 6.20996 2.79064C6.50044 3.09064 6.50044 3.56064 6.20996 3.86064L5.5689 4.49064L6.22999 5.14064C6.53049 5.44064 6.53049 5.91064 6.22999 6.21064Z" fill={color}/>
    </svg>
  )
}

export default ErrorCrossIcon
