import ConfirmationDialog from '../../ConfirmationDialog'
import {useContext} from 'react'
import {ChangelogsContext, ToastContext} from '../../../contexts'

const DeleteChangelogModal = ({id, title}) => {
  const {deleteChangelog} = useContext(ChangelogsContext)
  const {pushToast} = useContext(ToastContext)

  const onDelete = async () => {
    try {
      console.log('attempting to delete:', id)
      await deleteChangelog(id)
    } catch (err) {
      if (err.code === 'permission-denied') {
        pushToast({
          title: 'Missing changelog permissions',
          body: 'Ask @Josh on Slack for delete access',
          type: 'error'
        })
      } else {
        pushToast({
          title: 'Unexpected changelog deletion error',
          body: err.message,
          type: 'error'
        })
        console.error(err)
      }
    }
  }

  return (
    <ConfirmationDialog
      modalId="delete-changelog"
      title="Are you sure?"
      description={<span>Please confirm that you wish to delete the changelog: <strong>{title}</strong></span>}
      confirmLabel="Delete"
      confirmType="danger"
      cancelLabel="Nevermind"
      onConfirm={onDelete}
    />
  )
}

export default DeleteChangelogModal
