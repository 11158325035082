import styled from 'styled-components'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {colors, LoadingSpinner, Tooltip} from '@get-wrecked/simple-components'
import ApplicationIcon from '../Icons/ApplicationIcon'
import RecordIcon from '../Icons/RecordIcon'
import {ToastContext} from '../../contexts'
import useTimeAgo from '../../hooks/useTimeAgo'
import {LoadingPlaceholder} from '../styles'
import Badge from '../Badge'
import {Button} from '@get-wrecked/simple-components'
import {get, post} from '../../utils/builds-api'
import ConfirmationDialog from '../../presentations/ConfirmationDialog'
import semver from 'semver'

const propTypes = {
  id: PropTypes.string,
  environment: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  version: PropTypes.object,
  activeBuild: PropTypes.object,
  parent: PropTypes.object
}

const HighlightModule = ({id, environment, module, activeBuild}) => {
  const navigate = useNavigate()
  const {pushToast} = useContext(ToastContext)

  // local states
  const activeBuildFlagRef = useRef(false)
  const [cancelling, setCancelling] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const [latestVersion, setLatestVersion] = useState()

  const releaseDate = new Date(latestVersion?.releaseTimestamp ?? latestVersion?.timestamp)
  const timeAgo = useTimeAgo(releaseDate.getTime())
  const activeBuildTimeAgo = useTimeAgo(activeBuild?.timestamp)

  const onCancelBuild = async (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!confirmCancel) {
      setConfirmCancel(true);
      return;
    }
    setCancelling(true);
    try {
      const resp = await post(`/build/${activeBuild.buildId}/cancel`)
    } catch (err) {
      pushToast({
        title: 'Error canceling build',
        body: err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
      console.log(err)
    }
    setCancelling(false);
    setConfirmCancel(false)
  }

  const fetchVersion = async () => {
    try {
      const resp = await get('/highlight/version')
      const latest = resp.data
      setLatestVersion(latest)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchVersion()
  }, [])

  useEffect(() => {
    if (activeBuild) {
      activeBuildFlagRef.current = true
    }
    if (!activeBuild && activeBuildFlagRef.current) {
      activeBuildFlagRef.current = false
      fetchVersion()
    }
  }, [activeBuild])

  return (
    <Fragment>
      <Container>
        <Header>
          <Label>
            {
              module === 'electron'
                ? <ApplicationIcon size={16}/>
                : <RecordIcon size={16}/>
            }
            {module}
          </Label>
        </Header>
        <Contents>
          <VersionContainer
            $visible={true}
            $isLoading={!latestVersion}
          >
            <VersionMetadata>
              <VersionRow>
                {latestVersion?.semver}
                {
                  latestVersion &&
                  <Badge type="success">
                    Live
                  </Badge>
                }
              </VersionRow>
              <Tooltip
                id={`timestamp-${environment.value}-${module}-${latestVersion?.semver}`}
                tooltip={`${releaseDate.toDateString()} ${releaseDate.toTimeString()}`}
                position="bottom"
              >
                <Timestamp>
                  {timeAgo}
                </Timestamp>
              </Tooltip>
            </VersionMetadata>
          </VersionContainer>

          <ActiveBuildContainer
            $visible={!!activeBuild}
            $isActive={true}
            onClick={() => navigate(`build/${activeBuild?.buildId}`)}
          >
            <VersionMetadata>
              {activeBuild?.version}
              <Timestamp>
                {activeBuildTimeAgo}
              </Timestamp>
            </VersionMetadata>
            {
              activeBuild &&
              <ActiveBuildActions>
                <LoadingSpinner size="18px"/>
                <Button
                  variant="tertiary"
                  size="xsmall"
                  onClick={onCancelBuild}
                >
                  Cancel
                </Button>
              </ActiveBuildActions>
            }
          </ActiveBuildContainer>
        </Contents>
      </Container>
      {
        confirmCancel &&
        <ConfirmationDialog
          modalId={'confirm-cancel-build'}
          title={`Confirm Build Cancellation`}
          description={`Are you sure you want to cancel this ${activeBuild.module} build?`}
          onConfirm={onCancelBuild}
          onCancel={() => setConfirmCancel(false)}
        />
      }
    </Fragment>
  )
}

HighlightModule.propTypes = propTypes

export default HighlightModule

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
`

const Container = styled(ModuleContainer)`
  background-color: ${colors.background['third-layer']};
  border: 1px solid ${colors.neutral['0A4']};
  box-sizing: border-box;
  border-radius: 6px;
  height: fit-content;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding-left: 8px;
  padding-right: 4px;
  height: 36px;
  gap: 8px;
  border-bottom: 1px solid ${colors.neutral['0A4']};
  box-sizing: border-box;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: auto;
  gap: 4px;
  text-transform: capitalize;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
`

const VersionContainer = styled.div`
  width: 100%;
  background-color: ${colors.neutral['0A4']};
  border-radius: 4px;
  box-sizing: border-box;
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  ${({$isNew}) => $isNew ? `background-image: linear-gradient(-90deg, rgba(1, 210, 142, 0.08) 0%, rgba(1, 210, 142, 0) 100%);` : ''}
  ${({$isChild}) => $isChild ? `background-image: linear-gradient(90deg, rgba(255, 184, 75, 0.08) 0%, rgba(255, 184, 75, 0) 100%);` : ''}

  border: ${({$visible}) => $visible ? `1px solid ${colors.stroke['0A8']}` : 'none'};
  pointer-events: ${({$visible}) => $visible ? 'initial' : 'none'};
  overflow: ${({$visible}) => $visible ? 'initial' : 'hidden'};
  margin-top: ${({$visible, $isChild, $isActive}) => $visible ? ($isChild || $isActive ? '8px' : '') : '0'};
  max-height: ${({$visible}) => $visible ? '46px' : '0'};
  padding: ${({$visible}) => $visible ? '4px 6px' : '0'};
  opacity: ${({$visible}) => $visible ? '1' : '0'};
  transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out, padding 200ms ease-in-out, margin-top 200ms ease-in-out;

  ${({$isLoading}) => $isLoading ? LoadingPlaceholder : ''}
`

const ActiveBuildContainer = styled(VersionContainer)`
  border-style: dashed;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(255, 184, 75, 0.04) 0%, rgba(255, 184, 75, 0) 100%);
  cursor: ${({$visible}) => $visible ? 'pointer' : 'default'};
  border-width: ${({$visible}) => $visible ? '1px' : '0px'};
  
  &:hover {
    border-style: solid;
    border-color: ${colors.stroke['0A16']};
    background-color: ${colors.neutral['0A4']};
  }
`

const VersionMetadata = styled.div`
  display: flex;
  flex-direction: column;
`

const Timestamp = styled.span`
  font-size: 12px;
  color: ${colors.text['70']};
  font-weight: normal;
`

const Actions = styled.div`
  display: flex;
  gap: 1px;
  justify-content: flex-end;
  align-items: center;
  margin-inline-start: auto;
`

const ActiveBuildActions = styled(Actions)`
  align-items: center;
  gap: 8px;
`

const VersionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
