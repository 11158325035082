import {useMemo} from 'react'
import styled from 'styled-components'
import {colors, ContextMenu} from '@get-wrecked/simple-components'

const HelpButton = () => {
  const menuItems = useMemo(() => {
    return [
      {
        label: <a href="https://www.notion.so/Desktop-Release-Process-3b4e8d5833b54041b8a75421e118240a" target="_blank">
          <ItemPrefix>Docs:</ItemPrefix> Release Process
        </a>,
      },
      {
        divider: true
      },
      {
        label: <a href="https://www.notion.so/medaltv/Electron-Upgrades-Updates-364c9f42024c44e68dafd3c0b8c7c5fe" target="_blank">
          <ItemPrefix>Docs:</ItemPrefix> Electron Upgrades & Updates
        </a>,
      },
      // {
      //   label: <span><ItemPrefix>Tutorial:</ItemPrefix> Request QA</span>
      // },
      // {
      //   label: <span><ItemPrefix>Tutorial:</ItemPrefix> Release Candidates</span>
      // }
    ]
  }, [])

  return (
    <ContextMenu
      triggerId="help-button"
      position="top"
      alignment="right"
      items={menuItems}
      wrapperStyle={menuWrapperStyle}
    >
      <Button $size="40px" id="help-button">
        ?
      </Button>
    </ContextMenu>
  )
}

export default HelpButton

const menuWrapperStyle = {
  position: 'absolute',
  bottom: '20px',
  right: '20px'
}


const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ $size, $pill }) => $pill ? 'fit-content' : $size};
  max-width: ${({ $size, $pill }) => $pill ? 'fit-content' : $size};
  height: ${({ $size }) => $size};
  max-height: ${({ $size }) => $size};
  border-radius: ${({$size}) => parseInt($size.replace('px', ''), 10) / 2}px;
  cursor: pointer;
  white-space: nowrap;

  background-color: ${colors.background['third-layer']};
  opacity: 1;
  border: 1px solid ${colors.stroke['0A8']};
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.64);
  width: ${({$size}) => $size ?? '36px'};
  height: ${({$size}) => $size ?? '36px'};
  
  ${({$pill}) => $pill ? `padding: 0 6px; gap: 5px;` : ''}

  &:hover {
    opacity: 1;
    background-color: ${colors.text['70']};
    border: 1px solid ${colors.neutral['0A24']};
  }
`

const ItemPrefix = styled.span`
  font-weight: bold;
  color: white;
`
