import styled from 'styled-components'
import {useNavigate} from 'react-router'
import {Environments, Modules} from '@get-wrecked/updates/constants'
import semver from 'semver'
import {Fragment, useContext, useMemo} from 'react'
import {colors, CircleButton, ContextMenu, Tooltip} from '@get-wrecked/simple-components'
import {earlyAccessColor} from '../colors'
import EnvironmentIcon from '../Icons/EnvironmentIcon'
import OverflowIcon from '../Icons/OverflowIcon'
import VersionsIcon from '../Icons/VersionsIcon'
import Module from '../Module'
import ModulePlaceholder from '../Module/placeholder'
import PencilIcon from '../Icons/PencilIcon'
import StorageIcon from '../Icons/StorageIcon'
import {deriveActiveBuilds} from '../../utils/builds-api'
import {BuildApiContext, ReleasesContext} from '../../contexts'
import useLatestVersions from '../../hooks/useLatestVersions'
import ReleaseStatus from '../Badge/releaseStatus'
import CountdownBadge from '../Badge/countdown'
import useCountdown from '../../hooks/useCountdown'
import Badge from '../Badge'
import LinearIcon from '../Icons/LinearIcon'
import HighlightModule from '../Module/HighlightModule'

const getEnvironmentColor = env => {
  let iconColor
  if (env.static) {
    iconColor = env.public
      ? (env.value === Environments.PRODUCTION ? colors.success['50'] : earlyAccessColor)
      : colors.brand.primary['70'] // development
  } else {
    if (env === 'production') {
      iconColor = colors.success['50']
    } else if (env === 'staging') {
      iconColor = colors.brand.primary['70']
    } else {
      iconColor = colors.brand.secondary['30'] // dynamic
    }
  }
  return iconColor
}

export const ColoredEnvironmentIcon = ({size, environment}) => {
  const color = useMemo(() => {
    return getEnvironmentColor(environment)
  }, [environment])

  return (
    <EnvironmentIcon
      size={size}
      color={color}
    />
  )
}

const Environment = ({environment, child}) => {
  const navigate = useNavigate()
  const {buildApiState} = useContext(BuildApiContext)

  const latestVersions = useLatestVersions(environment)
  const latestChildVersions = useLatestVersions(child)

  const candidates = useMemo(() => {
    if (!child) {
      return {}
    }
    if (!latestVersions?.electron || !latestVersions?.recorder || !latestChildVersions?.electron || !latestChildVersions?.recorder) {
      return {}
    }

    const candidates = {}
    if (semver.gt(latestChildVersions.electron.semver, latestVersions.electron.semver)) {
      candidates.electron = latestChildVersions.electron.semver
    }
    if (semver.gt(latestChildVersions.recorder.semver, latestVersions.recorder.semver)) {
      candidates.recorder = latestChildVersions.recorder.semver
    }
    return candidates
  }, [child, latestVersions, latestChildVersions])

  const hasCandidate = useMemo(() => {
    return !!candidates.electron || !!candidates.recorder
  }, [candidates])

  const {releaseSchedule} = useContext(ReleasesContext)
  const {countdownLabel, timeLeft} = useCountdown({
    targetDay: releaseSchedule?.[child?.value]?.day,
    targetTime: releaseSchedule?.[child?.value]?.hour
  })

  const activeBuilds = useMemo(() => {
    if (environment.value === Environments.HIGHLIGHT) {
      return deriveActiveBuilds(buildApiState, {environment: environment.value, module: 'highlight'})
        .concat(deriveActiveBuilds(buildApiState, {environment: environment.value, module: 'recorder'}))
    }
    if (environment.public && environment.static && child) {
      return deriveActiveBuilds(buildApiState, {environment: child.value})
    }
    return deriveActiveBuilds(buildApiState, {environment: environment.value})
  }, [environment, buildApiState])

  const overflowMenuItems = useMemo(() => {
    return [
      {
        label: <Fragment><PencilIcon size="18" color={colors.text['30']}/> Edit environment</Fragment>,
        onClick: () => {
          navigate(`/environment/${environment.value}`)
        }
      },
      {
        label: <a target="_blank"
                  href={`https://console.cloud.google.com/storage/browser/assets.medal.tv/${environment.target || environment.value}`}>
          <StorageIcon size="18" color={colors.text['30']}/>
          Open storage bucket
        </a>
      },
      {
        label: <Fragment><VersionsIcon size="18" color={colors.text['30']}/> Version history</Fragment>,
        onClick: () => navigate(`/history/${environment.value}`)
      }
    ]
  }, [environment])

  const sortedModules = useMemo(() => {
    return environment.modules.sort()
  }, [environment])

  const linearTickets = useMemo(() => {
    // match both "XYZ 1234" and "XYZ-1234"
    const regex = /(\b[A-Za-z]{3}\b)[ -](\d+)/g;
    let matches;
    let tickets = [];
    while ((matches = regex.exec(environment.label)) !== null) {
      const code = matches[1].toUpperCase();
      const number = matches[2];
      const url = `https://linear.app/medal/issue/${code}-${number}`;
      tickets.push({
        id: `${code}-${number}`,
        url
      });
    }
    return tickets
  }, [environment])

  return (
    <Container>
      <Header>
        <LabelAndBadge>
          <ColoredEnvironmentIcon
            size={20}
            environment={environment}
          />
          <Label>{environment.label}</Label>
          {
            environment.userIds?.length > 0 &&
            <Badge type="gray">
              {environment.userIds.length} users added
            </Badge>
          }
          {
            releaseSchedule?.[child?.value] &&
            <Fragment>
              {
                hasCandidate
                  ? <ReleaseStatus
                    environment={environment}
                    versions={candidates}
                    child={child}
                  />
                  : (releaseSchedule?.[child?.value] &&
                    <Tooltip
                      tooltip={
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <span>{environment.label} release is {releaseSchedule[child.value].day} at {releaseSchedule[child.value].hour} ET</span>
                        </div>
                      }
                      position="bottom"
                    >
                      <CountdownBadge
                        type={'regular'}
                        label={
                          <Fragment>
                            <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.00001 1.33331C4.32668 1.33331 1.33334 4.32665 1.33334 7.99998C1.33334 11.6733 4.32668 14.6666 8.00001 14.6666C11.6733 14.6666 14.6667 11.6733 14.6667 7.99998C14.6667 4.32665 11.6733 1.33331 8.00001 1.33331ZM10.9 10.38C10.8067 10.54 10.64 10.6266 10.4667 10.6266C10.38 10.6266 10.2933 10.6066 10.2133 10.5533L8.14668 9.31998C7.63334 9.01331 7.25334 8.33998 7.25334 7.74665V5.01331C7.25334 4.73998 7.48001 4.51331 7.75334 4.51331C8.02668 4.51331 8.25334 4.73998 8.25334 5.01331V7.74665C8.25334 7.98665 8.45334 8.33998 8.66001 8.45998L10.7267 9.69331C10.9667 9.83331 11.0467 10.14 10.9 10.38Z"
                                fill={colors.text['30']}
                              />
                            </svg>
                            {countdownLabel}
                          </Fragment>
                        }
                      />
                    </Tooltip>
                  )
              }
            </Fragment>
          }
        </LabelAndBadge>
        <Actions>
          {
            linearTickets.length > 0 &&
            <LinearTickets>
              {
                linearTickets.map(ticket => {
                  return (
                    <LinearTicket key={ticket.id} href={ticket.url} target="_blank">
                      <Tooltip
                        tooltip={`Open ${ticket.id} in Linear`}
                        position="bottom"
                        wrapperStyle={{
                          height: '100%'
                        }}
                      >
                        <Ticket>
                          <LinearIcon size={16}/>
                          {ticket.id}
                        </Ticket>
                      </Tooltip>
                    </LinearTicket>
                  )
                })
              }
            </LinearTickets>
          }
          <ContextMenu
            triggerId={`${environment.value}-overflow`}
            position="bottom"
            alignment="right"
            items={overflowMenuItems}
          >
            <CircleButton id={`${environment.value}-overflow`}>
              <OverflowIcon size={16}/>
            </CircleButton>
          </ContextMenu>
        </Actions>
      </Header>
      <ModulesContainer>
        {
          environment.modules.length === 1 && environment.modules[0] === 'recorder' &&
          <ModulePlaceholder module={'electron'}/>
        }
        {
          sortedModules.map(module => {
            if (environment.value === Environments.HIGHLIGHT && module === Modules.ELECTRON) {
              return (
                <HighlightModule
                  key={`${environment.value}-${module}`}
                  environment={environment}
                  module={Modules.HIGHLIGHT}
                  activeBuild={activeBuilds.find(build => build.environment === Environments.HIGHLIGHT)}
                />
              )
            }
            return (
              <Module
                key={`${environment.value}-${module}`}
                environment={environment}
                module={module}
                activeBuild={activeBuilds.find(build => build.module === module)}
                child={child}
              />
            )
          })
        }
        {
          environment.modules.length === 1 && environment.modules[0] === 'electron' &&
          <ModulePlaceholder module={Environments.HIGHLIGHT === environment.value ? 'empty' : 'recorder'}/>
        }
      </ModulesContainer>
    </Container>
  )
}

export default Environment

const Container = styled.div`
  background-color: ${colors.background['second-layer']};
  border: 1px solid ${colors.neutral['0A4']};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: fit-content;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  border-bottom: 1px solid ${colors.neutral['0A4']};
  padding: 8px 6px 8px 12px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: white;
`

const LabelAndBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  margin-inline-end: 8px;
  svg {
    flex-shrink: 0;
  }
`

const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({$child}) => $child ? `color: ${colors.text['30']};` : ''}
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: auto;
  gap: 1px;
`

const ModulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 12px;
`

const LinearTickets = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 3px;

  /* Style for a div that is the only child */
  > a:only-child {
    border-radius: 14px;
  }

  /* Style for the first div when there are exactly 2 children */
  > a:first-child:nth-last-child(2),
  > a:nth-child(1):nth-last-child(2) {
    border-radius: 14px 0 0 14px;
  }

  /* Style for the second div when there are exactly 2 children */
  > a:last-child:nth-child(2) {
    border-radius: 0 14px 14px 0;
    border-left: none;
  }

  /* Style for the first div when there are 3 or more children */
  > a:first-child:nth-last-child(n+3),
  > a:nth-child(1):nth-last-child(n+3) {
    border-radius: 14px 0 0 0;
  }

  /* Style for the middle divs when there are 3 or more children */
  > a:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-left: none;
  }

  /* Style for the last div when there are 3 or more children */
  > a:last-child:nth-child(n+3) {
    border-radius: 0 14px 14px 0;
    border-left: none;
  }
`

const LinearTicket = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.stroke['0A8']};
  height: 28px;
  padding: 0 6px;
  color: ${colors.text['70']};
  opacity: 0.64;
  
  &:hover {
    border-color: ${colors.stroke['0A16']};
    background-color: ${colors.neutral['0A4']};
    color: ${colors.text['30']};
    text-decoration: none;
    opacity: 1;
  }
`

const Ticket = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  white-space: nowrap;
  color: inherit;
  width: 100%;
  height: 100%;
`
