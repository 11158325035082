import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button} from '@get-wrecked/simple-components'
import {DefaultFlagTypeValues, FlagTypes} from './flagsConstants'
import ValueInput from './ValueInput'
import {useState} from 'react'

const propTypes = {
  flagKey: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])),
  valueType: PropTypes.oneOf(Object.values(FlagTypes)),
  onChange: PropTypes.func
}

const ArrayInput = ({flagKey, value, valueType, onChange = () => {}}) => {
  const [values, setValues] = useState(value.slice())

  const onAddValue = () => {
    const newValues = values.slice()
    newValues.push(DefaultFlagTypeValues[valueType])
    setValues(newValues)
    if (typeof onChange === 'function') {
      onChange(newValues)
    }
  }

  const onValueChange = (index, change) => {
    const newValues = values.slice()
    newValues[index] = change
    setValues(newValues)
    if (typeof onChange === 'function') {
      onChange(newValues)
    }
  }

  return (
    <Container>
      <ValuesContainer>
        {
          values.map((value, index) => {
            return (
              <ValueInput
                key={`${flagKey}-${index}`}
                type={valueType}
                value={value}
                onChange={change => onValueChange(index, change)}
              />
            )
          })
        }
        <Button
          variant="tertiary"
          size="large"
          onClick={onAddValue}
          style={{width: '100%'}}
        >
          Add Value
        </Button>
      </ValuesContainer>
    </Container>
  )
}

ArrayInput.propTypes = propTypes

export default ArrayInput

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`
