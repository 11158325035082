import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components'
import {Modules} from '@get-wrecked/updates/constants'
import Environment from '../../components/Environment';
import {EnvironmentContext, UserContext, VersionListContext} from '../../contexts';
import {mobileWidthMediaQuery, pageContentWidth} from '../../components/constants'
import {EnvironmentFilters} from '../../contexts/providers'
import UpArrowIcon from '../../components/Icons/UpArrowIcon'
import {colors} from '@get-wrecked/simple-components'

const EnvironmentList = () => {
  const {user} = useContext(UserContext)
  const {environments, activeEnvironments, searchQuery, environmentFilter} = useContext(EnvironmentContext)
  const {versionLists} = useContext(VersionListContext)
  const envListRef = useRef()
  const scrollTimeoutRef = useRef()
  const [isScrolled, setIsScrolled] = useState(false)

  const matchesSearch = useCallback(env => {
    if (!searchQuery?.length) {
      return true
    }
    const lowerCaseSearch = searchQuery.toLowerCase()
    const lowerCaseEnvValue = env.value.toLowerCase()
    const lowerCaseEnvLabel = env.label.toLowerCase()
    return lowerCaseEnvValue.includes(lowerCaseSearch) ||
      lowerCaseEnvLabel.includes(lowerCaseSearch) ||
      lowerCaseSearch.includes(lowerCaseEnvValue) ||
      lowerCaseSearch.includes(lowerCaseEnvLabel)
  }, [searchQuery])

  const filterAllows = useCallback(env => {
    if (environmentFilter === EnvironmentFilters.ALL) {
      return true
    } else if (environmentFilter === EnvironmentFilters.ACTIVE) {
      return activeEnvironments.some(e => e.value === env.value)
    } else if (environmentFilter === EnvironmentFilters.PUBLIC) {
      return env.public === true || env.userIds?.length > 0
    } else if (environmentFilter === EnvironmentFilters.DYNAMIC) {
      return env.static === false
    }
    return true
  }, [environmentFilter])

  const staticEnvironments = useMemo(() => {
    return environments.filter(env => env.static && !env.parent && matchesSearch(env) && filterAllows(env))
  }, [environments, activeEnvironments, matchesSearch, filterAllows])

  const dynamicEnvironments = useMemo(() => {
    return environments.filter(env => !env.static && !env.parent && matchesSearch(env) && filterAllows(env))
      .sort((a, b) => {
        const isActiveA = activeEnvironments.some(env => env.value === a.value)
        const isActiveB = activeEnvironments.some(env => env.value === b.value)

        // 1. Prioritize active over non-active.
        if (isActiveA && !isActiveB) {
          return -1
        }
        if (!isActiveA && isActiveB) {
          return 1
        }

        const electronVersionsA = versionLists?.[`${a.value}.${Modules.ELECTRON}`]
        const recorderVersionsA = versionLists?.[`${a.value}.${Modules.RECORDER}`]
        const electronVersionsB = versionLists?.[`${b.value}.${Modules.ELECTRON}`]
        const recorderVersionsB = versionLists?.[`${b.value}.${Modules.RECORDER}`]

        // 2. If both or neither are active, compare timestamps.
        const timestampA = Math.max(
          electronVersionsA?.[0]?.timestamp || 0,
          recorderVersionsA?.[0]?.timestamp || 0
        )
        const timestampB = Math.max(
          electronVersionsB?.[0]?.timestamp || 0,
          recorderVersionsB?.[0]?.timestamp || 0
        )

        return timestampB - timestampA
      })
  }, [environments, activeEnvironments, matchesSearch, filterAllows, versionLists])

  const scrollToTop = () => {
    envListRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setIsScrolled(false)
  }

  useEffect(() => {
    function onScrollEnd () {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }

      scrollTimeoutRef.current = setTimeout(() => {
        const scrollTop = envListRef.current.scrollTop
        if (scrollTop > 300) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      }, 250)
    }

    const scrollable = envListRef.current
    scrollable.addEventListener('scroll', onScrollEnd)
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
      scrollable.removeEventListener('scroll', onScrollEnd)
    }
  }, [])

  return (
    <List ref={envListRef} id="environment-list" className="scrollable y">
      {
        environments.length > 0 &&
        staticEnvironments.length === 0 &&
        dynamicEnvironments.length === 0 &&
        <EmptyState>
          Nothing to see here.
        </EmptyState>
      }
      {
        staticEnvironments.length > 0 &&
        <Grid>
          {
            staticEnvironments.map(env => {
              return <Environment
                key={env.value}
                user={user}
                environment={env}
                isActive={activeEnvironments.some(e => e.value === env.value)}
                child={environments.find(child => child.parent === env.value)}
              />
            })
          }
        </Grid>
      }
      {
        staticEnvironments.length > 0 && dynamicEnvironments?.length > 0 &&
        <Divider/>
      }
      {
        dynamicEnvironments.length > 0 &&
        <Grid>
          {
            dynamicEnvironments.map(env => {
              return <Environment
                key={env.value}
                user={user}
                environment={env}
                isActive={activeEnvironments.some(e => e.value === env.value)}
                child={environments.find(child => child.parent === env.value)}
              />
            })
          }
        </Grid>
      }
      <ScrollToTop
        onClick={scrollToTop}
        $visible={isScrolled}
      >
        Scroll to top
        <UpArrowIcon
          size={16}
          color={colors.text['70']}
          strokeWidth={2}
        />
      </ScrollToTop>
    </List>
  )
}

export default EnvironmentList

const List = styled.div`
  width: ${pageContentWidth};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 20px 20px 64px;
  position: relative;

  @media (${mobileWidthMediaQuery}) {
    padding: 12px 12px 64px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(390px, 100%), 1fr));
  grid-auto-flow: dense;
  grid-gap: 24px;

  @media (${mobileWidthMediaQuery}) {
    grid-gap: 12px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: ${colors.stroke['0A8']};
  margin: 24px 0;
`

const EmptyState = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  font-size: 14px;
  color: ${colors.text['30']};
`

const ScrollToTop = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  border-radius: 16px;
  height: 32px;
  padding: 6px 12px;
  color: ${colors.text['70']};
  font-size: 14px;
  background-color: ${colors.background['third-layer']};
  border: 1px solid ${colors.stroke['0A8']};
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.64);
  cursor: pointer;
  z-index: 1;
  
  transition: opacity 150ms ease, transform 150ms ease;
  opacity: ${({$visible}) => $visible ? 1 : 0};
  transform: translate3d(-50%, ${({$visible}) => $visible ? '0px' : `calc(100% + 20px)`}, 0);
  
  &:hover {
    background-color: ${colors.text['70']};
    border: 1px solid ${colors.neutral['0A24']};
    color: white;

    svg {
      path {
        stroke: white;
      }
    }
  }
  
  svg {
    path {
      stroke: ${colors.text['70']};
    }
  }
`
