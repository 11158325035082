import {useContext, useMemo, useState} from "react";
import styled from 'styled-components'
import {Button, colors, Dropdown, LoadingSpinner} from '@get-wrecked/simple-components'
import equal from 'deep-equal'
import useTimeAgo from "../../../hooks/useTimeAgo";
import {BuildApiContext, SelectedVersionContext, VersionListContext, WhatsNewContext} from "../../../contexts";
import Modal from '../../../components/Modal'
import {getWhatsNew} from '../../../utils/versions'
import WhatsNewIcon from '../../../components/Icons/WhatsNewIcon'
import Rollout from '../../Rollout'
import SavePrompt from '../../../components/SavePrompt'
import UpdateTypeSelection, {deriveUpdateType, UpdateTypes} from '../../../components/UpdateTypeSelection'
import useVersion from '../../../hooks/useVersion'

const VersionModal = () => {
  const {setWhatsNew} = useContext(WhatsNewContext)
  const {selectedVersion, setSelectedVersion} = useContext(SelectedVersionContext);
  const {versionLists, fetchVersionList} = useContext(VersionListContext);
  const {apiPost} = useContext(BuildApiContext);
  const environment = selectedVersion.environment;
  const module = selectedVersion.module;

  const version = useVersion({
    environment,
    module,
    version: selectedVersion.version
  })

  const [loadingWhatsNew, setLoadingWhatsNew] = useState(false)
  const [updateType, setUpdateType] = useState(deriveUpdateType(version))

  const [autoUpdate, setAutoUpdate] = useState(version?.autoUpdate);
  const [promptUpdate, setPromptUpdate] = useState(version?.promptUpdate);
  const [forceUpdate, setForceUpdate] = useState(version?.forceUpdate);
  const [forceUpdateString, setForceUpdateString] = useState(version?.forceUpdate === 'immediate' ? version.forceUpdate : '')
  const [rollout, setRollout] = useState(version?.rollout || {})
  const [saving, setSaving] = useState(false);
  const isForceUpdate = updateType === UpdateTypes.FORCED || updateType === UpdateTypes.IMMEDIATE_FORCED

  const timeAgo = useTimeAgo(version?.releaseTimestamp || version?.timestamp)

  const hasChanges = useMemo(() => {
    return autoUpdate !== version?.autoUpdate ||
      promptUpdate !== version?.promptUpdate ||
      forceUpdate !== version?.forceUpdate ||
      !equal(version?.rollout || {}, rollout)
  }, [version, rollout, autoUpdate, promptUpdate, forceUpdate])

  const close = (e, force) => {
    setSelectedVersion(undefined);
  }

  const save = async e => {
    setSaving(true);
    try {
      const body = {
        autoUpdate,
        promptUpdate,
        forceUpdate,
      };
      const resp = await apiPost(`/versions/${environment.value}/${module}/${version.semver}`, body)
      if (resp.data.find(v => v.semver === version.semver)) {
        await fetchVersionList({environment, module});
      } else {
        setAutoUpdate(version.autoUpdate);
        setPromptUpdate(version.promptUpdate);
        setForceUpdate(forceUpdateString === 'immediate' ? 'immediate' : version.forceUpdate);
      }
    } catch (err) {
      console.error(err);
    }
    setSaving(false);
  }

  const openWhatsNew = async () => {
    setLoadingWhatsNew(true)

    const {title, parsed, suppressed} = await getWhatsNew({
      environment,
      module,
      versionLists,
      currentVersion: version
    })

    setLoadingWhatsNew(false)

    setWhatsNew({
      title,
      suppressed,
      body: parsed
    })
  }

  const downloadOptions = useMemo(() => {
    const options = []
    if (version?.downloadUrl) {
      options.push({
        label: '.zip package',
        value: 'zip',
        onClick: () => {
          window.open(version.downloadUrl, '_blank')
        }
      })
    }
    if (version?.installerUrl) {
      options.push({
        label: '.exe installer',
        value: 'exe',
        onClick: () => {
          window.open(version.installerUrl, '_blank')
        }
      })
    }
    if (version?.nupkgUrl) {
      options.push({
        label: '.nupkg',
        value: 'nupkg',
        onClick: () => {
          window.open(version.nupkgUrl, '_blank')
        }
      })
    }
    if (version?.nupkgReleasesFileUrl) {
      options.push({
        label: 'RELEASES nupkg file',
        value: 'RELEASES',
        onClick: () => {
          window.open(version.nupkgReleasesFileUrl, '_blank')
        }
      })
    }
    return options
  }, [version])

  return (
    <Modal
      id="version-modal"
      header={`${environment.label} ${module} ${version.semver}`}
      size="small"
      onClose={close}
      bodyPadding={'20px 0 0 0'}
    >
      <SelectionWrapper $active={hasChanges || saving}>
        <UpdateTypeSelection
          onSelectUpdateType={(option, updateConfig) => {
            setUpdateType(option)
            setAutoUpdate(updateConfig.autoUpdate)
            setPromptUpdate(updateConfig.promptUpdate)
            setForceUpdate(updateConfig.forceUpdate)
          }}
          updateType={updateType}
        />
        <SavePrompt
          visible={hasChanges || saving}
          saving={saving}
          description={`You have pending changes. Saved changes will take effect within 1 minute.`}
          onButtonClick={save}
          requireConfirmation={isForceUpdate}
          confirmLabel={
            updateType === UpdateTypes.FORCED
              ? <span>I confirm I know what I'm doing by making this version a <strong>{updateType}</strong> update</span>
              : ''
          }
          confirmMethod={
            updateType === UpdateTypes.FORCED
              ? 'checkbox'
              : (updateType === UpdateTypes.IMMEDIATE_FORCED
                ? 'input'
                : undefined)
          }
          confirmString={'immediate'}
        />
      </SelectionWrapper>

      <Options>
        <a
          href={`https://github.com/get-wrecked/${module === 'electron' ? 'medal-electron' : 'MedalStudio'}/commit/${version.hash}`}
          target="_blank"
        >
          <Button
            variant="tertiary"
            size="medium"
            onClick={() => {}}
          >
            View on GitHub
          </Button>
        </a>

        {
          environment.static && (environment.public || environment.parent) &&
          <Button
            variant="tertiary"
            size="medium"
            onClick={() => !loadingWhatsNew && openWhatsNew()}
            style={{minWidth: '119px'}}
          >
            <WhatsNewIcon size={16}/>
            {loadingWhatsNew ? `Loading` : `Changelog`}
            {
              loadingWhatsNew &&
              <LoadingSpinner size="14px"/>
            }
          </Button>
        }
        <TimeAgo>{timeAgo}</TimeAgo>

        <Dropdown
          value="Download"
          size="medium"
          options={downloadOptions}
          position="top"
        />
      </Options>
    </Modal>
  )
}

export default VersionModal;

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
  padding: 16px;
  background-color: ${colors.background['third-layer']};
  border-top: 1px solid ${colors.stroke['0A8']};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 20px;
`

const TimeAgo = styled.span`
  font-size: 13px;
  font-weight: normal;
  color: ${colors.text['30']};
  margin-inline-end: auto;
`

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: ${({$active}) => $active ? '8px' : '0'};
`
