import {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  Button,
  Checkbox,
  CircleButton,
  colors,
  ContextMenu,
  LoadingSpinner,
  Tooltip,
} from '@get-wrecked/simple-components'
import {Approvals} from '@get-wrecked/updates/constants'
import Modal from '../../../components/Modal'
import {earlyAccessColor} from '../../../components/colors'
import {ToastContext, UserContext, VersionListContext, WhatsNewContext} from '../../../contexts'
import Avatar from '../../../components/Avatar'
import {Divider, LoadingPlaceholder} from '../../../components/styles'
import SavePrompt from '../../../components/SavePrompt'
import {getUsers} from '../../../utils/api'
import {Environments, Modules} from '@get-wrecked/updates/constants'
import semver from 'semver'
import ApplicationIcon from '../../../components/Icons/ApplicationIcon'
import RecordIcon from '../../../components/Icons/RecordIcon'
import Badge from '../../../components/Badge'
import EnvironmentIcon from '../../../components/Icons/EnvironmentIcon'
import WhatsNewIcon from '../../../components/Icons/WhatsNewIcon'
import SettingsIcon from '../../../components/Icons/SettingsIcon'
import SentryIcon from '../../../components/Icons/SentryIcon'
import {useNavigate} from 'react-router'
import {getWhatsNew} from '../../../utils/versions'
import useLatestVersions from '../../../hooks/useLatestVersions'
import useApprovals from '../../../hooks/useApprovals'
import useVersion from '../../../hooks/useVersion'
import ConfirmationDialog from '../../ConfirmationDialog'

const {MINIMUM_APPROVALS} = Approvals

const ApprovalModal = ({environment}) => {
  const {pushToast} = useContext(ToastContext)
  const {user} = useContext(UserContext)
  const [users, setUsers] = useState([])
  const [isApproved, setApproved] = useState(false)
  const [isRevoking, setIsRevoking] = useState(false)

  const versions = useLatestVersions(environment)
  const parentVersions = useLatestVersions(environment.parent)

  const versionsForApproval = useMemo(() => {
    const pendingVersions = {
      // electron: '5.0.0', // @TODO REMOVE
      // recorder: '4.0.0' // @TODO REMOVE
    }
    if (versions.electron && parentVersions.electron && semver.gt(versions.electron?.semver, parentVersions.electron?.semver)) {
      pendingVersions.electron = versions.electron?.semver
    }
    if (versions.recorder && parentVersions.recorder && semver.gt(versions.recorder?.semver, parentVersions.recorder?.semver)) {
      pendingVersions.recorder = versions.recorder?.semver
    }
    return pendingVersions
  }, [versions, parentVersions])

  const {
    approvals,
    isLoading,
    approve,
    revoke
  } = useApprovals({
    environment,
    versions: versionsForApproval,
    pollInterval: 2 * 1000
  })

  const isApprovedBySelf = approvals.some(approval => approval.userId === user.userId)
  const currentApprovalCount = approvals.length
  const initialIsApproved = useRef(isApproved)
  const remainingApprovals = MINIMUM_APPROVALS - currentApprovalCount

  const toggleApproval = () => {
    setApproved(!isApproved)
  }

  const onApproveOrRevoke = (updatedApprovals) => {
    const isSelfApproved = updatedApprovals.some(approval => {
      const isFromSelf = approval.userId === user.userId
      const isElectronApproved = versionsForApproval.electron
        ? approval.versions.electron === versionsForApproval.electron
        : true
      const isRecorderApproved = versionsForApproval.recorder
        ? approval.versions.recorder === versionsForApproval.recorder
        : true
      return isFromSelf && isElectronApproved && isRecorderApproved
    })
    initialIsApproved.current = isSelfApproved
    setApproved(isSelfApproved)
  }

  const onApprove = async () => {
    const updatedApprovals = await approve(versionsForApproval)
    onApproveOrRevoke(updatedApprovals)
  }

  const onRemoveApproval = async (revokeAll) => {
    const updatedApprovals = await revoke(versionsForApproval, revokeAll)
    onApproveOrRevoke(updatedApprovals)
  }

  const onRevokeAll = async () => {
    if (!isRevoking) {
      setIsRevoking(true)
      return
    }
    setIsRevoking(false)
    return onRemoveApproval(true)
  }

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const results = await getUsers(approvals.filter(approval => approval.userId !== user.userId).map(approval => approval.userId))
        if (results?.data?.length) {
          setUsers(results.data)
        }
      } catch (err) {
        pushToast({
          title: 'Error loading users',
          body: err.response.data || err.message,
          type: 'error'
        })
      }
    }

    const isApprovedByUser = approvals.some(approval => approval.userId === user.userId)
    initialIsApproved.current = isApprovedByUser
    setApproved(isApprovedByUser)

    if (approvals.length > 0 && !approvals.filter(approval => approval.userId !== user.userId).every(approval => users.some(u => u.userId === approval.userId))) {
      loadUsers()
    }
  }, [approvals])

  return (
    <Modal
      id="approval-modal"
      size="small"
      header={`${environment.label} Approval`}
      zIndex={1} // go behind other modals which default to 2
    >
      <Body>
        {
          remainingApprovals > 0
            ? <span>
              <Badge type="warning" size="medium">
                {currentApprovalCount === 0 ? remainingApprovals : `${remainingApprovals} more`}
              </Badge> approval{remainingApprovals > 1 ? 's' : ''} required for the following builds to release:
            </span>
            : <span>
              <Badge type="success" size="medium">Approved</Badge> and ready for release:
            </span>
        }
        <Versions>
          {
            versionsForApproval?.electron &&
            <Version
              version={versionsForApproval.electron}
              module={Modules.ELECTRON}
              environment={environment}
            />
          }
          {
            versionsForApproval?.recorder &&
            <Version
              version={versionsForApproval.recorder}
              module={Modules.RECORDER}
              environment={environment}
            />
          }
        </Versions>
        <Divider/>
        <ApprovalsList $isSingle={approvals.length === 0 || (approvals.length === 1 && isApprovedBySelf)}>
          {
            approvals
              .map((approval) => {
                const self = approval.userId === user.userId
                const approvedByUser = self
                  ? user
                  : users.find(x => x.userId === approval.userId)
                return (
                  <ApprovalUser
                    key={`approved-by-${approval.userId}`}
                    user={approvedByUser}
                    approved={self ? isApproved : true}
                    isSelf={self}
                    isLoading={!approvedByUser || isLoading}
                    onChange={toggleApproval}
                  />
                )
              })
          }
          {
            (currentApprovalCount < MINIMUM_APPROVALS) && !isApprovedBySelf && !isLoading &&
            <ApprovalUser
              user={user}
              approved={isApproved}
              isSelf={true}
              isLoading={isLoading}
              onChange={toggleApproval}
            />
          }
        </ApprovalsList>
        {
          currentApprovalCount >= MINIMUM_APPROVALS && !isApprovedBySelf &&
          <RevokeAllContainer>
            <Button
              size="xsmall"
              variant="danger"
              onClick={onRevokeAll}
            >
              Revoke All
            </Button>
          </RevokeAllContainer>
        }
      </Body>
      <SavePrompt
        visible={initialIsApproved.current !== isApproved}
        saving={false}
        description={
          isApproved
            ? `Are you sure you're ready to approve this ${environment.label} release?`
            : <Fragment>Revoke your approval for this {environment.label} release?<br/>Revoking your approval will undo every approval for this release.</Fragment>
        }
        buttonLabel={isApproved ? 'Approve' : 'Revoke'}
        buttonType={isApproved ? 'success' : 'danger'}
        onButtonClick={isApproved ? onApprove : onRemoveApproval}
        requireConfirmation={true}
        confirmMethod={'checkbox'}
      />
      {
        isRevoking &&
        <ConfirmationDialog
          modalId={'revoke-approvals'}
          title={"Revoke All Approvals?"}
          description={"Are you sure you want to revoke all approvals? This release will have to be re-approved."}
          confirmType={'danger'}
          onConfirm={onRevokeAll}
          onCancel={() => setIsRevoking(false)}
        />
      }
    </Modal>
  )
}

export default ApprovalModal

const Version = ({environment, module, version}) => {
  const {setWhatsNew} = useContext(WhatsNewContext)
  const {versionLists} = useContext(VersionListContext)
  const navigate = useNavigate()
  const [loadingWhatsNew, setLoadingWhatsNew] = useState(false)

  const versionEntry = useVersion({environment, module, version})

  const color = environment.parent === Environments.EARLY_ACCESS
    ? earlyAccessColor
    : colors.success['50']

  const settingsMenuItems = useMemo(() => {
    return [
      {
        label: <Fragment><SettingsIcon size="18" color={colors.text['30']}/> Configure version</Fragment>,
        onClick: () => {
          navigate(`/version/${environment.value}/${module}/${versionEntry?.semver}`)
        }
      },
      {
        label: <Fragment><WhatsNewIcon size="18" color={colors.text['30']}/> View GitHub changelog</Fragment>,
        onClick: () => {
          openWhatsNew(environment, versionEntry)
        }
      },
      {
        label: <a target="_blank" href={`https://medal-s2.sentry.io/releases/${environment.parent}%40${versionEntry?.semver}/?project=1378175`}>
          <SentryIcon size="16" color={colors.text['30']}/> Open Sentry release
        </a>,
      }
    ]
  }, [environment, versionEntry, module])

  const openWhatsNew = async (targetEnvironment, targetVersion) => {
    setLoadingWhatsNew(targetVersion?.semver)

    const {title, parsed, suppressed} = await getWhatsNew({
      environment: targetEnvironment,
      currentVersion: versionEntry,
      module,
      versionLists
    })

    setLoadingWhatsNew(false)

    setWhatsNew({
      title,
      suppressed,
      body: parsed
    })
  }

  return (
    <VersionBox
      $gradientRgb={'225,225,225'}
      $gradientAngle={'-45deg'}
    >
      <VersionInfo>
        <VersionLabel>
          {
            module === Modules.ELECTRON
              ? <ApplicationIcon size={16}/>
              : <RecordIcon size={16}/>
          }
          <strong>{module} {versionEntry?.semver}</strong>
        </VersionLabel>
        <Badge type="gray">
          <EnvironmentIcon
            size={16}
            color={color}
          />
          {environment.label}
        </Badge>
      </VersionInfo>
      <Actions>
        <Tooltip
          id={`approve-changelog-${environment.value}-${module}-${versionEntry?.semver}`}
          tooltip="View GitHub changelog"
          position="bottom"
        >
          {
            loadingWhatsNew
              ? <LoadingSpinner size="16px" style={{margin: '0 6px'}}/>
              : <CircleButton onClick={e => {
                openWhatsNew(environment, versionEntry)
              }}>
                <WhatsNewIcon size={18}/>
              </CircleButton>
          }
        </Tooltip>
        <ContextMenu
          triggerId={`${environment.value}-${module}-${versionEntry?.semver}-settings`}
          position="left"
          alignment="top"
          items={settingsMenuItems}
        >
          <CircleButton $size="28px" id={`${environment.value}-${module}-${versionEntry?.semver}-settings`}>
            <SettingsIcon size="18"/>
          </CircleButton>
        </ContextMenu>
      </Actions>
    </VersionBox>
  )
}

const ApprovalUser = ({user, approved, isSelf, disabled, isLoading, onChange = () => {}}) => {
  return (
    <ApprovalCheckbox
      $isSelf={isSelf}
      $isLoading={isLoading}
    >
      <Avatar
        image={user?.thumbnail}
        isLoading={isLoading}
      />
      {
        (isLoading || !user)
          ? <PlaceholderText $loadingSpace="650px"/>
          : <span>
            "My name is <strong>{user.userName ?? user.username}</strong> and I <Badge type="success-50">approve</Badge> this release."
          </span>
      }
      {
        !!user &&
        <CheckboxWrapper>
          <Checkbox
            checked={approved}
            disabled={!isSelf || disabled || isLoading}
            onChange={onChange}
          />
        </CheckboxWrapper>
      }
    </ApprovalCheckbox>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
`

const ApprovalCheckbox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${({$isSelf}) => $isSelf ? colors.neutral['0A4'] : 'rgba(255, 255, 255, 0.03)'};
  color: ${({$isSelf}) => $isSelf ? colors.text['30'] : 'rgba(179, 177, 182, 0.7)'};
  border: 1px solid ${colors.stroke['0A8']};
  gap: 12px;
  min-height: 57px;
  
  strong {
    ${({$isSelf}) => $isSelf ? 'color: white;' : ''};
    font-weight: 500;
  }
`

const ApprovalsList = styled.div`
  display: flex;
  flex-direction: column;

  ${ApprovalCheckbox}:first-child {
    ${({$isSingle}) => !$isSingle && 'border-radius: 8px 8px 0 0;'};
    ${({$isSingle}) => !$isSingle && 'border-bottom: none;'};
  }

  ${ApprovalCheckbox}:last-child {
    ${({$isSingle}) => !$isSingle && 'border-radius: 0 0 8px 8px;'};
  }

  ${ApprovalCheckbox}:nth-child(n+2):nth-last-child(n+2) {
    border-radius: 0;
    border-bottom: none;
  }
`

const RevokeAllContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
`

const PlaceholderText = styled.div`
  width: 100%;
  max-width: 50%;
  height: 16px;
  border-radius: 8px;
  background-color: ${colors.neutral['0A4']};
  ${LoadingPlaceholder}
`

const VersionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const VersionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.neutral['0A4']};
  color: ${colors.text['30']};
  border: 1px solid ${colors.stroke['0A8']};
  border-radius: 8px;
  padding: 12px;
  gap: 8px;
  ${({$gradientRgb, $gradientAngle}) => {
    const rgb = $gradientRgb ?? '255, 184, 75'
    const angle = $gradientAngle ?? '90deg'
    return `background-image: linear-gradient(${angle}, rgba(${rgb}, 0.08) 0%, rgba(${rgb}, 0) 100%);`
  }}

  display: flex;
  flex: 1;
  min-width: 250px;
  width: fit-content;
`
const Versions = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
`

const VersionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;

  strong {
    color: white;
    font-weight: 500;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`

const CheckboxWrapper = styled.div`
  margin-inline-start: auto;
`
