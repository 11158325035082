// Required for side-effects
import "firebase/firestore";

import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  deleteDoc,
  where,
  arrayUnion,
  arrayRemove
} from "firebase/firestore";

import {initializeApp} from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyB9EyRWvEg2-HNqHA6I_qWi7k5pFud8_bM",
  authDomain: "medal-2995f.firebaseapp.com",
  databaseURL: "https://medal-2995f.firebaseio.com",
  projectId: "medal-2995f",
  storageBucket: "medal-2995f.appspot.com",
  messagingSenderId: "298959318778",
  appId: "1:298959318778:web:eb7376b6210b4a60f46343",
  measurementId: "G-C632P6XN39"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const firestore = getFirestore(app);

export default firestore

export const getCollection = (path) => {
  return collection(firestore, path)
}

const getChangelogsQuery = ({includeDrafts, pageLimit = 100} = {}) => {
  const changelogs = getCollection('changelog')
  const createdAtOrderBy = orderBy('createdAt', 'desc')
  const enforcedLimit = limit(pageLimit)
  let q
  if (includeDrafts) {
    q = query(changelogs, orderBy('createdAt', 'desc'), enforcedLimit)
  } else {
    q = query(changelogs, where('published', '==', true), createdAtOrderBy, enforcedLimit)
  }
  return q
}

export const getChangelogs = async({includeDrafts, limit} = {}) => {
  return getDocs(getChangelogsQuery({includeDrafts, pageLimit: limit}))
}
export const getAndListenChangelogs = ({includeDrafts, limit} = {}, callback) => {
  return onSnapshot(getChangelogsQuery({includeDrafts, pageLimit: limit}), callback)
}

export const addChangelog = async (log) => {
  return addDoc(getCollection('changelog'), log)
}

export const updateChangelog = async (id, log) => {
  const changelogRef = doc(firestore, 'changelog', id)
  return updateDoc(changelogRef, log)
}

export const deleteChangelog = async (id) => {
  const changelogRef = doc(firestore, 'changelog', id)
  return deleteDoc(changelogRef)
}

export const addChangelogActiveUser = async (id, user) => {
  const changelogRef = doc(firestore, 'changelog', id)
  return updateDoc(changelogRef, {
    activeUsers: arrayUnion(user)
  })
}

export const removeChangelogActiveUser = async (id, user) => {
  const changelogRef = doc(firestore, 'changelog', id)
  return updateDoc(changelogRef, {
    activeUsers: arrayRemove(user)
  })
}
