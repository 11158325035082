import styled from 'styled-components'
import PropTypes from 'prop-types'
import {colors} from '@get-wrecked/simple-components'

const propTypes = {
  type: PropTypes.oneOf([
    'default',
    'brand',
    'success',
    'warning',
    'gray',
    'brand-50',
    'success-50'
  ]),
  style: PropTypes.object
}

const Badge = ({type, children, size, style, onClick}) => {
  let Component = Regular
  switch (type) {
    case 'gold':
    case 'brand':
      Component = Gold
      break
    case 'brand-50':
      Component = BrandPrimary50
      break
    case 'success':
      Component = Green
      break
    case 'success-50':
      Component = Success50
      break
    case 'warning':
      Component = Red
      break
    case 'gray':
      Component = Gray
      break
  }
  return (
    <Component style={style} $size={size} onClick={onClick}>
      {children}
    </Component>
  )
}

Badge.propTypes = propTypes

export default Badge

const Container = styled.span`
  padding: 0 6px;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 11px;
  font-weight: 500;
  width: fit-content;
  height: 22px;
  white-space: nowrap;
  
  ${({onClick}) => {
    if (typeof onClick === 'function') {
      return `
        cursor: pointer;
        
        &:hover {
          transform: scale(1.1);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.9);
        }
      `
    }
    return ''
  }}
  
  ${({$size}) => {
    if ($size === 'medium') {
      return `height: 24px; font-size: 13px;`
    }
  }}
`

const Regular = styled(Container)`
  border: 1px solid ${colors.stroke['0A8']};
  box-sizing: border-box;
  background-color: ${colors.neutral['0A4']};
  color: ${colors.text['70']};
`

const BrandPrimary50 = styled(Container)`
  background-color: ${colors.stroke['0A8']};
  border: 1px solid ${colors.neutral['0A4']};
  color: ${colors.brand.primary['50']};
`

const Success50 = styled(Container)`
  background-color: ${colors.stroke['0A8']};
  border: 1px solid ${colors.neutral['0A4']};
  color: ${colors.success['50']};
`

const Gold = styled(Container)`
  background-color: ${colors.brand.primary['90']};
  color: ${colors.brand.primary['10']};
`

const Green = styled(Container)`
  background-color: ${colors.success['70']};
  color: ${colors.success['10']};
`

const Red = styled(Container)`
  background-color: ${colors.error['70']};
  color: white;
`

const Gray = styled(Container)`
  border: 1px solid ${colors.neutral['0A4']};
  box-sizing: border-box;
  background-color: ${colors.stroke['0A16']};
  color: rgba(255, 255, 255, 0.72);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
`
