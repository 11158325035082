import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, colors, InputField, LoadingSpinner, Tooltip} from '@get-wrecked/simple-components'
import Modal from '../../../components/Modal'
import {FeatureFlagsContext} from '../../../contexts'
import AddFlagModal from './AddFlagModal'
import FeatureFlag from './FeatureFlag'
import useMediaQuery from '../../../hooks/useMediaQuery'
import {FlagSourceOptions} from './flagsConstants'

const FeatureFlagsModal = () => {
  const {loaded, ldFlags, fetchLdFlags, ldProjectKey, setLdProjectKey, flags, fetchFlags} = useContext(FeatureFlagsContext)
  const [isAddingFlag, setAddingFlag] = useState(false)
  const [search, setSearch] = useState('')
  const [flagSource, setFlagSource] = useState(FlagSourceOptions.find(option => option.value === ldProjectKey) ?? FlagSourceOptions[0])
  const searchDebounceRef = useRef()
  const isCompactNav = false
  const isMobileWidth = useMediaQuery(`max-width: 600px`)

  const filteredFlags = useMemo(() => {
    if (search?.length) {
      const filteredKeys = Object.keys(flags).filter(key => {
        const flag = flags[key]
        return key.includes(search.toLowerCase()) ||
          key.includes(search.toLowerCase().split(' ').join('-')) ||
          (flag.description?.length && flag.description.toLowerCase().includes(search.toLowerCase()))
      })

      const filteredFlags = {}
      for (let key of filteredKeys) {
        filteredFlags[key] = flags[key]
      }

      return filteredFlags
    }
    return flags
  }, [flags, search])

  const filteredLdFlags = useMemo(() => {
    if (search?.length) {
      return ldFlags.filter(flag => {
        return flag.key.includes(search.toLowerCase()) ||
          flag.key.includes(search.toLowerCase().split(' ').join('-')) ||
          flag.name.toLowerCase().includes(search.toLowerCase()) ||
          search.toLowerCase().includes(flag.name.toLowerCase()) ||
          (flag.description?.length && flag.description.toLowerCase().includes(search.toLowerCase()))
      })
    }
    return ldFlags
  }, [ldFlags, search])

  const onSearch = e => {
    const text = e.target.value
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current)
    }
    searchDebounceRef.current = setTimeout(() => {
      setSearch(text)
      searchDebounceRef.current = undefined
    }, 200)
  }

  useEffect(() => {
    fetchFlags()
    fetchLdFlags()
  }, [])

  return (
    <Modal
      id="feature-flags-modal"
      header={
        <FlagHeaderMenu
          loading={!loaded}
          selected={flagSource}
          onSelect={option => {
            setFlagSource(option)
            if (option.isLaunchDarkly) {
              setLdProjectKey(option.value)
            }
          }}
        />
      }
      size="medium"
      navigateOnClose={'home'}
      scrollable={false}
      style={{
        minHeight: '700px',
        maxHeight: isMobileWidth ? '100vh' : '90vh'
      }}
      bodyPadding={"0px"}
    >
      <Controls>
        <InputField
          placeholder="Search feature flags..."
          onChange={onSearch}
        />

        <Tooltip
          tooltip={"Create a new feature flag"}
          position="left"
        >
          <Button
            size="large"
            variant="secondary"
            style={{
              marginLeft: 'auto',
              width: isCompactNav ? '28px' : 'auto',
              ...(isCompactNav ? {padding: '0'} : {})
            }}
            onClick={() => setAddingFlag(true)}
          >
            <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7H13" stroke={colors.brand.primary['50']} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7 13V1" stroke={colors.brand.primary['50']} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {
              !isCompactNav &&
              'New Flag'
            }
          </Button>
        </Tooltip>
      </Controls>
      <Body className="scrollable y">
        {
          filteredLdFlags.map((ldFlag, index) => {
            return (
              <FeatureFlag
                key={`${flagSource.value}-${ldFlag.key}-${index}`}
                isLaunchDarklyFlag={true}
                flagKey={ldFlag.key}
                flag={ldFlag}
              />
            )
          })
        }
        {
          Object.keys(filteredFlags).reverse().map((flagKey, index) => {
            return (
              <FeatureFlag
                key={`${flagSource.value}-${flagKey}-${index}`}
                isLaunchDarklyFlag={false}
                flagKey={flagKey}
                flag={flags[flagKey]}
              />
            )
          })
        }
      </Body>
      {
        isAddingFlag &&
        <AddFlagModal
          onCreate={() => setAddingFlag(false)}
          onClose={() => setAddingFlag(false)}
        />
      }
    </Modal>
  )
}

export default FeatureFlagsModal

const FlagHeaderMenu = ({selected, onSelect, loading}) => {
  const [selecting, setSelecting] = useState(false)
  return (
    <Header>
      <HeaderDropdown
        className={selecting ? 'selecting' : ''}
        onClick={() => setSelecting(!selecting)}
      >
        {selected?.label} Flags
        {
          selecting
            ? <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.6806 13.9783L15.4706 10.7683L13.5106 8.79828C12.6806 7.96828 11.3306 7.96828 10.5006 8.79828L5.32056 13.9783C4.64056 14.6583 5.13056 15.8183 6.08056 15.8183H11.6906H17.9206C18.8806 15.8183 19.3606 14.6583 18.6806 13.9783Z"
                fill='white'/>
            </svg>
            : <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                fill='white'/>
            </svg>
        }
        {
          selecting &&
          <HeaderDropdownMenu>
            {
              FlagSourceOptions.map(option => {
                return (
                  <HeaderMenuItem
                    $selected={option.value === selected.value}
                    key={option.value}
                    onClick={() => {
                      onSelect(option)
                      setSelecting(false)
                    }}
                  >
                    {option.label}
                  </HeaderMenuItem>
                )
              })
            }
          </HeaderDropdownMenu>
        }
      </HeaderDropdown>
      {
        loading &&
        <LoadingSpinner size="20px"/>
      }
    </Header>
  )
}

// styles
const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 16px;
`
const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colors.stroke['0A8']};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
  margin-left: -50px;
`

const HeaderDropdown = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  gap: 8px;
  position: relative;
  
  &:hover, &.selecting {
    outline: 1px solid ${colors.neutral['0A24']};
    background-color: ${colors.stroke['0A8']};
  }
`

const HeaderDropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colors.neutral['0A24']};
  background-color: ${colors.background['third-layer']};
  z-index: 1;
  width: fit-content;
  overflow: hidden;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.64);
  
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
`

const HeaderMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${({$selected}) => $selected ? colors.stroke['0A8'] : 'transparent'};
  gap: 8px;
  white-space: nowrap;
  color: ${({$selected}) => $selected ? 'white' : colors.text['30']};
  
  &:hover {
    background-color: ${colors.stroke['0A16']};
    color: white;
  }
`
