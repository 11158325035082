import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

const PromoteIcon = ({size = '24', color = '#fff'}) => {
  if (true) {
    return (
      <svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.06 13.79C15.91 13.94 15.72 14.01 15.53 14.01C15.34 14.01 15.15 13.94 15 13.79L12 10.79L9 13.79C8.71 14.08 8.23 14.08 7.94 13.79C7.65 13.5 7.65 13.02 7.94 12.73L11.47 9.2C11.76 8.91 12.24 8.91 12.53 9.2L16.06 12.73C16.35 13.03 16.35 13.5 16.06 13.79Z"
          fill={color}
        />
      </svg>
    )
  }
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.14 9.89844H12.75V15.3784C12.75 15.7884 12.41 16.1284 12 16.1284C11.59 16.1284 11.25 15.7884 11.25 15.3784V9.89844H2.86C2.38 9.89844 2 10.2784 2 10.7584C2 16.6484 6.11 20.7584 12 20.7584C17.89 20.7584 22 16.6484 22 10.7584C22 10.2784 21.62 9.89844 21.14 9.89844Z"
        fill={color}
      />
      <path
        d="M12.7484 5.79969L14.3084 7.35969C14.5984 7.64969 15.0784 7.64969 15.3684 7.35969C15.6584 7.06969 15.6584 6.58969 15.3684 6.29969L12.5184 3.45969C12.2284 3.16969 11.7484 3.16969 11.4584 3.45969L8.61844 6.30969C8.47844 6.45969 8.39844 6.64969 8.39844 6.83969C8.39844 7.02969 8.46844 7.21969 8.61844 7.36969C8.90844 7.65969 9.38844 7.65969 9.67844 7.36969L11.2384 5.80969V9.89969H12.7384V5.79969H12.7484Z"
        fill={color}
      />
    </svg>
  )
}

PromoteIcon.propTypes = propTypes

export default PromoteIcon
