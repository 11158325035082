import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  color: PropTypes.string,
  child: PropTypes.bool
}

const EnvironmentIcon = ({size = '18', color, child}) => {
  if (child) {
    return (
      <svg width={`${size}`} height={`${size}`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.135C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.135C14.865 16.5 16.4925 14.8725 16.4925 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM7.455 9.9525C6.945 10.7175 6.24 11.34 5.415 11.7525C5.34 11.79 5.25 11.8125 5.1675 11.8125C4.9575 11.8125 4.7625 11.7 4.665 11.505C4.5225 11.2275 4.635 10.89 4.92 10.7475C5.5725 10.425 6.1275 9.93 6.525 9.33C6.66 9.1275 6.66 8.8725 6.525 8.67C6.12 8.07 5.565 7.575 4.92 7.2525C4.635 7.1175 4.5225 6.78 4.665 6.495C4.8 6.2175 5.1375 6.105 5.415 6.2475C6.24 6.66 6.945 7.2825 7.455 8.0475C7.845 8.625 7.845 9.375 7.455 9.9525ZM12.75 11.8125H9.75C9.4425 11.8125 9.1875 11.5575 9.1875 11.25C9.1875 10.9425 9.4425 10.6875 9.75 10.6875H12.75C13.0575 10.6875 13.3125 10.9425 13.3125 11.25C13.3125 11.5575 13.0575 11.8125 12.75 11.8125Z"
          fill={color}
        />
      </svg>
    )
  }
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM6.3975 10.1025C6.615 10.32 6.615 10.68 6.3975 10.8975C6.285 11.01 6.1425 11.0625 6 11.0625C5.8575 11.0625 5.715 11.01 5.6025 10.8975L4.1025 9.3975C3.885 9.18 3.885 8.82 4.1025 8.6025L5.6025 7.1025C5.82 6.885 6.18 6.885 6.3975 7.1025C6.615 7.32 6.615 7.68 6.3975 7.8975L5.295 9L6.3975 10.1025ZM10.2675 7.47L8.7675 10.9725C8.6775 11.1825 8.4675 11.31 8.25 11.31C8.175 11.31 8.1 11.295 8.0325 11.265C7.7475 11.145 7.6125 10.815 7.74 10.5225L9.24 7.02C9.36 6.735 9.69 6.6 9.975 6.7275C10.26 6.855 10.3875 7.185 10.2675 7.47ZM13.8975 9.3975L12.3975 10.8975C12.285 11.01 12.1425 11.0625 12 11.0625C11.8575 11.0625 11.715 11.01 11.6025 10.8975C11.385 10.68 11.385 10.32 11.6025 10.1025L12.705 9L11.6025 7.8975C11.385 7.68 11.385 7.32 11.6025 7.1025C11.82 6.885 12.18 6.885 12.3975 7.1025L13.8975 8.6025C14.115 8.82 14.115 9.18 13.8975 9.3975Z"
        fill={color}
      />
    </svg>
  )
}

EnvironmentIcon.propTypes = propTypes

export default EnvironmentIcon
