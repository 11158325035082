import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

const StorageIcon = ({size, color}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5786 19.6986C13.4686 19.8486 13.4686 22.9086 15.5786 23.0586H20.5886C21.1986 23.0586 21.7786 22.8386 22.2286 22.4286C23.7086 21.1386 22.9186 18.5386 20.9686 18.2986C20.2686 14.0786 14.1586 15.6786 15.6086 19.6986" fill={color}/>
      <path d="M15.5786 19.6986C13.4686 19.8486 13.4686 22.9086 15.5786 23.0586H20.5886C21.1986 23.0586 21.7786 22.8386 22.2286 22.4286C23.7086 21.1386 22.9186 18.5386 20.9686 18.2986C20.2686 14.0786 14.1586 15.6786 15.6086 19.6986" fill={color}/>
      <path d="M22 11.07V15.34C22 15.66 21.49 15.8 21.27 15.57C20.8 15.08 20.21 14.71 19.53 14.5C17.96 14.01 16.15 14.49 15.01 15.67C14.29 16.44 13.9 17.4 13.88 18.44C13.88 18.62 13.79 18.78 13.65 18.89C12.93 19.46 12.49 20.35 12.49 21.37V21.38C12.49 21.7 12.26 21.99 11.94 21.99H7.34C4.4 22 2 19.6 2 16.65V6.42C2 3.98 3.98 2 6.42 2H8.74C10.37 2 10.88 2.53 11.53 3.4L12.93 5.26C13.24 5.67 13.28 5.72 13.86 5.72H16.65C19.03 5.72 21.05 7.28 21.74 9.44C21.89 9.89 21.97 10.35 21.99 10.84C22 10.91 22 11 22 11.07Z" fill={color}/>
    </svg>
  )
}

StorageIcon.propTypes = propTypes

export default StorageIcon
