import PropTypes from 'prop-types'
import {getNotifier} from '../PushNotificationIcon'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  notifyColor: PropTypes.string
}

const BuildHistoryIcon = ({size = 24, color = '#fff', notifyColor}) => {
  const notifySize = size * 0.6
  return (
    <div style={{position: 'relative', display: 'flex', pointerEvents: 'none'}}>
      {
        notifyColor &&
        <div style={{display: 'flex', position: 'absolute', bottom: `-${notifySize * 0.3}px`, right: `-${notifySize * 0.3}px`, width: `${notifySize}px`, height: `${notifySize}px`, pointerEvents: 'none'}}>
          {getNotifier({notifyColor, size: notifySize})}
        </div>
      }
      <svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{pointerEvents: 'none'}}>
        <path
          d="M17.6191 9.62012H12.3691C11.9591 9.62012 11.6191 9.28012 11.6191 8.87012C11.6191 8.46012 11.9591 8.12012 12.3691 8.12012H17.6191C18.0291 8.12012 18.3691 8.46012 18.3691 8.87012C18.3691 9.28012 18.0391 9.62012 17.6191 9.62012Z"
          fill={color}/>
        <path
          d="M7.12055 10.3798C6.93055 10.3798 6.74055 10.3098 6.59055 10.1598L5.84055 9.40982C5.55055 9.11982 5.55055 8.63982 5.84055 8.34982C6.13055 8.05982 6.61055 8.05982 6.90055 8.34982L7.12055 8.56982L8.84055 6.84982C9.13055 6.55982 9.61055 6.55982 9.90055 6.84982C10.1905 7.13982 10.1905 7.61982 9.90055 7.90982L7.65055 10.1598C7.51055 10.2998 7.32055 10.3798 7.12055 10.3798Z"
          fill={color}/>
        <path
          d="M17.6191 16.6201H12.3691C11.9591 16.6201 11.6191 16.2801 11.6191 15.8701C11.6191 15.4601 11.9591 15.1201 12.3691 15.1201H17.6191C18.0291 15.1201 18.3691 15.4601 18.3691 15.8701C18.3691 16.2801 18.0391 16.6201 17.6191 16.6201Z"
          fill={color}/>
        <path
          d="M7.12055 17.3798C6.93055 17.3798 6.74055 17.3098 6.59055 17.1598L5.84055 16.4098C5.55055 16.1198 5.55055 15.6398 5.84055 15.3498C6.13055 15.0598 6.61055 15.0598 6.90055 15.3498L7.12055 15.5698L8.84055 13.8498C9.13055 13.5598 9.61055 13.5598 9.90055 13.8498C10.1905 14.1398 10.1905 14.6198 9.90055 14.9098L7.65055 17.1598C7.51055 17.2998 7.32055 17.3798 7.12055 17.3798Z"
          fill={color}/>
        <path
          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
          fill={color}/>
      </svg>
      {/*<svg width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{pointerEvents: 'none'}}>*/}
      {/*  <path d="M19.9994 19.2611H10.9294C10.4794 19.2611 10.1094 18.8911 10.1094 18.4411C10.1094 17.9911 10.4794 17.6211 10.9294 17.6211H19.9994C20.4494 17.6211 20.8194 17.9911 20.8194 18.4411C20.8194 18.9011 20.4494 19.2611 19.9994 19.2611Z" fill={color}/>*/}
      {/*  <path d="M19.9994 12.9681H10.9294C10.4794 12.9681 10.1094 12.5981 10.1094 12.1481C10.1094 11.6981 10.4794 11.3281 10.9294 11.3281H19.9994C20.4494 11.3281 20.8194 11.6981 20.8194 12.1481C20.8194 12.5981 20.4494 12.9681 19.9994 12.9681Z" fill={color}/>*/}
      {/*  <path d="M19.9994 6.67125H10.9294C10.4794 6.67125 10.1094 6.30125 10.1094 5.85125C10.1094 5.40125 10.4794 5.03125 10.9294 5.03125H19.9994C20.4494 5.03125 20.8194 5.40125 20.8194 5.85125C20.8194 6.30125 20.4494 6.67125 19.9994 6.67125Z" fill={color}/>*/}
      {/*  <path d="M4.90969 8.03187C4.68969 8.03187 4.47969 7.94187 4.32969 7.79187L3.41969 6.88188C3.09969 6.56188 3.09969 6.04187 3.41969 5.72187C3.73969 5.40187 4.25969 5.40187 4.57969 5.72187L4.90969 6.05188L7.04969 3.91187C7.36969 3.59188 7.88969 3.59188 8.20969 3.91187C8.52969 4.23188 8.52969 4.75188 8.20969 5.07188L5.48969 7.79187C5.32969 7.94187 5.12969 8.03187 4.90969 8.03187Z" fill={color}/>*/}
      {/*  <path d="M4.90969 14.3287C4.69969 14.3287 4.48969 14.2487 4.32969 14.0887L3.41969 13.1788C3.09969 12.8588 3.09969 12.3388 3.41969 12.0188C3.73969 11.6988 4.25969 11.6988 4.57969 12.0188L4.90969 12.3488L7.04969 10.2087C7.36969 9.88875 7.88969 9.88875 8.20969 10.2087C8.52969 10.5288 8.52969 11.0487 8.20969 11.3687L5.48969 14.0887C5.32969 14.2487 5.11969 14.3287 4.90969 14.3287Z" fill={color}/>*/}
      {/*  <path d="M4.90969 20.3288C4.69969 20.3288 4.48969 20.2488 4.32969 20.0888L3.41969 19.1788C3.09969 18.8588 3.09969 18.3388 3.41969 18.0188C3.73969 17.6988 4.25969 17.6988 4.57969 18.0188L4.90969 18.3488L7.04969 16.2087C7.36969 15.8888 7.88969 15.8888 8.20969 16.2087C8.52969 16.5288 8.52969 17.0488 8.20969 17.3688L5.48969 20.0888C5.32969 20.2488 5.11969 20.3288 4.90969 20.3288Z" fill={color}/>*/}
      {/*</svg>*/}
    </div>
  )
}

BuildHistoryIcon.propTypes = propTypes

export default BuildHistoryIcon
