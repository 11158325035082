import Modal from '../../../components/Modal'
import {Fragment, useContext, useEffect, useMemo, useState} from 'react'
import {EnvironmentContext, ReleasesContext, ToastContext, VersionListContext} from '../../../contexts'
import styled from 'styled-components'
import {colors, Dropdown} from '@get-wrecked/simple-components'
import {Environments} from '@get-wrecked/updates/constants'
import {earlyAccessColor} from '../../../components/colors'
import CountdownBadge from '../../../components/Badge/countdown'
import useCountdown from '../../../hooks/useCountdown'
import SavePrompt from '../../../components/SavePrompt'
import EnvironmentIcon from '../../../components/Icons/EnvironmentIcon'
import {post} from '../../../utils/builds-api'
import Badge from '../../../components/Badge'
import {useParams} from 'react-router-dom'

const DayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
]

const hourToMeridianTime = hour => {
  let meridian
  if (hour === 0) {
    hour = 12
    meridian = 'AM'
  } else if (hour >= 13) {
    hour = hour - 12
    meridian = 'PM'
  } else {
    meridian = 'AM'
  }
  return {
    hour,
    meridian
  }
}

const timeToMeridianString = (hour, minutes = 0) => {
  const meridianTime = hourToMeridianTime(hour)
  return `${meridianTime.hour}:${`${minutes}`.padStart(2, 0)} ${meridianTime.meridian}`
}

const timeToString = (hour, minutes) => {
  return `${`${hour}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`
}

const TimeOptions = []
for (let i = 0; i <= 23; i++) {
  TimeOptions.push({
    label: timeToMeridianString(i, 0),
    value: timeToString(i, 0)
  })
  TimeOptions.push({
    label: timeToMeridianString(i, 15),
    value: timeToString(i, 15)
  })
  TimeOptions.push({
    label: timeToMeridianString(i, 30),
    value: timeToString(i, 30)
  })
  TimeOptions.push({
    label: timeToMeridianString(i, 45),
    value: timeToString(i, 45)
  })
}

const ScheduleModal = () => {
  const {releaseSchedule, fetchReleaseSchedule} = useContext(ReleasesContext)
  const {environments, getEnvironment} = useContext(EnvironmentContext)
  const {pushToast} = useContext(ToastContext)
  const params = useParams()
  const [changes, setChanges] = useState({})
  const [changesFlags, setChangesFlags] = useState({})
  const [saving, setSaving] = useState(false)

  // @todo loading state while environments and release schedule load
  const env = useMemo(() => {
    return environments.find(e => e.value === params.environment)
  }, [params.environment, environments])

  const onSave = async () => {
    setSaving(true)
    const environments = Object.keys(releaseSchedule)
    for (let e of environments) {
      const env = getEnvironment(e)
      if (changesFlags[e] === true) {
        try {
          const newSchedule = changes[env.value]
          const resp = await post(`/releases/schedule/${env.value}`, newSchedule)
          if (resp.data.errorMessage) {
            throw new Error(resp.data.errorMessage)
          }
          const times = newSchedule.hour.split(':')
          pushToast({
            title: `Updated ${env.label} schedule!`,
            body: `Now scheduled for release on ${newSchedule.day} at ${timeToMeridianString(times[0], times[1])} ET`,
            type: 'success'
          })
        } catch (err) {
          pushToast({
            title: `Error saving ${env.label} schedule`,
            body: err.response.data?.errorMessage || err.message,
            type: 'error'
          })
          console.error(err)
        }
      }
    }

    await fetchReleaseSchedule()
    setSaving(false)
  }

  return (
    <Modal
      id="schedule-modal"
      size="small"
      header={`${env ? `${env.label} ` : ''}Release Schedule`}
      scrollable={false} // we want the dropdowns to be able to overflow
      navigateOnClose={true}
    >
      <Body>
        {
          params.environment && env && releaseSchedule[env.value] &&
          <EnvironmentSchedule
            environment={env}
            {...releaseSchedule[env.value]}
            onChange={({hasChanges, day, hour}) => {
              setChanges({
                ...changes,
                [env.value]: {
                  day,
                  hour
                }
              })
              setChangesFlags({
                ...changesFlags,
                [env.value]: hasChanges
              })
            }}
          />
        }
        {
          !params?.environment &&
          Object.keys(releaseSchedule).map(key => {
            return <EnvironmentSchedule
              key={key}
              environment={getEnvironment(key)}
              {...releaseSchedule[key]}
              onChange={({hasChanges, day, hour}) => {
                setChanges({
                  ...changes,
                  [key]: {
                    day,
                    hour
                  }
                })
                setChangesFlags({
                  ...changesFlags,
                  [key]: hasChanges
                })
              }}
            />
          })
        }
      </Body>
      <SavePrompt
        visible={Object.values(changesFlags).some(change => change === true) || saving}
        saving={saving}
        description={`You have pending changes to the release schedule.`}
        onButtonClick={onSave}
      />
    </Modal>
  )
}

export default ScheduleModal

const EnvironmentSchedule = ({environment, day, hour, onChange = () => {}}) => {
  const {getEnvironment} = useContext(EnvironmentContext)
  const [scheduleDay, setScheduleDay] = useState(day)
  const [scheduleHour, setScheduleHour] = useState(hour)
  const parentEnvironment = getEnvironment(environment.parent)

  const {countdownLabel: originalLabel} = useCountdown({
    targetDay: day,
    targetTime: hour
  })

  const {countdownLabel} = useCountdown({
    targetDay: scheduleDay,
    targetTime: scheduleHour
  })

  const hasChanges = (scheduleDay !== day || scheduleHour !== hour)

  const iconColor = useMemo(() => {
    if (parentEnvironment.static) {
      return parentEnvironment.public
        ? (parentEnvironment.value === Environments.PRODUCTION ? colors.success['50'] : earlyAccessColor)
        : colors.brand.primary['70'] // development
    } else {
      return colors.brand.secondary['30'] // dynamic
    }
  }, [parentEnvironment])

  useEffect(() => {
    if (scheduleDay !== day || scheduleHour !== hour) {
      onChange({
        day: scheduleDay,
        hour: scheduleHour,
        hasChanges: true
      })
    } else {
      onChange({
        day: scheduleDay,
        hour: scheduleHour,
        hasChanges: false
      })
    }
  }, [day, hour, scheduleDay, scheduleHour])

  return (
    <EnvironmentContainer>
      <EnvironmentLabel>
        <EnvironmentIcon
          size="20"
          color={iconColor}
        />
        {parentEnvironment.label ?? parentEnvironment}
        <Badge>Eastern Time</Badge>
        <CountdownBadge
          type={'regular'}
          label={
            <Fragment>
              <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.00001 1.33331C4.32668 1.33331 1.33334 4.32665 1.33334 7.99998C1.33334 11.6733 4.32668 14.6666 8.00001 14.6666C11.6733 14.6666 14.6667 11.6733 14.6667 7.99998C14.6667 4.32665 11.6733 1.33331 8.00001 1.33331ZM10.9 10.38C10.8067 10.54 10.64 10.6266 10.4667 10.6266C10.38 10.6266 10.2933 10.6066 10.2133 10.5533L8.14668 9.31998C7.63334 9.01331 7.25334 8.33998 7.25334 7.74665V5.01331C7.25334 4.73998 7.48001 4.51331 7.75334 4.51331C8.02668 4.51331 8.25334 4.73998 8.25334 5.01331V7.74665C8.25334 7.98665 8.45334 8.33998 8.66001 8.45998L10.7267 9.69331C10.9667 9.83331 11.0467 10.14 10.9 10.38Z"
                  fill={colors.text['30']}
                />
              </svg>
              {originalLabel}
            </Fragment>
          }
        />
        {
          hasChanges &&
          <Fragment>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke={colors.text['30']} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.5 12H20.33" stroke={colors.text['30']} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <CountdownBadge
              type={'success'}
              label={
                <Fragment>
                  <svg id="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00001 1.33331C4.32668 1.33331 1.33334 4.32665 1.33334 7.99998C1.33334 11.6733 4.32668 14.6666 8.00001 14.6666C11.6733 14.6666 14.6667 11.6733 14.6667 7.99998C14.6667 4.32665 11.6733 1.33331 8.00001 1.33331ZM10.9 10.38C10.8067 10.54 10.64 10.6266 10.4667 10.6266C10.38 10.6266 10.2933 10.6066 10.2133 10.5533L8.14668 9.31998C7.63334 9.01331 7.25334 8.33998 7.25334 7.74665V5.01331C7.25334 4.73998 7.48001 4.51331 7.75334 4.51331C8.02668 4.51331 8.25334 4.73998 8.25334 5.01331V7.74665C8.25334 7.98665 8.45334 8.33998 8.66001 8.45998L10.7267 9.69331C10.9667 9.83331 11.0467 10.14 10.9 10.38Z"
                      fill={colors.success['50']}
                    />
                  </svg>
                  {countdownLabel}
                </Fragment>
              }
            />
          </Fragment>
        }
      </EnvironmentLabel>
      <ScheduleContainer>
        <Dropdown
          value={scheduleDay}
          size="medium"
          options={DayOptions}
          onSelect={option => setScheduleDay(option)}
          style={{
            minWidth: '130px'
          }}
        />
        <Dropdown
          value={scheduleHour}
          size="medium"
          options={TimeOptions}
          onSelect={option => setScheduleHour(option?.value ?? option)}
          style={{
            minWidth: '115px'
          }}
        />
      </ScheduleContainer>
    </EnvironmentContainer>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const EnvironmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  background-color: ${colors.background['third-layer']};
  border: 1px solid ${colors.stroke['0A8']};
  gap: 12px;
`

const EnvironmentLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: white;
  gap: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
`

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`
