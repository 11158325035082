import React, { useState, useEffect } from 'react'
import { calculateTimeLeft } from '@get-wrecked/updates/utils/countdown'

const useCountdown = ({ targetDay, targetTime }) => {
  const [timeLeft, setTimeLeft] = useState({})

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (targetDay && targetTime) {
        const newTimeLeft = calculateTimeLeft(targetDay, targetTime)
        if (newTimeLeft.total <= 0) {
          clearInterval(intervalId)
        }
        setTimeLeft(newTimeLeft)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [targetDay, targetTime])

  useEffect(() => {
    if (targetDay && targetTime) {
      setTimeLeft(calculateTimeLeft(targetDay, targetTime))
    }
  }, [targetDay, targetTime])

  const showSeconds = timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes < 10

  return {
    countdownLabel: timeLeft.total > 0
      ? `${timeLeft.days > 0 ? `${timeLeft.days}d ` : ''}${timeLeft.hours > 0 ? `${timeLeft.hours}h ` : ''} ${timeLeft.minutes}m${showSeconds ? ` ${String(timeLeft.seconds).padStart(2, '0')}s` : ''}`
      : '00:00:00',
    timeLeft
  }
}

export default useCountdown

