import { Identify, identify } from '@amplitude/analytics-browser';
import * as amplitude from '@amplitude/analytics-browser'

export const init = () => {
  // initialize amplitude Medal Builds project tracking
  amplitude.init('758d847cf5b770dacc25399ba8d5d5e2', undefined, {
    defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
    serverUrl: 'https://ampltd2.medal.tv/2/httpapi'
  });
}

export const setUserId = (userId) => {
  amplitude.setUserId(userId)
}

export const setProperty = (key, value) => {
  const event = new Identify();
  event.set(key, value);
  identify(event);
}

export const track = (event, props) => {
  amplitude.track(event, props)
}
