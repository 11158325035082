import styled from 'styled-components'
import semver from 'semver'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import {Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {CircleButton, colors, ContextMenu, LoadingSpinner, Tooltip} from '@get-wrecked/simple-components'
import ApplicationIcon from '../Icons/ApplicationIcon'
import RecordIcon from '../Icons/RecordIcon'
import SettingsIcon from '../Icons/SettingsIcon'
import {ModalContext, ToastContext, VersionListContext, WhatsNewContext} from '../../contexts'
import useTimeAgo from '../../hooks/useTimeAgo'
import {LoadingPlaceholder, SuccessText, WarningText} from '../styles'
import SentryIcon from '../Icons/SentryIcon'
import WhatsNewIcon from '../Icons/WhatsNewIcon'
import Badge from '../Badge'
import PromoteIcon from '../Icons/PromoteIcon'
import {getWhatsNew} from '../../utils/versions'
import useLatestVersion from '../../hooks/useLatestVersion'
import useVersionOrLatest from '../../hooks/useVersionOrLatest'
import {Button} from '@get-wrecked/simple-components'
import {post} from '../../utils/builds-api'
import ConfirmationDialog from '../../presentations/ConfirmationDialog'

const propTypes = {
  id: PropTypes.string,
  environment: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  version: PropTypes.object,
  activeBuild: PropTypes.object,
  parent: PropTypes.object
}

const Module = ({id, environment, module, version, activeBuild, child}) => {
  const navigate = useNavigate()
  const {openModal} = useContext(ModalContext)
  const {setWhatsNew} = useContext(WhatsNewContext)
  const {pushToast} = useContext(ToastContext)

  // local states
  const [isPromoting, setPromoting] = useState(false)
  const [loadingWhatsNew, setLoadingWhatsNew] = useState(false)
  const activeBuildFlagRef = useRef(false)
  const [cancelling, setCancelling] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  // current version config
  const {versionLists, fetchVersionList} = useContext(VersionListContext)

  const latestVersion = useLatestVersion({environment, module})
  const currentVersion = useVersionOrLatest({
    environment,
    module,
    version
  })

  // only loading if current version is not defined
  const [isLoading, setLoading] = useState(!currentVersion)

  const releaseDate = new Date(currentVersion?.releaseTimestamp ?? currentVersion?.timestamp)
  const timeAgo = useTimeAgo(releaseDate.getTime())
  const activeBuildTimeAgo = useTimeAgo(activeBuild?.timestamp)

  // for parent environments w/ children
  const hasChild = !!child

  const childVersion = useLatestVersion({
    environment: child,
    module
  })

  const childTimestampDate = new Date(childVersion?.releaseTimestamp ?? childVersion?.timestamp)
  const childVersionTimeAgo = useTimeAgo(childTimestampDate.getTime())

  const childExceedsParentVersion = useMemo(() => {
    return hasChild &&
      currentVersion &&
      childVersion &&
      semver.gt(childVersion?.semver, currentVersion?.semver)
  }, [version, currentVersion, childVersion])

  const onPromote = useCallback(() => {
    openModal('promotion-modal', {
      environment: child,
      version: childVersion,
      parentEnvironment: environment,
      parentVersion: currentVersion,
      module,
    })
  }, [environment, child, currentVersion, childVersion, module])

  const openWhatsNew = async (targetEnvironment, targetVersion) => {
    setLoadingWhatsNew(targetVersion?.semver)

    const {title, parsed, suppressed} = await getWhatsNew({
      environment: targetEnvironment,
      currentVersion: version,
      module,
      versionLists
    })

    setLoadingWhatsNew(false)

    setWhatsNew({
      title,
      suppressed,
      body: parsed
    })
  }

  const onCancelBuild = async (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!confirmCancel) {
      setConfirmCancel(true);
      return;
    }
    setCancelling(true);
    try {
      const resp = await post(`/build/${activeBuild.buildId}/cancel`)
    } catch (err) {
      pushToast({
        title: 'Error canceling build',
        body: err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
      console.log(err)
    }
    setCancelling(false);
    setConfirmCancel(false)
  }

  const settingsMenuItems = useMemo(() => {
    return [
      {
        label: <Fragment><SettingsIcon size="18" color={colors.text['30']}/> Configure version</Fragment>,
        onClick: () => {
          navigate(`/version/${environment.value}/${module}/${currentVersion?.semver}`)
        }
      },
      {
        label: <Fragment><WhatsNewIcon size="18" color={colors.text['30']}/> View GitHub changelog</Fragment>,
        onClick: () => {
          openWhatsNew(environment, currentVersion)
        }
      },
      {
        label: <a target="_blank" href={`https://medal-s2.sentry.io/releases/${environment.value}%40${currentVersion?.semver}/?project=1378175`}>
          <SentryIcon size="16" color={colors.text['30']}/> Open Sentry release
        </a>,
      }
    ]
  }, [environment, currentVersion, module])

  const childMenuItems = useMemo(() => {
    return [
      {
        label: <Fragment><PromoteIcon size="18" color={colors.success['50']}/> <SuccessText>Promote candidate</SuccessText></Fragment>,
        onClick: onPromote
      },
      {
        label: <Fragment><SettingsIcon size="18" color={colors.text['30']}/> Configure candidate</Fragment>,
        onClick: () => {
          navigate(`/version/${child.value}/${module}/${childVersion?.semver}`)
        }
      },
      {
        label: <Fragment><WhatsNewIcon size="18" color={colors.text['30']}/> View GitHub changelog</Fragment>,
        onClick: () => {
          openWhatsNew(child, childVersion)
        }
      },
      {
        divider: true
      },
      {
        label: <WarningText>Delete version</WarningText>,
        onClick: () => {
          // @todo
        }
      }
    ]
  }, [child, childVersion, module])

  useEffect(() => {
    if (!version) {
      fetchVersionList({
        environment,
        module
      })
        .then(() => setLoading(false))
    }
    if (!version && hasChild && !!child.modules?.length && !childVersion) {
      fetchVersionList({
        environment: child,
        module
      })
    }
  }, [])

  useEffect(() => {
    if (activeBuild) {
      activeBuildFlagRef.current = true
    }
    if (!activeBuild && activeBuildFlagRef.current) {
      activeBuildFlagRef.current = false
      fetchVersionList({
        environment: hasChild
          ? child
          : environment,
        module
      })
    }
  }, [activeBuild])

  return (
    <Fragment>
      <Container>
        <Header>
          <Label>
            {
              module === 'electron'
                ? <ApplicationIcon size={16}/>
                : <RecordIcon size={16}/>
            }
            {module}
          </Label>
        </Header>
        <Contents>
          <VersionContainer
            $visible={true}
            $isLoading={isLoading}
          >
            <VersionMetadata>
              <VersionRow>
                {currentVersion?.semver}
                {
                  !isLoading &&
                  ((hasChild && environment.static && environment.public && currentVersion?.semver === latestVersion?.semver)) &&
                  <Badge type="success">
                    Live
                  </Badge>
                }
              </VersionRow>
              <Tooltip
                id={`timestamp-${environment.value}-${module}-${currentVersion?.semver}`}
                tooltip={`${releaseDate.toDateString()} ${releaseDate.toTimeString()}`}
                position="bottom"
              >
                <Timestamp>
                  {timeAgo}
                </Timestamp>
              </Tooltip>
            </VersionMetadata>
            {
              !isLoading &&
              <Actions>
                <Tooltip
                  id={`open-sentry-${environment.value}-${module}-${currentVersion?.semver}`}
                  tooltip="Open Sentry release"
                  position="bottom"
                >
                  <CircleButton
                    href={`https://medal-s2.sentry.io/releases/${environment.value}%40${currentVersion?.semver}/?project=1378175`}
                    target="_blank"
                    style={{
                      paddingBottom: '1px'
                    }}
                  >
                    <SentryIcon size={16}/>
                  </CircleButton>
                </Tooltip>
                {
                  environment.static && environment.public &&
                  <Tooltip
                    id={`view-changelog-${environment.value}-${module}-${currentVersion?.semver}`}
                    tooltip="View GitHub changelog"
                    position="bottom"
                  >
                    {
                      loadingWhatsNew === currentVersion?.semver
                        ? <LoadingSpinner size="16px" style={{margin: '0 6px'}}/>
                        : <CircleButton onClick={e => {
                          openWhatsNew(environment, currentVersion)
                        }}>
                          <WhatsNewIcon size={18}/>
                        </CircleButton>
                    }
                  </Tooltip>
                }
                <ContextMenu
                  triggerId={`${id ?? `${environment.value}-${module}`}-settings`}
                  position="left"
                  alignment="top"
                  items={settingsMenuItems}
                >
                  <CircleButton $size="28px" id={`${id ?? `${environment.value}-${module}`}-settings`}>
                    <SettingsIcon size="18"/>
                  </CircleButton>
                </ContextMenu>
              </Actions>
            }
          </VersionContainer>

          {
            hasChild && !!child.modules.length &&
            <VersionContainer
              $visible={!!childVersion && childExceedsParentVersion}
              $isLoading={isLoading}
              $isChild={true}
            >
              <VersionMetadata>
                <VersionRow>
                  {childVersion?.semver}
                  <Badge type="brand">
                    Candidate
                  </Badge>
                </VersionRow>
                <Tooltip
                  id={`child-timestamp-${environment.value}-${module}-${currentVersion?.semver}`}
                  tooltip={`${childTimestampDate.toDateString()} ${childTimestampDate.toTimeString()}`}
                  position="bottom"
                >
                  <Timestamp>
                    {childVersionTimeAgo}
                  </Timestamp>
                </Tooltip>
              </VersionMetadata>
              {
                !isLoading &&
                <Actions>
                  <Tooltip
                    id={`promote-${child.value}-${module}-${childVersion?.semver}`}
                    tooltip={`Promote ${module} candidate`}
                    position="bottom"
                  >
                    {
                      !isPromoting
                        ? <CircleButton
                          onClick={onPromote}
                          initialOpacity={'0.64'}
                        >
                          <PromoteIcon size="20" color={colors.success['50']}/>
                        </CircleButton>
                        : <LoadingSpinner size="16px" style={{margin: '0 6px'}}/>
                    }
                  </Tooltip>
                  <Tooltip
                    id={`view-changelog-${child.value}-${module}-${childVersion?.semver}`}
                    tooltip="View GitHub changelog"
                    position="bottom"
                  >
                    {
                      loadingWhatsNew === childVersion?.semver
                        ? <LoadingSpinner size="16px" style={{margin: '0 6px'}}/>
                        : <CircleButton onClick={e => {
                          openWhatsNew(child, childVersion)
                        }}>
                          <WhatsNewIcon size={18}/>
                        </CircleButton>
                    }
                  </Tooltip>
                  <ContextMenu
                    triggerId={`${child.value}-${module}-settings`}
                    position="left"
                    alignment="top"
                    items={childMenuItems}
                  >
                    <CircleButton $size="28px" id={`${child.value}-${module}-settings`}>
                      <SettingsIcon size="18"/>
                    </CircleButton>
                  </ContextMenu>
                </Actions>
              }
            </VersionContainer>
          }

          <ActiveBuildContainer
            $visible={!!activeBuild}
            $isActive={true}
            onClick={() => navigate(`build/${activeBuild?.buildId}`)}
          >
            <VersionMetadata>
              {activeBuild?.version}
              <Timestamp>
                {activeBuildTimeAgo}
              </Timestamp>
            </VersionMetadata>
            {
              activeBuild &&
              <ActiveBuildActions>
                <LoadingSpinner size="18px"/>
                <Button
                  variant="tertiary"
                  size="xsmall"
                  onClick={onCancelBuild}
                >
                  Cancel
                </Button>
              </ActiveBuildActions>
            }
          </ActiveBuildContainer>
        </Contents>
      </Container>
      {
        confirmCancel &&
        <ConfirmationDialog
          modalId={'confirm-cancel-build'}
          title={`Confirm Build Cancellation`}
          description={`Are you sure you want to cancel this ${activeBuild.module} build?`}
          onConfirm={onCancelBuild}
          onCancel={() => setConfirmCancel(false)}
        />
      }
    </Fragment>
  )
}

Module.propTypes = propTypes

export default Module

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
`

const Container = styled(ModuleContainer)`
  background-color: ${colors.background['third-layer']};
  border: 1px solid ${colors.neutral['0A4']};
  box-sizing: border-box;
  border-radius: 6px;
  height: fit-content;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding-left: 8px;
  padding-right: 4px;
  height: 36px;
  gap: 8px;
  border-bottom: 1px solid ${colors.neutral['0A4']};
  box-sizing: border-box;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: auto;
  gap: 4px;
  text-transform: capitalize;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
`

const VersionContainer = styled.div`
  width: 100%;
  background-color: ${colors.neutral['0A4']};
  border-radius: 4px;
  box-sizing: border-box;
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  ${({$isNew}) => $isNew ? `background-image: linear-gradient(-90deg, rgba(1, 210, 142, 0.08) 0%, rgba(1, 210, 142, 0) 100%);` : ''}
  ${({$isChild}) => $isChild ? `background-image: linear-gradient(90deg, rgba(255, 184, 75, 0.08) 0%, rgba(255, 184, 75, 0) 100%);` : ''}

  border: ${({$visible}) => $visible ? `1px solid ${colors.stroke['0A8']}` : 'none'};
  pointer-events: ${({$visible}) => $visible ? 'initial' : 'none'};
  overflow: ${({$visible}) => $visible ? 'initial' : 'hidden'};
  margin-top: ${({$visible, $isChild, $isActive}) => $visible ? ($isChild || $isActive ? '8px' : '') : '0'};
  max-height: ${({$visible}) => $visible ? '46px' : '0'};
  padding: ${({$visible}) => $visible ? '4px 6px' : '0'};
  opacity: ${({$visible}) => $visible ? '1' : '0'};
  transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out, padding 200ms ease-in-out, margin-top 200ms ease-in-out;

  ${({$isLoading}) => $isLoading ? LoadingPlaceholder : ''}
`

const ActiveBuildContainer = styled(VersionContainer)`
  border-style: dashed;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(255, 184, 75, 0.04) 0%, rgba(255, 184, 75, 0) 100%);
  cursor: ${({$visible}) => $visible ? 'pointer' : 'default'};
  border-width: ${({$visible}) => $visible ? '1px' : '0px'};
  
  &:hover {
    border-style: solid;
    border-color: ${colors.stroke['0A16']};
    background-color: ${colors.neutral['0A4']};
  }
`

const VersionMetadata = styled.div`
  display: flex;
  flex-direction: column;
`

const Timestamp = styled.span`
  font-size: 12px;
  color: ${colors.text['70']};
  font-weight: normal;
`

const Actions = styled.div`
  display: flex;
  gap: 1px;
  justify-content: flex-end;
  align-items: center;
  margin-inline-start: auto;
`

const ActiveBuildActions = styled(Actions)`
  align-items: center;
  gap: 8px;
`

const VersionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
