import {Fragment, useContext, useEffect} from 'react';
import './App.css';
import dayjs from 'dayjs';
import { Theme } from '@get-wrecked/simple-components'

import {
  ModalProvider,
  AuthProvider,
  BuildApiProvider,
  EnvironmentProvider,
  VersionListProvider,
  ToastProvider,
  ModalsProvider,
  ReleasesProvider, ChangelogsProvider, FeatureFlagsProvider, TimeAgoProvider,
} from "./contexts/providers";

import ModalContainer from "./presentations/ModalContainer";
import EnvironmentList from "./presentations/EnvironmentList";
import {
  BuildApiContext,
  EnvironmentContext,
  UserContext
} from "./contexts";
import ToastContainer from "./presentations/ToastContainer";
import Routing from "./Routing";
import {init as initAmplitude} from './utils/amplitude'
import HelpButton from './components/HelpButton'
import LoginPage from './presentations/LoginPage'
import Tabs from './components/Tabs'

// initialize amplitude Medal Builds project tracking
initAmplitude();

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);

const App = () => {
  return (
    <ToastProvider>
      <AuthProvider>
        <EnvironmentProvider>
          <VersionListProvider>
            <ReleasesProvider>
              <BuildApiProvider>
                <ChangelogsProvider>
                  <FeatureFlagsProvider>
                    <ModalsProvider>
                      <ModalProvider>
                        <TimeAgoProvider>
                          <Theme>
                            <Main/>
                          </Theme>
                        </TimeAgoProvider>
                      </ModalProvider>
                    </ModalsProvider>
                  </FeatureFlagsProvider>
                </ChangelogsProvider>
              </BuildApiProvider>
            </ReleasesProvider>
          </VersionListProvider>
        </EnvironmentProvider>
      </AuthProvider>
    </ToastProvider>
  )
}

const Main = () => {
  const {user, loading} = useContext(UserContext);
  const {buildApiState, fetchBuildApiState} = useContext(BuildApiContext);
  const {fetchEnvironments, setActiveEnvironments} = useContext(EnvironmentContext);

  // on user finished loading (or no user loaded)
  useEffect(() => {
    if (user?.isStaff) {
      console.log('Staff verified, requesting build api state...')
      fetchBuildApiState();
    } else if (!loading) {
      fetchEnvironments();
    }
  }, [loading, user])

  // when the build api state changes, refresh active environments
  useEffect(() => {
    fetchEnvironments()
      .then(environmentList => {
        setActiveEnvironments(environmentList.filter(env => Object.values(buildApiState?.activeBuilds || {}).find(build => build.environment === env.value)))
      })
  }, [buildApiState])

  useEffect(() => {
    return () => {
      console.log('unmounted App')
    }
  }, [])

  if (!user?.isStaff) {
    return <LoginPage/>
  }

  return (
    <Fragment>
      <Routing/>
      <div className="app">
        <Tabs/>
        <EnvironmentList/>
        <HelpButton/>
      </div>
      <ModalContainer/>
      <ToastContainer/>
    </Fragment>
  );
}

export default App;
