import {useMemo} from 'react'
import useVersion from './useVersion'
import useLatestVersion from './useLatestVersion'

const useVersionOrLatest = ({environment, module, version}) => {
  const ver = useVersion({environment, module, version})
  const latest = useLatestVersion({environment, module})
  const use = useMemo(() => {
    return version
      ? ver
      : latest
  }, [version, ver, latest])
  return use
}

export default useVersionOrLatest
