import React, {useMemo} from 'react'
import {colors} from '@get-wrecked/simple-components'
import {draftPullRequestColor, mergedPullRequestColor} from '../../colors'

const PullRequestIcon = ({pullRequest, size = 16, style = {}} = {}) => {
  const icon = useMemo(() => {
    switch (pullRequest.state) {
      case 'open':
        if (pullRequest.draft) {
          return (
            <svg viewBox="0 0 16 16" version="1.1" width={`${size}`} height={`${size}`} aria-hidden="true" style={{...style, fill: '#768380'}}>
              <path
                fillRule="evenodd"
                d="M2.5 3.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.25 1a2.25 2.25 0 00-.75 4.372v5.256a2.251 2.251 0 101.5 0V5.372A2.25 2.25 0 003.25 1zm0 11a.75.75 0 100 1.5.75.75 0 000-1.5zm9.5 3a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5z"
              />
              <path d="M14 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm0-4.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"/>
            </svg>
          )
        } else {
          return (
            <svg viewBox="0 0 16 16" version="1.1" width={`${size}`} height={`${size}`} aria-hidden="true" style={{...style, fill: colors.success['50']}}>
              <path
                fillRule="evenodd"
                d="M7.177 3.073L9.573.677A.25.25 0 0110 .854v4.792a.25.25 0 01-.427.177L7.177 3.427a.25.25 0 010-.354zM3.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 113 2.122v5.256a2.251 2.251 0 11-1.5 0V5.372A2.25 2.25 0 011.5 3.25zM11 2.5h-1V4h1a1 1 0 011 1v5.628a2.251 2.251 0 101.5 0V5A2.5 2.5 0 0011 2.5zm1 10.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.75 12a.75.75 0 100 1.5.75.75 0 000-1.5z"
              />
            </svg>
          )
        }

      case 'closed':
        if (!pullRequest.merged_at) {
          return (
            <svg viewBox="0 0 16 16" version="1.1" width={`${size}`} height={`${size}`} aria-hidden="true" style={{...style, fill: colors.error['50']}}>
              <path
                fillRule="evenodd"
                d="M5 3.254V3.25v.005a.75.75 0 110-.005v.004zm.45 1.9a2.25 2.25 0 10-1.95.218v5.256a2.25 2.25 0 101.5 0V7.123A5.735 5.735 0 009.25 9h1.378a2.251 2.251 0 100-1.5H9.25a4.25 4.25 0 01-3.8-2.346zM12.75 9a.75.75 0 100-1.5.75.75 0 000 1.5zm-8.5 4.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
              />
            </svg>
          )
        } else {
          return (
            <svg viewBox="0 0 16 16" version="1.1" width={`${size}`} height={`${size}`} aria-hidden="true" style={{...style, fill: mergedPullRequestColor}}>
              <path
                fillRule="evenodd"
                d="M5 3.254V3.25v.005a.75.75 0 110-.005v.004zm.45 1.9a2.25 2.25 0 10-1.95.218v5.256a2.25 2.25 0 101.5 0V7.123A5.735 5.735 0 009.25 9h1.378a2.251 2.251 0 100-1.5H9.25a4.25 4.25 0 01-3.8-2.346zM12.75 9a.75.75 0 100-1.5.75.75 0 000 1.5zm-8.5 4.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
              />
            </svg>
          )
        }

      default:
        return (
          <svg viewBox="0 0 16 16" version="1.1" width={`${size}`} height={`${size}`} aria-hidden="true" style={{...style, fill: draftPullRequestColor}}>
            <path
              fillRule="evenodd"
              d="M2.5 3.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.25 1a2.25 2.25 0 00-.75 4.372v5.256a2.251 2.251 0 101.5 0V5.372A2.25 2.25 0 003.25 1zm0 11a.75.75 0 100 1.5.75.75 0 000-1.5zm9.5 3a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5z"
            />
            <path d="M14 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm0-4.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"/>
          </svg>
        )
    }
  }, [pullRequest, size, style])

  return icon
}

export default PullRequestIcon
