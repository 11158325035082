import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import equal from 'deep-equal'
import {deleteRequest, get, post} from '../utils/builds-api'
import {ToastContext} from '../contexts'

const useApprovals = ({environment, versions, pollInterval}) => {
  const {pushToast} = useContext(ToastContext)
  const [approvals, setApprovals] = useState([])
  const [isLoading, setLoading] = useState(true)
  const pollIntervalRef = useRef()

  const approve = useCallback(async (versions) => {
    try {
      const resp = await post(`/approvals/${environment.value}`, {
        versions
      })
      console.log('approving for versions:', versions, resp.data)
      setApprovals(resp.data)
      return resp.data
    } catch (err) {
      pushToast({
        title: `Error approving ${environment.label}`,
        body: JSON.stringify(err.response?.data?.errorMessage ?? err.message),
        type: 'error'
      })
      return approvals
    }
  }, [])

  const revoke = useCallback(async (versions, revokeAll) => {
    try {
      const resp = await deleteRequest(`/approvals/${environment.value}${revokeAll === true ? '/revoke' : ''}`, {
        versions
      })
      setApprovals(resp.data)
      return resp.data
    } catch (err) {
      pushToast({
        title: `Error revoking approval for ${environment.label}`,
        body: JSON.stringify(err.response?.data?.errorMessage ?? err.message),
        type: 'error'
      })
      return approvals
    }
  }, [])

  useEffect(() => {
    const loadApprovals = async () => {
      try {
        const resp = await get(`/approvals/${environment.value}`)

        let updatedApprovals = resp.data
        // if we are getting approvals for specific versions, filter by those versions
        if (versions?.electron || versions?.recorder) {
          updatedApprovals = resp.data.filter(approval => {
            // if an electron version is being approved, but approval doesn't match that version
            // then filter it out
            if (versions.electron && approval.versions.electron !== versions.electron) {
              return false
            }
            // if a recorder version is being approved, but approval doesn't match that version
            // then filter it out
            if (versions.recorder && approval.versions.recorder !== versions.recorder) {
              return false
            }
            // versions pending approval match versions on the approval
            return true
          })
        }

        if (!equal(approvals, updatedApprovals)) {
          setApprovals(updatedApprovals)
        }
      } catch (err) {
        pushToast({
          title: `Error loading ${environment.label} approvals`,
          body: JSON.stringify(err.response?.data?.errorMessage ?? err.message),
          type: 'error'
        })
      } finally {
        setLoading(false)
      }
    }

    if (environment?.parent) {
      loadApprovals()

      if (pollInterval !== undefined && pollInterval > 0) {
        pollIntervalRef.current = setInterval(loadApprovals, pollInterval)
      }
    }

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current)
      }
    }
  }, [approvals, versions])

  return {approvals, isLoading, approve, revoke}
}

export default useApprovals
