import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

const SettingsIcon = ({size = '18', color = 'white'}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.075 6.91501C13.7175 6.91501 13.1625 5.95501 13.8375 4.77751C14.2275 4.09501 13.995 3.22501 13.3125 2.83501L12.015 2.09251C11.4225 1.74001 10.6575 1.95001 10.305 2.54251L10.2225 2.68501C9.5475 3.86251 8.4375 3.86251 7.755 2.68501L7.6725 2.54251C7.335 1.95001 6.57 1.74001 5.9775 2.09251L4.68 2.83501C3.9975 3.22501 3.765 4.10251 4.155 4.78501C4.8375 5.95501 4.2825 6.91501 2.925 6.91501C2.145 6.91501 1.5 7.55251 1.5 8.34001V9.66001C1.5 10.44 2.1375 11.085 2.925 11.085C4.2825 11.085 4.8375 12.045 4.155 13.2225C3.765 13.905 3.9975 14.775 4.68 15.165L5.9775 15.9075C6.57 16.26 7.335 16.05 7.6875 15.4575L7.77 15.315C8.445 14.1375 9.555 14.1375 10.2375 15.315L10.32 15.4575C10.6725 16.05 11.4375 16.26 12.03 15.9075L13.3275 15.165C14.01 14.775 14.2425 13.8975 13.8525 13.2225C13.17 12.045 13.725 11.085 15.0825 11.085C15.8625 11.085 16.5075 10.4475 16.5075 9.66001V8.34001C16.5 7.56001 15.8625 6.91501 15.075 6.91501ZM9 11.4375C7.6575 11.4375 6.5625 10.3425 6.5625 9.00001C6.5625 7.65751 7.6575 6.56251 9 6.56251C10.3425 6.56251 11.4375 7.65751 11.4375 9.00001C11.4375 10.3425 10.3425 11.4375 9 11.4375Z"
        fill={color}
      />
    </svg>
  )
}

SettingsIcon.propTypes = propTypes

export default SettingsIcon
