import {useCallback, useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import {CircleButton, colors, InputField, Tooltip} from '@get-wrecked/simple-components'
import {mobileWidthMediaQuery, pageContentWidth} from '../constants'
import AvatarMenu from '../AvatarMenu'
import {EnvironmentFilters} from '../../contexts/providers'
import {EnvironmentContext, ModalContext, UserContext} from '../../contexts'
import SearchIcon from '../Icons/SearchIcon'
import useMediaQuery from '../../hooks/useMediaQuery'
import useClickWithin from '../../hooks/useClickWithin'
import {isAllowed, showNotification} from '../../utils/notifications'
import PushNotificationIcon from '../Icons/PushNotificationIcon'
import BuildHistoryButton from '../BuildHistoryButton'
import {useNavigate} from 'react-router'
import ScheduleIcon from '../Icons/ScheduleIcon'
import ChangelogIcon from '../Icons/ChangelogIcon'
import FeatureFlagsIcon from '../Icons/FeatureFlagsIcon'

const Tabs = () => {
  const {openModal} = useContext(ModalContext);
  const {user} = useContext(UserContext)
  const {setEnvironmentFilter, searchQuery} = useContext(EnvironmentContext)
  const [activeTab, setActiveTab] = useState(0)
  const [isSearching, setIsSearching] = useState(false)
  const [searchClickWithinRef, isClickWithinSearch, setIsClickWithinSearch] = useClickWithin()
  const [notificationsAllowed, setNotificationsAllowed] = useState(isAllowed())
  const [showDropdown, setShowDropdown] = useState(false)
  const isMobileWidth = useMediaQuery(`max-width: 600px`)
  const isCollapseTabsWidth = useMediaQuery(`max-width: 560px`)
  const navigate = useNavigate()

  const [tabClickWithinRef, isClickWithinTab, setIsClickWithinTab] = useClickWithin()
  const activeTabName = Object.values(EnvironmentFilters)[activeTab]

  const onSearchInputChange = useCallback(e => {
    const query = e.target.value?.length > 0 ? `?q=${e.target.value}` : ''
    navigate(`/${query}`)
  }, [])

  useEffect(() => {
    setEnvironmentFilter(Object.values(EnvironmentFilters)[activeTab])
  }, [activeTab])

  useEffect(() => {
    if (isSearching && searchQuery?.length === 0 && isClickWithinSearch === false) {
      setIsSearching(false)
      navigate('/')
      setIsClickWithinSearch(undefined)
    }
  }, [isClickWithinSearch, isMobileWidth, isSearching, searchQuery])


  useEffect(() => {
    if (showDropdown && isClickWithinTab === false || !isMobileWidth) {
      setShowDropdown(false)
      setIsClickWithinTab(undefined)
    }
  }, [isClickWithinTab, isMobileWidth, showDropdown])

  return (
    <TabRow>
      <TabContainer>
        {
          isMobileWidth && (!isSearching && !searchQuery?.length) &&
          <Tab
            $isActive={true}
            ref={tabClickWithinRef}
            onClick={(e) => {
              e.stopPropagation()
              setShowDropdown(!showDropdown)
            }}
          >
            {activeTabName}
            <svg width="10" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.9188 0.179688H6.68877H1.07877C0.118772 0.179688 -0.361228 1.33969 0.318772 2.01969L5.49877 7.19969C6.32877 8.02969 7.67877 8.02969 8.50877 7.19969L10.4788 5.22969L13.6888 2.01969C14.3588 1.33969 13.8788 0.179688 12.9188 0.179688Z"
                fill={'white'}/>
            </svg>
            {
              showDropdown &&
              <TabsDropdown>
                {
                  Object.values(EnvironmentFilters)
                    .map((name, index) => {
                      return (
                        <DropdownItem
                          $selected={activeTab === index}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActiveTab(index)
                            setShowDropdown(false)
                          }}
                        >
                          {name}
                        </DropdownItem>
                      )
                    })
                }
              </TabsDropdown>
            }
          </Tab>
        }
        {
          !isMobileWidth &&
          Object.values(EnvironmentFilters).map((label, index) => {
            if (index >= 2 && isCollapseTabsWidth) {
              return ''
            }
            return (
              <Tab
                key={label}
                $isActive={index === activeTab}
                onClick={() => setActiveTab(index)}
              >
                {label}
              </Tab>
            )
          })
        }
      </TabContainer>
      <RowEnd>
        {
          !(!isSearching && searchQuery?.length === 0) &&
          <InputField
            $ref={searchClickWithinRef}
            autoFocus={true}
            placeholder="Search environments..."
            value={searchQuery}
            onChange={onSearchInputChange}
            debounce={200}
            style={!isMobileWidth ? undefined : {
              flexGrow: 1
            }}
          />
        }
        {
          (!isMobileWidth || !isSearching) &&
          <Actions>
            {
              (!isSearching && searchQuery?.length === 0) &&
              <CircleButton
                size="36px"
                onClick={() => {
                  setIsSearching(true)
                }}
              >
                <SearchIcon size={20} color={'white'}/>
              </CircleButton>
            }
            <BuildHistoryButton/>

            <Divider/>

            <Tooltip
              tooltip="Manage feature flags"
              position="bottom"
            >
              <CircleButton
                size="36px"
                onClick={() => navigate('/flags')}
              >
                <FeatureFlagsIcon
                  size={20}
                  color={'white'}
                />
              </CircleButton>
            </Tooltip>
            <Tooltip
              tooltip="Manage in-app changelogs"
              position="bottom"
            >
              <CircleButton
                size="36px"
                onClick={() => navigate('/changelogs')}
              >
                <ChangelogIcon size={20} color={'white'}/>
              </CircleButton>
            </Tooltip>
            <Tooltip
              tooltip="Manage release schedule"
              position="bottom"
            >
              <CircleButton
                size="36px"
                onClick={() => navigate('/schedule')}
              >
                <ScheduleIcon size={20} color={'white'}/>
              </CircleButton>
            </Tooltip>
            {
              'Notification' in window && !notificationsAllowed &&
              <Tooltip
                tooltip="Allow Notifications"
                position="bottom"
              >
                <CircleButton
                  size="36px"
                  onClick={() => {
                    showNotification({
                      title: 'Build Notifications Enabled',
                      body: 'You will now be notified when active builds complete or fail.'
                    }, allowed => setNotificationsAllowed(allowed))
                  }}
                >
                  <PushNotificationIcon size={20} color={'white'}/>
                </CircleButton>
              </Tooltip>
            }
            <Divider/>
          </Actions>
        }
        {
          user &&
          <AvatarMenu
            user={user}
            logout={() => openModal('logout')}
          />
        }
      </RowEnd>
    </TabRow>
  )
}

export default Tabs

const TabRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  width: ${pageContentWidth};
`

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-left: 20px;

  @media (${mobileWidthMediaQuery}) {
    margin-left: 12px;
  }
`

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  cursor: pointer;
  box-sizing: content-box; // let the border of the Tab overlap the border of the TabRow
  border-bottom: ${({$isActive}) => $isActive ? `2px solid ${colors.brand.primary['50']}` : 'none'};
  color: ${({$isActive}) => $isActive ? 'white' : colors.text['30']};
  padding-bottom: ${({$isActive}) => $isActive ? '0' : '2px'};
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  
  svg {
    path {
      fill: ${({$isActive}) => $isActive ? 'white' : colors.text['30']};
    }
  }
  
  &:hover {
    color: white;
    padding-bottom: 0;
    border-bottom: 2px solid ${({$isActive}) => $isActive ? colors.brand.primary['50'] : colors.stroke['50']};

    svg {
      path {
        fill: white;
      }
    }
  }
`

const TabsDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: -12px;
  background-color: ${colors.background['first-layer']};
  border: 1px solid ${colors.stroke['0A16']};
  border-top: none;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.64);
  border-radius: 0 0px 8px 8px;
  z-index: 1;
`

const DropdownItem = styled.div`
  padding: 12px;
  background-color: ${({$selected}) => $selected ? colors.stroke['0A8'] : 'transparent'};
  
  &:hover {
    background-color: ${colors.stroke['0A16']};
  }
`

const RowEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-right: 20px;

  @media (${mobileWidthMediaQuery}) {
    margin-right: 12px;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

const Divider = styled.div`
  width: 1px;
  min-width: 1px;
  height: 36px;
  background-color: ${colors.stroke['0A16']};
  margin: 0 3px;
`
