import {Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {CircleButton, colors, Tooltip} from '@get-wrecked/simple-components'
import BuildHistoryIcon from '../Icons/BuildHistoryIcon'
import {BuildApiContext, ModalContext, ToastContext} from '../../contexts'
import {post} from '../../utils/builds-api'
import {useNavigate} from 'react-router'
import ErrorCrossIcon from '../Icons/ErrorCrossIcon'
import CircleCheckIcon from '../Icons/CircleCheckIcon'
import RetryIcon from '../Icons/RetryIcon'

const BuildHistoryButton = () => {
  const {buildApiState} = useContext(BuildApiContext)
  const {pushToast} = useContext(ToastContext)
  const {openModal} = useContext(ModalContext)
  const [isOpen, setOpen] = useState(false)
  const [buildHistoryViewed, setBuildHistoryViewed] = useState(false)
  const ref = useRef()
  const navigate = useNavigate()

  const processedBuilds = useMemo(() => {
    return Object.values(buildApiState?.processedBuilds || {}).reverse()
  }, [buildApiState?.processedBuilds])

  const retryBuild = async build => {
    try {
      const resp = await post('/build', build)
      if (resp.data.errorMessage) {
        pushToast({
          title: 'Error canceling build',
          body: resp.data.errorMessage,
          type: 'error'
        })
      } else {
        pushToast({
          title: `Retrying Build`,
          body: `${build.environment} ${build.module} build re-added to queue`,
          type: 'success'
        })
        navigate(`/build/${resp.data.buildId}`)
      }
    } catch (err) {
      pushToast({
        title: 'Error retrying build',
        body: err.response.data?.errorMessage || err.toString(),
        type: 'error'
      })
      console.log(err)
    }
  }

  const showNotifyFailedBuilds = !buildHistoryViewed &&
    processedBuilds.some(entry => entry.json.state.releaseState === 'failure')

  const onClickOutsideListener = useCallback(e => {
    const el = ref.current
    if (isOpen && el && !el.contains(e.target)) {
      setOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('click', onClickOutsideListener)
    return () => {
      document.removeEventListener('click', onClickOutsideListener)
    }
  }, [onClickOutsideListener])

  useEffect(() => {
    if (isOpen && !buildHistoryViewed) {
      setBuildHistoryViewed(true)
    }
  }, [isOpen, buildHistoryViewed])

  return (
    <Fragment>
      <Tooltip
        tooltip="Build history"
        position="bottom"
        disabled={isOpen}
      >
        <CircleButton
          $ref={ref}
          size="36px"
          onClick={() => setOpen(!isOpen)}
          initialOpacity={isOpen ? 1 : undefined}
        >
          <BuildHistoryIcon
            size={22}
            color={'white'}
            notifyColor={showNotifyFailedBuilds ? colors.error['50'] : undefined}
          />
          {
            isOpen &&
            <History className="scrollable y">
              {
                processedBuilds.map(entry => {
                  const build = entry.json.build
                  const releaseState = entry.json.state.releaseState
                  return (
                    <HistoryEntry
                      key={build.buildId}
                      onClick={e => navigate(`/build/${build.buildId}`)}
                    >
                      <Icon>
                        {releaseState === 'failure' && <ErrorCrossIcon size={13}/>}
                        {releaseState === 'canceled' && <ErrorCrossIcon size={13} color="rgba(128, 128, 128)"/>}
                        {releaseState === 'started' && <ErrorCrossIcon size={13} color="#ffb84b"/>}
                        {releaseState === 'success' && <CircleCheckIcon size={13}/>}
                      </Icon>
                      {build.module} {build.version}
                      {
                        releaseState !== 'success' &&
                        <CircleButton
                          size="20px"
                          onClick={(e) => {
                            e.stopPropagation()
                            setOpen(false)
                            openModal('retry-build', {
                              build,
                              callback: () => {
                                return retryBuild(build)
                              }
                            })
                          }}
                        >
                          <RetryIcon size="14"/>
                        </CircleButton>
                      }
                    </HistoryEntry>
                  )
                })
              }
            </History>
          }
        </CircleButton>
      </Tooltip>
    </Fragment>
  )
}

export default BuildHistoryButton

const History = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  background-color: ${colors.background['second-layer']};
  border-radius: 12px;
  border: 1px solid ${colors.stroke['0A16']};
  padding: 10px;
  min-width: max-content;
  gap: 8px;
  max-height: min(300px, 90vh);
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.64);
  cursor: default;
  z-index: 1;
`

const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
`

const HistoryEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 30px;
  border-radius: 9px;
  margin: -4px -6px;
  gap: 10px;
  cursor: pointer;
  min-width: fit-content;
  font-size: 14px;
  transition: background-color 150ms;

  &:hover {
    background-color: ${colors.stroke['0A16']};
  }
`
