import styled from 'styled-components'
import {LoadingPlaceholder} from '../styles'
import {getNotifier} from '../Icons/PushNotificationIcon'
import {colors} from '@get-wrecked/simple-components'

const notifySize = 12
const Avatar = ({id, image, size = '32px', clickable, isLoading, backgroundColor, notifyColor}) => {
  return (
    <AvatarContainer
      id={id}
      $size={size}
      $clickable={clickable}
      $isLoading={isLoading}
      $loadingSpace="650px"
      $backgroundColor={backgroundColor}
    >
      {
        !isLoading &&
        <AvatarImage src={image}/>
      }
      {
        notifyColor &&
        <div style={{display: 'flex', position: 'absolute', bottom: `-1px`, right: `-1px`, width: `${notifySize}px`, height: `${notifySize}px`, pointerEvents: 'none'}}>
          {getNotifier({notifyColor, size: notifySize})}
        </div>
      }
    </AvatarContainer>
  )
}

export default Avatar

const AvatarContainer = styled.div`
  border-radius: 50%;
  max-width: ${({$size}) => $size ?? '32px'};
  max-height: ${({$size}) => $size ?? '32px'};
  min-width: ${({$size}) => $size ?? '32px'};
  min-height: ${({$size}) => $size ?? '32px'};
  border: 3px solid ${({$isLoading}) => $isLoading ? colors.neutral['0A4'] : colors.stroke['0A16']};
  box-sizing: border-box;
  cursor: ${({$clickable}) => $clickable ? 'pointer' : 'default'};
  overflow: hidden;
  
  ${({$backgroundColor}) => $backgroundColor ? `background-color: ${$backgroundColor};` : ''}
  ${({$isLoading}) => $isLoading ? LoadingPlaceholder : ''}
  
  &:hover {
    ${({$clickable}) => $clickable ? 'background-color: rgba(255, 255, 255, 0.08);' : ''}
    ${({$clickable}) => $clickable ? 'border-color: ${stroke50};' : ''}
  }
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
