import ConfirmationDialog from '../../ConfirmationDialog'
import {useContext} from 'react'
import {EnvironmentContext} from '../../../contexts'

const RetryBuildModal = ({build, callback}) => {
  const {environments} = useContext(EnvironmentContext)
  const environment = environments.find(env => env.value === build.environment)
  return (
    <ConfirmationDialog
      modalId="retry-build"
      title={`Retry ${build.module} Build`}
      description={
        <span>
          Are you sure you want to retry <code>{environment?.label ?? build.environment}</code> {build.module} {build.version}?
        </span>
      }
      onConfirm={() => callback()}
    />
  )
}

export default RetryBuildModal
