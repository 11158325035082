import {useContext} from 'react'
import ReactMarkdown from 'react-markdown'
import Modal from '../../../components/Modal'
import {WhatsNewContext} from '../../../contexts'

const GitHubNotesModal = () => {
  const {whatsNew, setWhatsNew} = useContext(WhatsNewContext)

  const close = () => {
    setWhatsNew({});
  }
  return (
    <Modal
      id={'whats-new'}
      onClose={close}
      size="medium"
      header={whatsNew?.title}
    >
      <ReactMarkdown
        className="whats-new markdown"
        linkTarget={'_blank'}
      >
        {whatsNew?.body}
      </ReactMarkdown>
    </Modal>
  )
}

export default GitHubNotesModal
