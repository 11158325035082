import {Fragment, useContext, useState} from 'react';
import styled from 'styled-components'
import {colors, Button, InputField} from '@get-wrecked/simple-components'
import {LoginContext, UserContext} from '../../contexts';

const LoginPage = () => {
  const {user} = useContext(UserContext)
  const {setLoggingIn, login, logout} = useContext(LoginContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [busy, setBusy] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onLogin = async () => {
    setBusy(true)
    if (user) {
      await logout()
    }
    setErrorMessage('')
    login(username, password, (errorMessage) => {
      if (errorMessage) {
        setErrorMessage(errorMessage)
      }
      setBusy(false)
    })
  }

  const getMessage = () => {
    if (user && !user?.isStaff) {
      return <Fragment>It looks like your account <b>{user.userName}</b> does not have a staff role.<br/>Reach out on Slack for a staff role, or logout and try a different account.</Fragment>
    }
    return <Fragment>Login with your <b>Medal staff account</b> for access to advanced build controls.</Fragment>
  }

  return (
    <Page>
      {
        !!user && !user?.isStaff
          ? <Avatar>
            <AvatarImage src={user.thumbnail}/>
          </Avatar>
          : <LogoContainer>
            <Logo src="https://cdn.medal.tv/assets/img/new-medal-logo-small.png"/>
          </LogoContainer>
      }
      <Message $warning={errorMessage.length > 0}>
        {
          errorMessage.length > 0
            ? errorMessage
            : getMessage()
        }
      </Message>
      {
        !user
          ? <Form>
            <InputField
              size="large"
              placeholder="Username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              onKeyDown={e => e.keyCode === 13 ? onLogin() : () => {}}
              disabled={!!user}
            />
            <InputField
              size="large"
              placeholder="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => e.keyCode === 13 ? onLogin() : () => {}}
              disabled={!!user}
            />
            <Button
              onClick={onLogin}
              variant="primary"
              size="large"
              style={{ width: '100%' }}
              disabled={username?.length === 0 || password?.length === 0 || !!user}
            >
              Login
            </Button>
          </Form>
          : <Button
            onClick={logout}
            variant="secondary"
            size="large"
          >
            Logout
          </Button>
      }
    </Page>
  )
}

export default LoginPage;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 0px 24px;
`

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  height: 32px;
  width: auto;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  gap: 16px;
`

const Message = styled.p`
  font-size: 14px;
  text-align: center;
  ${({$warning}) => $warning ? `color: ${colors.error['10']}` : ''}
`

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow:hidden;
`
const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
