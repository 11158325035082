import {useContext, useEffect} from 'react'
import styled from 'styled-components'
import {Modules} from '@get-wrecked/updates/constants'
import Module, {ModuleContainer} from '../../../components/Module'
import {SelectedVersionHistoryContext, VersionListContext} from '../../../contexts'
import Modal from '../../../components/Modal'
import {mobileWidthMediaQuery} from '../../../components/constants'
import useVersionList from '../../../hooks/useVersionList'
import {colors} from '@get-wrecked/simple-components'

const VersionHistory = ({environment}) => {
  const {fetchVersionList} = useContext(VersionListContext);
  const {setSelectedVersionHistory} = useContext(SelectedVersionHistoryContext);

  const electronVersions = useVersionList({
    environment,
    module: Modules.ELECTRON
  })

  const recorderVersions = useVersionList({
    environment,
    module: Modules.RECORDER
  })

  const close = (e, force) => {
    setSelectedVersionHistory(undefined);
  }

  useEffect(() => {
    if (environment.modules.includes(Modules.ELECTRON) && !electronVersions?.length) {
      fetchVersionList({environment, module: Modules.ELECTRON})
    }
    if (environment.modules.includes(Modules.RECORDER) && !recorderVersions?.length) {
      fetchVersionList({environment, module: Modules.RECORDER})
    }
  }, [])

  const renderVersions = () => {
    const elements = []
    for (let i = 0; i < Math.max(electronVersions.length, recorderVersions.length); i++) {
      elements.push(<ModulesContainer key={`row-${i}`}>
        {
          electronVersions[i]
            ? <Module
              key={`${environment.value}-electron-${electronVersions[i].semver}`}
              id={`history-${environment.value}-electron-${electronVersions[i].semver}`}
              environment={environment}
              module={Modules.ELECTRON}
              version={electronVersions[i]}
            />
            : <ModuleContainer/>
        }
        {
          recorderVersions[i]
            ? <Module
              key={`${environment.value}-recorder-${recorderVersions[i].semver}`}
              id={`history-${environment.value}-recorder-${recorderVersions[i].semver}`}
              environment={environment}
              module={Modules.RECORDER}
              version={recorderVersions[i]}
            />
            : <ModuleContainer/>
        }
      </ModulesContainer>)
    }
    return elements;
  }

  return (
    <Modal
      id="version-history-modal"
      header={`${environment.label} Version History`}
      size="medium"
      onClose={close}
    >
      <Scrollable className="scrollable y">
        {renderVersions()}
      </Scrollable>
    </Modal>
  )
}

export default VersionHistory;

const Scrollable = styled.div`
  gap: 16px;
`

const ModulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  
  @media (${mobileWidthMediaQuery}) {
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.stroke['0A8']};
  }
`// @todo on mobile resolution, border bottom + padding / margin

