import ConfirmationDialog from '../../ConfirmationDialog'
import {useContext} from 'react'
import {FeatureFlagsContext, ToastContext} from '../../../contexts'

const DeleteFeatureFlagModal = ({flagKey}) => {
  const {deleteFlag, deleteLdFlag} = useContext(FeatureFlagsContext)
  const {pushToast} = useContext(ToastContext)

  const onDelete = async () => {
    try {
      console.log('attempting to delete:', flagKey)
      await deleteLdFlag(flagKey)
    } catch (err) {
      pushToast({
        title: 'Failed to delete flag',
        body: err.message,
        type: 'error'
      })
    }
  }

  return (
    <ConfirmationDialog
      modalId="delete-feature-flag"
      title="Are you sure?"
      description={<span>Please confirm that you wish to delete the feature flag:<br/><br/><code>{flagKey}</code></span>}
      confirmLabel="Delete"
      confirmType="danger"
      cancelLabel="Nevermind"
      onConfirm={onDelete}
    />
  )
}

export default DeleteFeatureFlagModal
