import {useContext, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {colors, CloseButton} from '@get-wrecked/simple-components'
import {ToastContext} from '../../contexts'

const Toast = ({title, body, timeout, type, id}) => {
  const timeoutRef = useRef(null)
  const {removeToast} = useContext(ToastContext)

  const onRemove = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    removeToast(id)
  }

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null
      removeToast(id)
    }, timeout || 5 * 1000)
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Container $type={type}>
      <CloseButton
        position="12px"
        onClick={onRemove}
      />
      <Title>{typeof title === 'object' ? JSON.stringify(title) : title}</Title>
      <Body>{typeof body === 'object' ? JSON.stringify(body) : body}</Body>
    </Container>
  )
}

export default Toast;

const SlideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.background['third-layer']};
  border: 1px solid ${colors.stroke['0A8']};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 16px;
  padding-inline-end: 46px;
  gap: 8px;
  ${({$type}) => {
    if ($type === 'success') {
      return `background: radial-gradient(${colors.background['third-layer']} 40%, ${colors.success['100']}); border-color: ${colors.success['70']};`
    } else if ($type === 'error') {
      return `background: radial-gradient(${colors.background['third-layer']} 40%, ${colors.error['100']}); border-color: ${colors.error['70']};`
    }
  }}
  animation: ${SlideIn} 100ms ease;
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: white;
`

const Body = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text['30']};
`
