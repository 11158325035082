
const CircleCheckIcon = ({size}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.5" cy="4.5" r="3.5" fill="#000"/>
      <path d="M7.98 1.65C7.16 0.64 5.91 0 4.5 0C3.44 0 2.46 0.37 1.69 0.99C0.65 1.81 0 3.08 0 4.5C0 5.34 0.24 6.14 0.65 6.82C0.92 7.27 1.26 7.66 1.66 7.98H1.67C2.44 8.62 3.43 9 4.5 9C5.64 9 6.67 8.58 7.46 7.88C7.81 7.58 8.11 7.22 8.35 6.82C8.76 6.14 9 5.34 9 4.5C9 3.42 8.62 2.42 7.98 1.65ZM6.76 3.94L4.36 6.16C4.22 6.29 4.03 6.36 3.85 6.36C3.66 6.36 3.47 6.29 3.32 6.14L2.21 5.03C1.92 4.74 1.92 4.26 2.21 3.97C2.5 3.68 2.98 3.68 3.27 3.97L3.87 4.57L5.74 2.84C6.04 2.56 6.52 2.58 6.8 2.88C7.09 3.19 7.07 3.66 6.76 3.94Z" fill="#01d28e"/>
    </svg>
  )
}

export default CircleCheckIcon
