export const isAllowed = () => {
  if ('Notification' in window) {
    return Notification.permission === 'granted'
  }
  return false
}

export const isDenied = () => {
  if ('Notification' in window) {
    return Notification.permission === 'denied'
  }
  return false
}

export const showNotification = ({title, body, onClick}, callback = () => {}) => {
  if (!('Notification' in window)) {
    console.warn('Notification API not supported')
    callback(false)
    return
  }

  const show = () => {
    const options = {
      body: body,
      icon: 'https://cdn.medal.tv/assets/img/new-medal-logo-small.png'
    }
    const notification = new Notification(title, options)
    notification.onerror = e => {
      console.log('Notification error:', e)
    }
    notification.onclick = e => {
      if (typeof onClick === 'function') {
        onClick()
      }
      window.focus();
      notification.close();
    }
  }

  if (isAllowed()) {
    show()
    callback(true)
  } else if (!isDenied()) {
    console.log('requesting permissions')
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        show()
        callback(true)
      } else {
        callback(false)
      }
    });
  } else {
    console.log('permissions denied')
    callback(false)
  }
}
