import {useMemo} from 'react'
import useVersionList from './useVersionList'

const useVersion = ({environment, module, version}) => {
  const versionList = useVersionList({environment, module})
  const ver = useMemo(() => {
    return versionList?.find(v => v.semver === (version?.semver ?? version))
  }, [version, versionList])
  return ver
}

export default useVersion
