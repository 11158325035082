import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button} from '@get-wrecked/simple-components'
import Modal from '../../components/Modal'
import {useContext} from 'react'
import {ModalContext} from '../../contexts'

const propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  confirmLabel: PropTypes.string,
  confirmType: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'danger',
    'success'
  ]),
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
}

const ConfirmationDialog = ({modalId, title, description, confirmLabel, confirmType, cancelLabel, onConfirm = () => {}, onCancel = () => {}, onClose}) => {
  const { closeModal } = useContext(ModalContext)

  const cancel = () => {
    closeModal(modalId)
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const confirm = () => {
    closeModal(modalId)
    if (typeof onConfirm === 'function') {
      onConfirm()
    }
  }

  const close = () => {
    if (typeof onCancel === 'function') {
      cancel()
    }
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Modal
      id={modalId}
      size="xsmall"
      header={title}
      onClose={close}
    >
      <Body>
        {description}
        <Actions>
          <Button
            variant="ghost-neutral"
            size="large"
            onClick={cancel}
          >
            {cancelLabel || 'Nevermind'}
          </Button>
          <Button
            variant={confirmType || 'primary'}
            size="large"
            onClick={confirm}
          >
            {confirmLabel || 'Yes'}
          </Button>
        </Actions>
      </Body>
    </Modal>
  )
}

ConfirmationDialog.propTypes = propTypes

export default ConfirmationDialog;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`
