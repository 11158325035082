import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, colors, InputField, TextArea} from '@get-wrecked/simple-components'
import {DefaultFlagTypeValues, FlagTypes} from './flagsConstants'
import Modal from '../../../components/Modal'
import FlagKey from './FlagKey'
import {FeatureFlagsContext} from '../../../contexts'
import {InputLabelWrapper} from '../../../components/styles'
import {get} from '../../../utils/builds-api'

const AddFlagModal = ({onCreate = () => {}, onClose = () => {}}) => {
  const {flags, createLdFlag} = useContext(FeatureFlagsContext)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState(FlagTypes.BOOLEAN)
  const [value, setValue] = useState(DefaultFlagTypeValues[FlagTypes.BOOLEAN])
  const [saving, setSaving] = useState(false)
  const [ldFlagExists, setLdFlagExists] = useState(false)
  const checkKeyRef = useRef()

  const flagKey = useMemo(() => {
    return name
      .toLowerCase()
      .split(' ')
      .join('-')
  }, [name])

  const isKeyTaken = useMemo(() => {
    return Object.keys(flags).includes(flagKey)
  }, [flagKey, flags])

  const typeOptions = useMemo(() => {
    return [
      {
        label: 'Toggle (boolean)',
        value: FlagTypes.BOOLEAN
      },
      {
        label: 'Text (string)',
        value: FlagTypes.STRING
      },
      {
        label: 'Number',
        value: FlagTypes.NUMBER
      },
      {
        label: 'List (array)',
        value: FlagTypes.ARRAY
      }
    ]
  }, [])

  const onUpdateType = type => {
    setType(type)
    if (typeof value !== type) {
      setValue(DefaultFlagTypeValues[type])
    }
  }

  const create = async () => {
    setSaving(true)
    try {
      await createLdFlag({
        key: flagKey,
        name: name,
        description: description
      })
    } catch (err) {
      console.error(err)
    }
    setSaving(false)
    if (typeof onCreate === 'function') {
      onCreate()
    }
  }

  useEffect(() => {
    if (typeof value !== type) {
      setValue(DefaultFlagTypeValues[type])
    }
  }, [type])

  useEffect(() => {
    if (flagKey?.length > 0) {
      if (checkKeyRef.current) {
        clearTimeout(checkKeyRef.current)
      }
      checkKeyRef.current = setTimeout(async () => {
        try {
          const resp = await get(`/ld/flags/${flagKey}`)
          console.log(resp.data)
          setLdFlagExists(true)
        } catch (err) {
          if (err.response.data.code === 'not_found') {
            setLdFlagExists(false)
          } else {
            console.error(`Error checking LD flag: ${flagKey}`, err)
          }
        }
      }, 200)
    } else if (ldFlagExists) {
      setLdFlagExists(false)
    }
  }, [flagKey])

  return (
    <Modal
      id="add-feature-flag"
      size="xsmall"
      header="Create New Flag"
      gap="8px"
      scrollable={false}
      onClose={onClose}
    >
      <InputLabelWrapper>
        Name + key generation
        <InputField
          placeholder="Name your feature flag"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </InputLabelWrapper>

      <FlagKey
        flagKey={flagKey}
        canCopy={!isKeyTaken}
        warningMessage={
          ldFlagExists
            ? 'already exists in LaunchDarkly'
            : (isKeyTaken ? 'already exists' : '')
        }
        width="100%"
      />

      <InputLabelWrapper style={{marginTop: '10px'}}>
        Description
        <TextArea
          autoResize={true}
          placeholder="Describe your feature flag"
          value={description}
          onChange={e => setDescription(e.target.value)}
          style={{
            minHeight: '75px',
            maxHeight: '200px'
          }}
        />
      </InputLabelWrapper>

      {/*<TypeSelection>*/}
      {/*  What type of flag is this?*/}
      {/*  <Dropdown*/}
      {/*    value={type}*/}
      {/*    options={typeOptions}*/}
      {/*    onSelect={option => onUpdateType(option.value)}*/}
      {/*    size="large"*/}
      {/*  />*/}
      {/*</TypeSelection>*/}

     {/*<ValueInputWrapper*/}
     {/*  type={type}*/}
     {/*  value={value}*/}
     {/*  onChange={value => {*/}
     {/*    setValue(value)*/}
     {/*  }}*/}
     {/*/>*/}

      {
        type &&
        <Actions>
          <Button
            variant="secondary"
            size="large"
            disabled={!flagKey?.length || !type || isKeyTaken || ldFlagExists || saving}
            onClick={create}
          >
            Create Flag
          </Button>
        </Actions>
      }
    </Modal>
  )
}

export default AddFlagModal

const TypeSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 20px;
  margin-top: 12px;
  border-top: 1px solid ${colors.stroke['0A8']};
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  margin-top: 12px;
  border-top: 1px solid ${colors.stroke['0A8']};
`
