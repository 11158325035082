import {useContext, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, CircleButton, colors, ContextMenu, LoadingSpinner, Tooltip} from '@get-wrecked/simple-components'
import equal from 'deep-equal'
import {EnvironmentContext, FeatureFlagsContext, ModalContext} from '../../../contexts'
import useTimeAgo from '../../../hooks/useTimeAgo'
import {FlagTypes} from './flagsConstants'
import {LoadingPlaceholder, WarningText} from '../../../components/styles'
import FlagKey from './FlagKey'
import ValueInput from './ValueInput'
import OverflowIcon from '../../../components/Icons/OverflowIcon'
import styled from 'styled-components'
import EditFlagModal from './EditFlagModal'
import {convertLdFlag, medalEnvironmentToLdEnv} from '../../../utils/launchdarkly'
import {ColoredEnvironmentIcon} from '../../../components/Environment'
import OpenIcon from '../../../components/Icons/OpenIcon'

const propTypes = {
  isLaunchDarklyFlag: PropTypes.bool,
  flagKey: PropTypes.string.isRequired,
  flag: PropTypes.object.isRequired
}

const FeatureFlag = ({isLaunchDarklyFlag, flagKey, flag}) => {
  const {loaded, updateLdFlag, ldProjectKey} = useContext(FeatureFlagsContext)
  const {openModal} = useContext(ModalContext)
  const [editing, setEditing] = useState(false)
  const useMedalEnvironments = ldProjectKey === 'medaltv-desktop'

  const currentFlag = useMemo(() => {
    if (!loaded) {
      return undefined
    }
    return isLaunchDarklyFlag
      ? convertLdFlag(flag)
      : flag
  }, [isLaunchDarklyFlag, flagKey, flag, useMedalEnvironments, loaded])

  // editing + save states
  const [environments, setEnvironments] = useState(currentFlag?.environments)
  const [saving, setSaving] = useState(false)
  const hasChanges = loaded && !equal(environments, currentFlag?.environments)

  const createdTimeAgo = useTimeAgo(currentFlag?.createdAt)
  const lastUpdatedTimeAgo = useTimeAgo(currentFlag?.lastUpdated?.timestamp)

  const flagValue = currentFlag?.value

  const flagType = useMemo(() => {
    if (Array.isArray(flagValue)) {
      return FlagTypes.ARRAY
    } else if (typeof flagValue === 'string') {
      return FlagTypes.STRING
    } else if (typeof flagValue === 'number') {
      return FlagTypes.NUMBER
    } else if (typeof flagValue === 'boolean') {
      return FlagTypes.BOOLEAN
    } else {
      // console.warn(`Unrecognized flag value type:`, flagKey, flagValue)
    }
  }, [flagValue])

  const overflowMenuItems = useMemo(() => {
    return [
      {
        label: 'Edit Flag',
        onClick: () => {
          setEditing(true)
        }
      },
      {
        divider: true
      },
      {
        label: <WarningText>Delete Flag</WarningText>,
        onClick: () => {
          openModal('delete-feature-flag', {flagKey})
        }
      }
    ]
  }, [])

  const onSave = async () => {
    setSaving(true)
    try {
      if (isLaunchDarklyFlag) {
        await updateLdFlag(flagKey, {
          environments: Object.keys(environments).map(env => {
            const ldEnv = useMedalEnvironments
              ? medalEnvironmentToLdEnv(env)
              : env
            const on = environments[env].value
            return {
              key: ldEnv,
              on
            }
          })
        })
      } else {
        console.error('unhandled non-LD flag saving')
      }
    } catch (err) {
      console.error(err)
    }
    setSaving(false)
  }

  useEffect(() => {
    if (loaded && currentFlag) {
      setEnvironments(currentFlag.environments)
    }
  }, [loaded, currentFlag])

  return (
    <Flag>
      <SpacedRow>
        <NameAndTime>
          {
            currentFlag?.name &&
            <FlagName>{currentFlag.name !== flagKey ? currentFlag.name : currentFlag.name.split('-').join(' ')}</FlagName>
          }
          <Row $loading={!loaded}>
            <Timestamp>
              {
                loaded
                  ? (currentFlag.lastUpdated
                    ? <span>Last updated {lastUpdatedTimeAgo} by {currentFlag.lastUpdated?.userName}</span>
                    : <span>Created {createdTimeAgo}{currentFlag.createdBy?.userName ? ` by ${currentFlag.createdBy.userName}` : ''}</span>)
                  : ' '
              }
            </Timestamp>
          </Row>
        </NameAndTime>
        <ValueContainer>
          {
            hasChanges && !saving &&
            <Button
              variant="success"
              size="small"
              onClick={onSave}
            >
              Save
            </Button>
          }
          {
            saving &&
            <LoadingSpinner size="18px"/>
          }
        </ValueContainer>
        {
          loaded &&
          <Actions>
            <Tooltip
              tooltip="Open in LaunchDarkly"
              position="left"
            >
              <CircleButton
                size="32px"
                href={`https://app.launchdarkly.com/${ldProjectKey}/test/features/${flagKey}/targeting`}
                target="_blank"
              >
                <OpenIcon size={18} color={'white'}/>
              </CircleButton>
            </Tooltip>
            <ContextMenu
              triggerId={`${flagKey}-overflow`}
              position="bottom"
              alignment="right"
              items={overflowMenuItems}
            >
              <CircleButton id={`${flagKey}-overflow`} size="32px">
                <OverflowIcon size={18}/>
              </CircleButton>
            </ContextMenu>
          </Actions>
        }
      </SpacedRow>
      <FlagKey flagKey={flagKey} loading={!loaded}/>
      {
        loaded && currentFlag.description?.length > 0 &&
        <Description>
          {currentFlag.description}
        </Description>
      }
      {
        loaded && environments && Object.keys(environments).length > 0 &&
        <EnvironmentSection>
          {
            Object.keys(environments).map(env => {
              const envConfig = environments[env]
              return (
                <FlagEnvironment
                  key={env}
                  flag={currentFlag}
                  useMedalEnvironments={useMedalEnvironments}
                  envName={env}
                  envConfig={envConfig}
                  onChange={value => {
                    setEnvironments({
                      ...environments,
                      [env]: {
                        ...environments[env],
                        value
                      }
                    })
                  }}
                />
              )
            })
          }
        </EnvironmentSection>
      }
      {
        editing &&
        <EditFlagModal
          isLaunchDarklyFlag={isLaunchDarklyFlag}
          flagKey={flagKey}
          flag={currentFlag}
          flagType={flagType}
          onSave={() => setEditing(false)}
          onClose={() => setEditing(false)}
        />
      }
    </Flag>
  )
}

FeatureFlag.propTypes = propTypes

export default FeatureFlag

const FlagEnvironment = ({flag, useMedalEnvironments, envName, envConfig, onChange}) => {
  const {environments} = useContext(EnvironmentContext)
  const env = useMedalEnvironments
    ? environments.find(env => env.value === envName)
    : envName
  const value = envConfig.value
  const flagType = typeof value
  const inlineEditor = flagType === FlagTypes.BOOLEAN || flagType === FlagTypes.STRING || flagType === FlagTypes.NUMBER
  return (
    <EnvironmentContainer>
      <EnvironmentLabel>
        <ColoredEnvironmentIcon
          size={20}
          environment={env ?? envName}
        />
        {env?.label ?? envName}
      </EnvironmentLabel>
      <ValueContainer>
        {
          inlineEditor
            ? <ValueInput
              type={flagType}
              value={value}
              onChange={onChange}
            />
            : <Button
              variant="tertiary"
              size="small"
              onClick={() => {}}
            >
              Edit
            </Button>
        }
      </ValueContainer>
    </EnvironmentContainer>
  )
}

// styled components
const Flag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 12px;
  border-bottom: 1px solid ${colors.stroke['0A8']};
  font-weight: 500;
  flex-shrink: 0;
`

const NameAndTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const FlagName = styled.span`
  font-size: 14px;
`

const SpacedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  ${({$loading}) => $loading ? LoadingPlaceholder : ''}
`

const Actions = styled(Row)`
  gap: 1px;
`

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-inline-start: auto;
`

const Timestamp = styled.span`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  color: ${colors.text['70']};
`

const Description = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text['50']};
  margin: 4px 0 6px;
`

const EnvironmentSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colors.stroke['0A8']};
  background-color: ${colors.neutral['0A4']};

  > div:not(:last-child) {
    border-bottom: 1px solid ${colors.stroke['0A8']};
  }
`

const EnvironmentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 8px;
`

const EnvironmentLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
