import PropTypes from 'prop-types'
import {useContext} from 'react'
import {useNavigate} from 'react-router'
import {Modal as BaseModal} from '@get-wrecked/simple-components'
import {ModalContext} from '../../contexts'

const propTypes = {
  id: PropTypes.string.isRequired,
  close: PropTypes.func,
  size: PropTypes.oneOfType([
    PropTypes.oneOf([
      'xsmall',
      'small',
      'medium',
      'large',
      'fullscreen',
    ]),
    PropTypes.string, // This allows any string
  ]),
  exitOnClick: PropTypes.bool,
  exitOnEscape: PropTypes.bool,
  bodyPadding: PropTypes.string,
  gap: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  zIndex: PropTypes.number,
  navigateOnClose: PropTypes.oneOf([
    false,
    true,
    'home'
  ])
}

const Modal = (props) => {
  const { closeModal } = useContext(ModalContext)
  const navigate = useNavigate()

  const navigateBackOrHome = (e, shouldClose) => {
    if (window.history.state && window.history.state.idx > 0 && props.navigateOnClose !== 'home') {
      navigate(-1);
    } else {
      navigate('/');
    }
  }

  return (
    <BaseModal
      {...props}
      closeModal={closeModal}
      navigateBackOrHome={navigateBackOrHome}
    >
      {props.children}
    </BaseModal>
  )
}

Modal.propTypes = propTypes

export default Modal
